import React from "react";

const VerificationModal = ({ closeModal, email }) => {
  return (
    <>
      <section className="fixed top-0 z-10 bg-[#00000049] left-0 w-full h-full flex justify-center items-center">
        <section className="w-[60rem] h-[20rem] border rounded-2xl bg-white">
          <section className="flex flex-col items-center justify-center h-full">
            <h1 className="text-4xl font-bold">Verification Email Sent</h1>
            <p className="text-lg text-center">
              We have sent a verification email to <b>{email}</b> . Please
              verify your email to continue.
            </p>
            <button
              onClick={closeModal}
              className="w-[160px] rounded-full text-2xl text-white h-20 bg-[#eab646] mt-10"
            >
              Close
            </button>
          </section>
        </section>
      </section>
    </>
  );
};

export default VerificationModal;
