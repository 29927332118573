import React, { useState, useEffect } from "react";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { SignupUser } from "../../../API/Authenticate";
import { Link } from "react-router-dom";
import lockLogo from "../../Authentication/Login/assest/trace.svg";
import mail from "../Login/assest/mail.svg";
import iconLogo from "../Login/assest/username-icon.svg";
import VerificationModal from "../modal/VerificationModal";
import toast, { Toaster } from "react-hot-toast";
import ArrowIcon from "../Login/assest/Mask-group.svg";
import { zodResolver } from "@hookform/resolvers/zod";
import { RegisterSchema } from "../../../schemas/RegisterSchema";
import "../Login/newLogin.css";

const NewRegister = props => {
  const [successRegister, setSuccessRegister] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const Navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(RegisterSchema)
  });

  const onSubmit = async data => {
    try {
      setLoading(true);
      const response = await SignupUser(data);
      const email = response.data.email;
      setEmail(email);
      setSuccessRegister(true);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        const errorMessages = err.response.data.message;

        if (typeof errorMessages === "string") {
          toast.error(errorMessages);
        } else if (typeof errorMessages === "object") {
          Object.values(errorMessages).forEach(message => {
            toast.error(message);
          });
        }
      } else {
        toast.error("An error occurred. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setSuccessRegister(prev => !prev);
    Navigate("/auth/login");
  };

  return (
    <>
      {loading && (
        <section className="fixed top-0 left-0 w-full h-full bg-opacity-50 bg-gray-500 flex justify-center items-center z-50">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-[#eab646] border-solid"></div>
        </section>
      )}
      <section className="main flex items-center justify-center overflow-hidden min-h-screen min-w-screen  max-sm:w-auto ">
        <form onSubmit={handleSubmit(onSubmit)} className="z-10">
          <section className="register-card   h-[600px] w-[800px] rounded-3xl relative  flex-col pt-18 items-center justify-center flex  max-sm:w-[450px]">
            <section className="w-[150px] h-[150px] rounded-full absolute left-[-90px]  z-[-1] top-[-70px] register-left-circle"></section>
            <section className="w-[200px] h-[200px] bg-[#e4e7ed] rounded-full absolute right-[-150px] z-[-1] top-[10rem] register-right-circle"></section>
            <section className="w-[200px] h-[200px]  rounded-full absolute left-[-120px] z-0 bottom-[-100px] register-bottom-circle"></section>

            <h1 className="text-[#eab646] font-verdana-bold font-bold text-7xl ">
              HAI APP
            </h1>
            <p className="text-xl font-verdana-regular  "> Sign up to your account</p>
            {/* <section className="flex  max-sm:flex-col  items-center justify-center mt-5 px-0 gap-5 max-sm:gap-2">
              <InputField
                label="Firstname"
                name="first_name"
                placeholder="firstname"
                register={register}
                type="text"
                errors={errors}
              />
              <InputField
                label="Middlename"
                name="middle_name"
                placeholder="middlename"
                register={register}
                type="text"
                errors={errors}
              />
            </section> */}

            {/* <section className="relative w-full flex flex-col mt-20 items-center justify-center"> */}
              {/* <InputField
                label="Lastname"
                name="last_name"
                placeholder="lastname"
                register={register}
                type="text"
                errors={errors}
              /> */}
              <InputFields
                label="Username"
                name="username"
                placeholder="username"
                register={register}
                type="text"
                errors={errors}
              />
            {/* </section> */}

            {/* <section className=" relative w-full   flex flex-col mt-20  px-14 max-sm:px-0  items-center justify-center"> */}
              <InputFields
                label="Email"
                name="email"
                placeholder="email"
                register={register}
                type="email"
                errors={errors}
              />
            {/* </section> */}

            {/* <section className=" relative w-full   flex flex-col mt-1  px-14 max-sm:px-0  items-center justify-center"> */}
              <InputFields
                label="Password"
                name="password"
                placeholder="password"
                register={register}
                type="password"
                errors={errors}
              />
            {/* </section> */}
            {/* <section className=" relative w-full  flex flex-col items-center   px-14 max-sm:px-0   justify-center mt-2">
              <InputFields
                label="Confirm Password"
                name="password_confirmation"
                placeholder="confirm password"
                register={register}
                type="password"
                errors={errors}
              />
            </section> */}
            <section className="my-14 w-full flex items-center flex-col justify-center">
              <p className="flex items-center gap-3 ">
                <Link to="/auth/login" style={{ textDecoration: "none" }}>
                  <span className="text-[#49494b] font-verdana-regular font-[400]  ml-3 create-account ">
                    Already Have An Account?{" "}
                  </span>
                </Link>
                <img src={ArrowIcon} className="h-[30px] w-[20px]" alt="Fit" />
              </p>

              <button
                type="submit"
                onSubmit={handleSubmit(onSubmit)}
                className="custom-register-button rounded-full bg-[#EAB646] hover:bg-[#dca027] font-[800] font-verdana-regular max-sm:mb-10 max-sm:ml-5  "
              >
                Sign Up
              </button>
            </section>
          </section>
        </form>
      </section>
      {successRegister ? (
        <VerificationModal closeModal={closeModal} email={email} />
      ) : null}
      <Toaster position="bottom-right" reverseOrder={false} />
    </>
  );
};

export default NewRegister;

const InputField = ({ label, name, placeholder, register, type, errors }) => (
  <section className="relative  flex mt-1 items-center justify-center">
    <section className=" flex flex-col">
      <label
        htmlFor={name}
        className={`absolute left-8 top-1 custom-label bg-[#edf0f6] h-10 w-auto px-5 rounded-full`}
      >
        {label}
      </label>
      <input
        {...register(name)}
        type={type}
        id={name}
        placeholder={`Enter your ${placeholder}`}
        className={`w-[310px] max-sm:w-[370px] h-20 p-2 mt-5 pl-20 rounded-2xl outline-none placeholder:font-verdana-bold placeholder:text-xl  placeholder:text-[#BCBFC7]  bg-[#edf0f6] custom-input-bg border border-[#76777a] ${errors[name] ? "border-red-500 border-2" : "border-[#76777a]"
          }`}
      />
      <img src={iconLogo} alt="" className="absolute left-4 w-12 h-12 top-9" />
    </section>
  </section>
);

const InputFields = ({ label, name, placeholder, register, type, errors }) => (
    <section className=" relative w-full flex flex-col mt-10 items-center justify-center">
      <label
        htmlFor={name}
        className="input-fields max-sm:left-5"
      >
        {label}
      </label>
      <input
        {...register(name, {
          required: "This field is required",
        })}
        
        type={type}
        id={name}
        placeholder={`Enter your ${placeholder}`}
        className={`w-[75%] h-20 p-2 mt-5 pl-20  max-sm:w-[400px]  placeholder:font-verdana-bold placeholder:text-[#BCBFC7]  rounded-full bg-[#f0f1f8] border-[1px] ${errors[name] ? "border-red-500 border-2" : "border-[#77787b]"
          } `}
      />

      {name === "username" && (
        <img
          src={iconLogo}
          alt=""
          className="register-icons ml-10 w-12 h-12 top-9 max-sm:left-20"
        />
      )}
      {name === "email" && (
        <img
          src={mail}
          alt=""
          className="register-icons ml-10 w-12 h-12 top-9 max-sm:left-20"
        />
      )}
      {(name === "password" || name === "password_confirmation") && (
        <img
          src={lockLogo}
          alt=""
          className="register-icons ml-10 w-12 h-12 top-9 max-sm:left-20"
        />
      )}
    {errors[name] && (
      <p className="input-field-error">
        {errors[name].message}
      </p>
    )}
    </section>
);
