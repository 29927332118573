import axios from "axios";
const baseUrl = process.env.REACT_APP_NODE_API_BASE_URL;


export const putCreateTeam = async (data, token) => {
  let config = {
    method: "put",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/team`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  const response = await axios.request(config);
  return response.data;
};

export const postAddTeam = async (data, token) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/team`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  const response = await axios.request(config);
  return response.data;
};

export const getListTeam = async token => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/team`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
};

export const deleteTeam = async (id, token) => {
  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/team/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
};

export const limitTokenAll = async (data, token) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/limit-token`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },

    data: data,
  };

  const response = await axios.request(config);
  return response.data;
};

export const limitTokenMember = async (data, id, token) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/limit-token-specific/${id}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  const response = await axios.request(config);
  return response.data;
};

export const resetToken = async token => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/reset-token`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
};
