import { useState } from "react";
import { FaRegBell } from "react-icons/fa6";
import { testData } from "./testdata";
import { IoCloseSharp } from "react-icons/io5";
import profile from "../../Assets/profile-picture.png";
const ProfileMenu = () => {
  const [hovered, setHovered] = useState(false);
  const [data, setData] = useState(testData);
  const handleDeleteNotif = id => {
    const updatedData = data.filter(list => list.id !== id);
    setData(updatedData);
  };
  return (
    <section className="absolute left-10 top-12 z-20 w-[32rem] h-fit border rounded-2xl overflow-hidden">
      <div className="h-28 flex items-center justify-between  px-8 font-semibold  bg-yellow-500 ">
        <p>Notifications </p>
        <p className="border-2 border-black p-1 rounded-full">
          <FaRegBell className="w-7 h-7 font-bold" />
        </p>
      </div>
      <section className="flex flex-col font-medium bg-white ">
        {data.length > 0 ? (
          data.map(list => {
            return (
              <section
                key={list.id}
                className="h-36 flex justify-between hover:bg-gray-100 border-b px-10 pt-5  cursor-pointer"
                onMouseEnter={() => setHovered(list.id)}
                onMouseLeave={() => setHovered(false)}
              >
                <section className="flex gap-3  ">
                  <img src={profile} alt="profile-pic" className="h-10" />
                  <section className="flex flex-col gap-2">
                    <span className="font-semibold"> {list.title}</span>
                    <span className=" text-lg text-[#4c4c4c]">
                      {list.description}
                    </span>
                    <span className="text-[#9c9c9c] text-lg ">
                      {list.dateTime}
                    </span>
                  </section>
                </section>
                {hovered === list.id && (
                  <IoCloseSharp
                    className="mt-10 text-2xl"
                    onClick={() => handleDeleteNotif(list.id)}
                  />
                )}
              </section>
            );
          })
        ) : (
          <p className="mx-auto my-20 text-2xl text-gray-400">
            You have no notification
          </p>
        )}
      </section>
    </section>
  );
};

export default ProfileMenu;
