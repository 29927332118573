import axios from "axios";

const baseUrl = process.env.REACT_APP_NODE_API_BASE_URL;

export const deleteAccount = async () => {
  try {
    const config = {
      method: "delete",
      url: `${baseUrl}/api/v1/profile`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const response = await axios(config);
    return response.data;
  } catch (error) {
    console.error("Error during deleteAccount:", error);
    throw error;
  }
};
