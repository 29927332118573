import { createContext, useContext, useState } from "react";

const ConversationsContext = createContext();
const ConversationsUpdateContext = createContext();

export const  useConversations = () => {
    return useContext(ConversationsContext);
}

export const useConversationsUpdate = () => {
    return useContext(ConversationsUpdateContext);
}

export const ConvsersationsProvider = ({ children }) => {
    const [conversations, setConversations] = useState([])

    function updateConversations(newConversations){
        setConversations(newConversations);
    }

    return (
        <ConversationsContext.Provider value={conversations}>
            <ConversationsUpdateContext.Provider value={updateConversations}>
                {children}
            </ConversationsUpdateContext.Provider>
        </ConversationsContext.Provider>
    )
}


