import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { BiSolidLeftArrow } from "react-icons/bi";
import OrgUsageExport from "./OrgUsageExport";
import OrgUsageRequestIncreaseLimit from "./OrgUsageRequestIncreaseLimit";
import {
  getFilterUsage,
  getListOfOrgTokenUsage,
  updateOrgToken,
} from "../../../API/OrgUsage";
import "./orgUsage.css";
import DoughnutGraph from "../Table/Graph/DoughnutGraph";

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const shortMonth = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const OrgUsage = ({ returnView, listOrg, clickOrg, orgId }) => {
  const token = localStorage.getItem("accessKey");
  const currentMonthIndex = new Date().getMonth();
  const [currentMonthIndexState, setCurrentMonthIndexState] =
    useState(currentMonthIndex);
  const [filteredData, setFilteredData] = useState([]);
  const [userActivity, setUserActivity] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isIncreaseLimitModalOpen, setIsIncreaseLimitModalOpen] =
    useState(false);
  const [remaining, setRemaining] = useState(0);
  const [orgTokenUsed, setOrgTokenUsed] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getFilterUsage(
        currentMonthIndexState + 1,
        new Date().getFullYear(),
        token
      );
      const response = await getListOfOrgTokenUsage();
      const orgData = response.find((user) => user.organization_id === orgId);
      setOrgTokenUsed(orgData.total_token_used);
      setRemaining(orgData.organization_remaining_token);
      setFilteredData(data);
    };
    fetchData();
  }, [currentMonthIndexState, token, orgId]);

  const changeMonth = (increment) => {
    const newIndex =
      (currentMonthIndexState + increment + month.length) % month.length;
    setCurrentMonthIndexState(newIndex);
  };

  const currentMonth = month[currentMonthIndexState];
  const currentShortMonth = shortMonth[currentMonthIndexState];

  const progressBarValue = filteredData ? filteredData.total_tokens_used : 0;
  const progressBarMaxValue = filteredData
    ? filteredData.organization_remaining_token
    : 0;

  const handleExport = () => {
    setIsExportModalOpen(true);
  };

  const handleCloseExportModal = () => {
    setIsExportModalOpen(false);
  };

  const handleOpenIncreaseLimitModal = () => {
    setIsIncreaseLimitModalOpen(true);
  };

  const handleCloseIncreaseLimitModal = () => {
    setIsIncreaseLimitModalOpen(false);
  };

  const handleUpdateToken = async (newTokenValue) => {
    try {
      await updateOrgToken({ orgId, max_token: newTokenValue }, token);
      const data = await getFilterUsage(
        currentMonthIndexState,
        new Date().getFullYear(),
        token
      );
      setFilteredData(data);
      const response = await getListOfOrgTokenUsage();
      const orgData = response.find((user) => user.organization_id === orgId);
      setOrgTokenUsed(orgData.total_token_used);
      setRemaining(orgData.organization_remaining_token);
    } catch (error) {
      console.error("Error updating token:", error);
    }
    setIsIncreaseLimitModalOpen(false);
  };

  return (
    <>
      <div className="h-screen w-screen pl-[23rem] bg-white flex flex-col justify-end">
        <div className="z-10 border-transparent bg-[#d9d9d9] p-5 w-[140px] -mb-8 rounded-t-xl ml-[6rem] ">
          <button
            className="flex justify-center items-center text-black"
            onClick={() => returnView(false)}
          >
            <BiSolidLeftArrow className="mr-8 hover:text-hsi-yellow duration-300" />
            Usage
          </button>
        </div>
        <div className="flex flex-col gap-[15px] bg-[#d9d9d9] p-20">
          {/* 1st Container */}
          <div className="flex justify-between bg-white p-10 rounded-2xl border-black">
            <div className="button-container flex gap-[1px]">
              <button
                className="border border-hsi-yellow rounded-l-2xl px-12 py-1 bg-hsi-yellow text-white duration-300"
                onClick={() => setUserActivity(false)}
              >
                Cost
              </button>

              <button
                to="/Activity"
                className="border border-hsi-yellow rounded-r-2xl px-12 py-1 bg-white text-hsi-yellow duration-300"
                onClick={() => setUserActivity(true)}
              >
                Activity
              </button>
            </div>
            <div className="flex gap-[10px]">
              <div className="grid-cols-3 justify-evenly border border-black rounded-2xl p-5 w-[150px] bg-white flex duration-300">
                <div
                  className="arrow-left"
                  onClick={() => changeMonth(-1)}
                ></div>
                <span className="px-3 text-center"> {currentMonth} </span>
                <div
                  className="arrow-right"
                  onClick={() => changeMonth(1)}
                ></div>
              </div>

              <button
                className="border border-[#e3b51a] rounded-2xl p-5 text-white bg-[#E3b51a] hover:text-[#e3b51a] hover:bg-[#fefefe] duration-300"
                onClick={handleExport}
              >
                Export
              </button>
            </div>
          </div>

          {/* 2nd Container */}

          {!userActivity ? (
            <div className="flex flex-row bg-white p-10 rounded-2xl justify-between items-end ">
              <div className="flex flex-col">
                <p className="text-3xl font-bold">
                  Monthly Usage{" "}
                  <span className="text-lg font-normal">
                    {currentShortMonth} 01-31
                  </span>
                </p>
                <DoughnutGraph
                  totalCurrentTokens={orgTokenUsed}
                  maxLimit={remaining}
                />
              </div>
              <div className="flex flex-col w-4/5 px-5">
                <div className="py-6 text-right mb-12 mr-12">
                  <span className="text-3xl text-center font-bold text-hsi-yellow">
                    {" "}
                    <span className="text-3xl font-bold mr-12 mb-12 text-black">
                      {orgTokenUsed ? orgTokenUsed : 0}
                    </span>
                  </span>
                  <div className="">
                    <span className="mt-1 mr-6 text-black">
                      <br />/{remaining.toLocaleString()} limit
                    </span>
                  </div>
                  <div className="py-3 flex justify-between items-center mt-12">
                    <span className="text-2xl font-bold mt-12">
                      Credit Grants
                      <span className="font-light ml-2"> Tokens </span>
                    </span>

                    <button
                      className="border border-[#e3b51a] rounded-2xl p-1 text-white bg-[#E3b51a] hover:text-[#e3b51a] hover:bg-[#fefefe] duration-300"
                      onClick={handleOpenIncreaseLimitModal}
                    >
                      Update <br></br>Increase Limit
                    </button>
                  </div>
                  <div className="mt-4 relative">
                    <progress
                      value={orgTokenUsed}
                      max={remaining}
                      className="w-full h-12 duration-300"
                      style={{ marginBottom: "10px" }}
                    />
                    <div className="absolute right-0 top-0 mb-3 mt-3 mr-[1] text-2xl font-bold">
                      {/* {progressBarValue.toFixed(2)} - 100,000 */}
                      {orgTokenUsed ? orgTokenUsed : 0} -{" "}
                      {remaining.toLocaleString()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="">
              <form action="">
                <div className="overflow-scroll h-[350px] mt-4">

                  <div className="bg-white py-7 px-14 rounded-2xl">
                    <h1 className="font-bold text-[15px]">Images</h1>

                    <div className="w-100% grid grid-rows-2 grid-flow-col gap-4">
                      <div className="mt-10 py-3 px-10">
                        <h1 className="mb-8 font-semibold">API request 0</h1>
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>

                      <div className="mt-10 py-3 px-10">
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>

                      <div className="mt-10 py-3 px-10">
                        <h1 className="mb-8 font-semibold">Images 0</h1>
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>

                      <div className="mt-10 py-3 px-10">
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>


                  <div className="bg-white py-7 px-14 rounded-2xl mt-10">
                    <h1 className="font-bold text-[15px]">Audio</h1>
                    <div className="w-100% grid grid-rows-2 grid-flow-col gap-4">
                      <div className="mt-10 py-3 px-10">
                        <h1 className="mb-8 font-semibold">API request 0</h1>
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>

                      <div className="mt-10 py-3 px-10">
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>

                      <div className="mt-10 py-3 px-10">
                        <h1 className="mb-8 font-semibold">Transcribed Seconds 0</h1>
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>

                      <div className="mt-10 py-3 px-10">
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="bg-white py-7 px-14 rounded-2xl mt-10">
                    <h1 className="font-bold text-[15px]">Text to Speech</h1>

                    <div className="w-100% grid grid-rows-2 grid-flow-col gap-4">
                      <div className="mt-10 py-3 px-10">
                        <h1 className="mb-8 font-semibold">API request 0</h1>
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>

                      <div className="mt-10 py-3 px-10">
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>

                      <div className="mt-10 py-3 px-10">
                        <h1 className="mb-8 font-semibold">Character Synthesized 0</h1>
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>

                      <div className="mt-10 py-3 px-10">
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="bg-white py-7 px-14 rounded-2xl mt-10">
                    <h1 className="font-bold text-[15px]">Fine-Tuning Training</h1>

                    <div className="w-100% grid grid-rows-2 grid-flow-col gap-4">
                      <div className="mt-10 py-3 px-10">
                        <h1 className="mb-8 font-semibold">Job Counts 0</h1>
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>

                      <div className="mt-10 py-3 px-10">
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>

                      <div className="mt-10 py-3 px-10">
                        <h1 className="mb-8 font-semibold">Trained tokens 0</h1>
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>

                      <div className="mt-10 py-3 px-10">
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="bg-white py-7 px-14 rounded-2xl mt-10">
                    <h1 className="font-bold text-[15px]">Assistants</h1>

                    <div className="w-100% grid grid-rows-2 grid-flow-col gap-4">
                      <div className="mt-10 py-3 px-10">
                        <h1 className="mb-8 font-semibold">Code Interpreter Sessions 0</h1>
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>


                      <div className="mt-10 py-3 px-10">
                        <input
                          type="text"
                          className="w-full border-b border-gray-700 focus:outline-none"
                        />
                      </div>
                    </div>
                  </div>

                </div>
              </form>
            </div>
          )}
        </div>
      </div>
      {isExportModalOpen && (
        <OrgUsageExport handleOpenModal={handleCloseExportModal} />
      )}
      {isIncreaseLimitModalOpen && (
        <OrgUsageRequestIncreaseLimit
          handleOpenModal={handleCloseIncreaseLimitModal}
          orgId={orgId}
          handleUpdateToken={handleUpdateToken}
        />
      )}
    </>
  );
};

export default OrgUsage;
