import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AddUserSchema } from "../../../../schemas/UserSchema";
import { RiAddFill } from "react-icons/ri";
import { IoIosCloseCircleOutline } from "react-icons/io";

export const NewUserModal = ({ handleOpenModal, onSubmit }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(AddUserSchema),
    defaultValues: {
      first_name: "",
      middle_name: "",
      last_name: "",
      username: "",
      role: null,
      email: "",
      password: "",
    },
  });

  return (
    <section className="fixed top-0 left-0 z-50 w-full h-full flex justify-center items-center  bg-[#00000065]">
      <section className="w-[50rem] h-fit border  bg-white rounded-3xl ">
        <form onSubmit={handleSubmit(onSubmit)} className="py-4">
          <section className=" flex items-center justify-between text-3xl px-10 py-6">
            <h1 className="text-4xl font-bold"> Add User</h1>
            <IoIosCloseCircleOutline onClick={handleOpenModal} className="hover:text-yellow-500 hover:cursor-pointer" />
          </section>

          <div className=" w-full h-3 bg-[#EFB312]"></div>
          <div className=" w-full h-3 bg-[#b18900]"></div>

          <section className="flex gap-5 items-center px-10 py-5 ">
            {/* Firstname */}
            <section className="w-full">
              <label htmlFor="first_name">First Name</label>
              <div className="w-full h-4 rounded-t-2xl bg-[#e3b51a] border border-gray-500"></div>
              <input
                type="text"
                id="first_name"
                placeholder="Enter the first name"
                {...register("first_name")}
                className="border border-gray-500 w-full h-16 pl-5 rounded-b-2xl outline-none bg-gray-100"
              />
              {errors?.first_name && <span className="text-red-400 text-lg">{errors?.first_name?.message}</span>}
            </section>

            {/* Middle */}
            <section className="w-full">
              <label htmlFor="middle_name">Middle Name</label>
              <div className="w-full h-4 rounded-t-2xl bg-[#e3b51a] border border-gray-500"></div>
              <input
                type="text"
                id="middle_name"
                placeholder="Enter the middle name"
                {...register("middle_name")}
                className="border border-gray-500 w-full h-16 pl-5 rounded-b-2xl outline-none bg-gray-100"
              />
              {errors?.middle_name && <span className="text-red-400 text-lg">{errors?.middle_name?.message}</span>}
            </section>
          </section>
          <section className=" px-10">

            <section>
              {/* Lastname */}
              <label htmlFor="last_name" className="mt-2">
                Last Name
              </label>
              <div className="w-full h-4 rounded-t-2xl bg-[#e3b51a] border border-gray-500"></div>
              <input
                type="text"
                id="last_name"
                placeholder="Enter the last name"
                {...register("last_name")}
                className=" border border-gray-500 w-full h-16 pl-5 outline-none rounded-b-2xl bg-gray-100"
              />
              {errors?.last_name && <span className="text-red-400 text-lg">{errors?.last_name?.message}</span>}
            </section>

            <section>
              {/* Username */}
              <label htmlFor="username" className="mt-2">
                Username
              </label>
              <div className="w-full h-4 rounded-t-2xl bg-[#e3b51a] border border-gray-500"></div>
              <input
                type="text"
                id="username"
                placeholder="Enter a username"
                {...register("username")}
                className=" border border-gray-500 w-full h-16 pl-5 outline-none rounded-b-2xl bg-gray-100"
              />
              {errors?.username && <span className="text-red-400 text-lg">{errors?.username?.message}</span>}
            </section>
            <section>
              {/* Role */}
              <label htmlFor="role" className="mt-2">
                Role
              </label>
              <div className="w-full h-4 rounded-t-2xl bg-[#e3b51a] border border-gray-500"></div>
              <select
                {...register("role")}
                className=" border border-gray-500 w-full h-16 pl-5 outline-none rounded-b-2xl bg-gray-100"
              >
                <option value="" className="text-gray-300" selected disabled hidden>Role</option>
                <option value="user">user</option>
                <option value="admin">admin</option>
              </select>
              {errors?.role && <span className="text-red-400 text-lg">{errors?.role?.message}</span>}
            </section>
            <section>
              {/* Email */}
              <label htmlFor="email" className="mt-5">
                Email
              </label>
              <div className="w-full h-4 rounded-t-2xl bg-[#e3b51a] border border-gray-500"></div>
              <input
                type="email"
                id="email"
                placeholder="Enter an email"
                {...register("email")}
                className=" border border-gray-500 w-full h-16 pl-5 outline-none rounded-b-2xl bg-gray-100"
              />
              {errors?.email && <span className="text-red-400 text-lg">{errors?.email?.message}</span>}
            </section>

            <section>
              {/* Password */}
              <label htmlFor="password" className="mt-5">
                Password
              </label>
              <div className="w-full h-4 rounded-t-2xl bg-[#e3b51a] border border-gray-500"></div>
              <input
                type="text"
                id="password"
                placeholder="Enter the password"
                {...register("password")}
                className=" border border-gray-500 w-full h-16 pl-5 outline-none rounded-b-2xl bg-gray-100"
              />
              {errors?.password && <span className="text-red-400 text-lg">{errors?.password?.message}</span>}
            </section>
          </section>

          <section className=" w-full flex justify-between px-16 mt-12 mb-4">
            <button
              className="w-48 h-auto rounded-xl py-4 bg-[#d9d9d9] "
              onClick={handleOpenModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="w-48 h-auto py-4 flex items-center rounded-xl text-white justify-center bg-[#e3b51a]"
            >
              <RiAddFill /> Add
            </button>
          </section>
        </form>
      </section>
    </section>
  );
};
