import DoughnutGraph from "./Graph/DoughnutGraph";
import { BiSolidLeftArrow, BiSolidRightArrow } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { UserUsageExport } from "../Modal/UserUsageModal/UserUsageExport";
import { UserUsageRequestIncreaseLimit } from "../Modal/UserUsageModal/UserUsageRequestIncreaseLimit";
import { getFilterUsage, getListOfUsersTokenUsage } from "../../../API/UserUsage";
import UserActivity from "./UserUsageActivity";
import { useNavigate } from "react-router-dom";
import { useUser, useUserTokenUsage } from '../../../context/UserContext';
import { Toaster } from "react-hot-toast";
// importing to use params from the URL
import { useParams } from "react-router-dom";
import '../Table/UserUsage.css'

const currentYear = new Date().getFullYear();
const isLeapYear = (currentYear % 4 === 0 && currentYear % 100 !== 0) || currentYear % 400 === 0;

const months = [
  { month: "January", monthValue: 1, days: "01-31" },
  { month: "February", monthValue: 2, days: isLeapYear ? "01-29" : "01-28" },
  { month: "March", monthValue: 3, days: "01-31" },
  { month: "April", monthValue: 4, days: "01-30" },
  { month: "May", monthValue: 5, days: "01-31" },
  { month: "June", monthValue: 6, days: "01-30" },
  { month: "July", monthValue: 7, days: "01-31" },
  { month: "August", monthValue: 8, days: "01-31" },
  { month: "September", monthValue: 9, days: "01-30" },
  { month: "October", monthValue: 10, days: "01-31" },
  { month: "November", monthValue: 11, days: "01-30" },
  { month: "December", monthValue: 12, days: "01-31" },
];

const UserUsage = () => {

  // context
  const user = useUser()
  const { userId } = useUserTokenUsage()

  const navigate = useNavigate()

  // states
  const [currentMonthIndex, setCurrentMonthIndex] = useState(new Date().getMonth());
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);
  const [isOpenRequestModal, setIsOpenRequestModal] = useState(false);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  // this state is for handling the total tokens used of ALL USERS
  const [totalCurrentUsageTokens, setTotalCurrentUsageTokens] = useState(0);
  const [limitToken, setLimitToken] = useState(0);
  const [monthlyUsage, setMonthlyUsage] = useState(0);

  // state for handling user token usage PER USER
  const [userTokenUsage, setUserTokenUsage] = useState(0);
  const [minProgressValue, setMinProgressValue] = useState(0); // State to hold the minimum value of the progress bar

  const currentMonth = months[currentMonthIndex].month;

  // get the id from the URL
  const { id } = useParams();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const limit_token = await getFilterUsage(months[currentMonthIndex].monthValue, currentYear, user?.token);
        const response = await getListOfUsersTokenUsage(user?.token)
        if (response && limit_token) {
          // Update monthly usage
          const monthly = limit_token.users.find(user => user.user_id === Number(id))
          setMonthlyUsage(monthly.total_tokens_used > 0 ? monthly.total_tokens_used : 0)
          const data = response.users.find(user => user.user_id === Number(id));
          setTotalCurrentUsageTokens(data?.total_tokens_used);
          setLimitToken(data?.max_token);
        }
      } catch (error) {
        console.error("Error fetching filtered token usage:", error);
      }
    };

    fetchData();

  }, [currentMonthIndex, currentYear, user?.token, id]);

  useEffect(() => {
    const fetchUserTokenUsage = async () => {
      try {
        const response = await getListOfUsersTokenUsage(user?.token);
        const filteredResponse = response.users.find(user => user.user_id === Number(id));

        if (!filteredResponse) {

        };

        setUserTokenUsage(filteredResponse.total_tokens_used);
      } catch (error) {
        console.error(error);
      }
    };

    fetchUserTokenUsage();

  }, [id, user?.token]);

  useEffect(() => {
    const daysInMonth = parseInt(months[currentMonthIndex].days.split('-')[1], 10);
    const minVal = ((daysInMonth * userTokenUsage) / 15000) * 100;
    setMinProgressValue(minVal);
  }, [currentMonthIndex, userTokenUsage]);

  const changeMonth = increment => {
    const newIndex = (currentMonthIndex + increment + months.length) % months.length;
    setCurrentMonthIndex(newIndex);
  };

  return (
    <>
      <div className="h-screen w-full overflow-x-hidden bg-white flex flex-col justify-end">
        <div className="z-10 border-transparent bg-[#d9d9d9] p-5 w-[140px] -mb-8 rounded-t-xl ml-24">
          <button
            className="flex justify-center items-center text-black"
            onClick={() => navigate("/admin/user")}
          >
            <BiSolidLeftArrow className="mr-8 hover:text-hsi-yellow duration-300" />
            Usage
          </button>
        </div>
        <div className="flex flex-col gap-[15px] bg-[#d9d9d9] p-20">
          {/* 1st Container */}
          <div className="flex justify-between bg-white p-10 rounded-2xl border-black">
            <div className="button-container flex gap-[1px]">
              <button className="border border-hsi-yellow rounded-l-2xl px-12 py-1 bg-hsi-yellow text-white hover:bg-hsi-yellow duration-300">
                {" "}
                Cost{" "}
              </button>
              <button
                className="border border-hsi-yellow rounded-r-2xl px-12 py-1 bg-white text-hsi-yellow duration-300"
                disabled
                onClick={() => setIsOpenRequestModal(true)}
              >
                Activity
              </button>
            </div>
            <div className="flex gap-[10px]">
              <div className="grid-cols-3 justify-evenly border border-black rounded-2xl p-5 w-[150px] bg-white flex duration-300">
                <div className="arrow-left" onClick={() => changeMonth(-1)}></div>
                <span className="px-3 text-center"> {currentMonth} </span>
                <div className="arrow-right" onClick={() => changeMonth(1)}></div>
              </div>
              <button
                className="border border-[#e3b51a] rounded-2xl p-5 text-white bg-[#E3b51a] hover:bg-[#B18900] hover:border-[#e3b51a] duration-300"
                onClick={() => setIsOpenExportModal(true)}
              >
                Export
              </button>
            </div>
          </div>

          {/* 2nd Container */}
          <div className="flex flex-row bg-white p-10 rounded-2xl justify-between items-end w-full overflow-x-auto">
            <div className="flex flex-col">
              <p className="text-3xl font-bold">
                Monthly Usage{" "}
                <span className="text-lg font-normal">
                  {currentMonth} {months[currentMonthIndex].days}
                </span>
              </p>
              <DoughnutGraph totalCurrentTokens={monthlyUsage} maxLimit={limitToken} />
            </div>
            <div className="flex flex-col w-full max-w-[80%] px-5">
              <div className="py-6 text-right mb-12 mr-12">
                <span className="text-3xl text-center font-bold text-hsi-yellow">
                  <span className="text-3xl font-bold mb-12 mr-12 text-black">
                    {monthlyUsage !== 0 ? monthlyUsage : 0}
                  </span>
                </span>

                <div className="">
                  <span className="mt-1 mr-6 text-black text-xl font-bold tracking-wider">
                    <br />/{limitToken.toLocaleString()} limit
                  </span>
                </div>
              </div>
              <div className="py-3 flex justify-between items-center">
                <span className="text-2xl font-bold mt-12">
                  Credit Grants
                  <span className="font-light ml-2"> Tokens </span>
                </span>

                <button
                  className="border border-[#e3b51a] rounded-2xl p-1 text-white bg-[#E3b51a] hover:text-[#e3b51a] hover:bg-[#fefefe] duration-300 mr-12"
                  onClick={() => setIsOpenRequestModal(true)}
                >
                  Update <br /> Increase Limit
                </button>
              </div>
              <div className="mt-4 relative">
                <progress
                  value={monthlyUsage}
                  max={limitToken}
                  className="w-full h-12 duration-300"
                  style={{ marginBottom: '10px' }}
                />
                <div className="absolute right-0 top-0 mb-3 mt-3 mr-12 text-2xl font-bold">
                  {monthlyUsage !== undefined ? monthlyUsage : 0} - {limitToken.toLocaleString()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpenExportModal && (
        <UserUsageExport handleOpenModal={() => setIsOpenExportModal(false)} />
      )}
      {isOpenRequestModal && (
        <UserUsageRequestIncreaseLimit
          handleOpenModal={() => setIsOpenRequestModal(false)}
          userBearerToken={user?.token}
          userId={userId}
        />
      )}
    </>
  );
};

export default UserUsage;
