import React, { useContext, useEffect, useState } from "react";
import { useUser } from "../../../context/UserContext";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { FaAngleDown } from "react-icons/fa";
import { GiArtificialHive } from "react-icons/gi";
import { FaUser } from "react-icons/fa";
import { deleteAccount } from "../../../API/UserProfile";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

function MoreSettings({ closeMoreSettings }) {
  const user = useUser();
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    try {
      await deleteAccount();
      toast.success("Account deleted successfully");
      navigate("/auth/login");
    } catch (error) {
      console.log("Error during deleteAccount:", error);
      toast.error(`Failed to delete account: ${error.message}`);
    }
  };

  return (
    <div className="relative text-white">
      <div className="fixed z-10 top-0 left-0 w-[100%] h-[100%] overflow bg-[rgba(0,0,0,0.5)] flex flex-col mt-auto gap-[5px] justify-center items-start">
        <div className="w-[40%] rounded-[12px] bg-[#202123] shadow-lg shadow-[rgba(0,0,0,0.35)]/900 mt-0 mb-0 ml-auto mr-auto p-[20px] border-b-[4px] border-solid border-hsi-yellow/100">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 flex flex-row gap-[10px] ">
              <div
                className={`rounded-full h-[25px] aspect-square bg-blue-500`}
              ></div>
              <p className="mt-2 text-shark-50 text-xl">{user?.username} </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 text-right">
              <button onClick={() => closeMoreSettings(false)}>
                <IoMdCloseCircleOutline className="text-3xl bg-tuna-800 rounded-full m-1 w-auto h-auto p-1" />
              </button>
            </div>
          </div>
        </div>
        <div className="w-[40%] rounded-[12px]  bg-[#202123] shadow-lg shadow-[rgba(0,0,0,0.35)]/900 mt-0 mb-0 ml-auto mr-auto p-[20px] ">
          <div className="row">
            <h3 className="ml-5">Settings</h3>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6 p-10 w-[100%] h-[60%] rounded-[3px] bg-[#2e2f31] flex flex-row gap-[10px]">
              <GiArtificialHive className="bg-white text-black rounded-[12px] m-1 w-auto h-[30px] p-1" />{" "}
              <p className="mt-3 text-shark-50 text-2xl">General Settings</p>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 p-10 w-[100%] h-[60%] rounded-[3px] bg-[#313338]">
              <Dropdown>
                <Dropdown.Button>Theme</Dropdown.Button>
                <Dropdown.Content>
                  <Dropdown.List>
                    <Dropdown.Item>System</Dropdown.Item>
                    <Dropdown.Item>Dark</Dropdown.Item>
                    <Dropdown.Item>Light</Dropdown.Item>
                  </Dropdown.List>
                </Dropdown.Content>
              </Dropdown>
            </div>
          </div>

          <div className="row h-[150px] pt-2 pb-2 ">
            <div className="col-lg-6 col-md-6 col-sm-6 p-10 w-[100%] h-[60%] rounded-[3px] bg-[#2e2f31] flex flex-row gap-[10px]">
              <FaUser className="bg-white text-black rounded-[12px] m-1 w-auto h-auto p-2" />{" "}
              <p className="mt-3 text-shark-50 text-2xl">User Profile</p>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 pt-5 w-[100%] h-[60%] rounded-[3px] bg-[#313338] flex flex-col gap-1">
              <div className="row pl-6 pr-6">
                <button className="pt-2 pb-2 w-[100%] bg-[#444547] hover:bg-[#888889] duration-300">
                  Edit Profile
                </button>
              </div>
              <div className="row pl-6 pr-6">
                <button
                  onClick={handleDeleteAccount()}
                  className="pt-2 pb-2 w-[100%] bg-[#444547] hover:bg-red-500 duration-300"
                >
                  Delete Account
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// dropdown context for open state
const DropdownContext = React.createContext({
  open: false,
  setOpen: () => { },
});

// dropdown component for wrapping and providing context
function Dropdown({ children, ...props }) {
  const [open, setOpen] = React.useState(false);
  const dropdownRef = React.useRef(null);

  // click listeners for closing dropdown
  React.useEffect(() => {
    // close dropdown if click outside
    function close(e) {
      if (!dropdownRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
    // add or remove event listener
    if (open) {
      window.addEventListener("click", close);
    }
    // cleanup
    return function removeListener() {
      window.removeEventListener("click", close);
    };
  }, [open]); // only run if open state changes

  return (
    <DropdownContext.Provider value={{ open, setOpen }}>
      <div ref={dropdownRef} className="relative m-1">
        {children}
      </div>
    </DropdownContext.Provider>
  );
}

// dropdown button for triggering open
function DropdownButton({ children, ...props }) {
  const { open, setOpen } = React.useContext(DropdownContext); // get the context

  // to open and close the dropdown
  function toggleOpen() {
    setOpen(!open);
  }

  return (
    <div>
      <button
        onClick={toggleOpen}
        className="rounded px-4 py-2 font-bold text-white bg-[#444547] focus:bg-[#686869] flex items-center w-[100%]"
      >
        {children}
        <FaAngleDown className="text-white" />
      </button>
    </div>
  );
}

// dropdown content for displaying dropdown
function DropdownContent({ children }) {
  const { open } = React.useContext(DropdownContext); // get the context

  return (
    <div
      className={`absolute z-20 rounded border border-gray-300 bg-white overflow-hidden my-1 overflow-y-auto ${open ? "shadow-md" : "hidden"
        }`}
    >
      {children}
    </div>
  );
}

// dropdown list for dropdown menus
function DropdownList({ children, ...props }) {
  const { setOpen } = React.useContext(DropdownContext); // get the context

  return (
    <ul
      onClick={() => setOpen(false)}
      className="w-[100%] divide-y divide-gray-200 text-gray-700"
      {...props}
    >
      {children}
    </ul>
  );
}

// dropdown items for dropdown menus
function DropdownItem({ children, ...props }) {
  return (
    <li className="">
      <button
        className="w-[100%] py-3 px-20 whitespace-nowrap bg-[#686869] hover:bg-[#78797a] focus:bg-[#78797a] text-white hover:bg-"
        {...props}
      >
        {children}
      </button>
    </li>
  );
}

Dropdown.Button = DropdownButton;
Dropdown.Content = DropdownContent;
Dropdown.List = DropdownList;
Dropdown.Item = DropdownItem;

export default MoreSettings;
