import React from "react";
import { RiAddFill } from "react-icons/ri";
import { NewUserModal } from "../Modal/ListUserModal/NewUserModal";
import ListUserTable from '../Table/ListUserTable/ListUserTable'
import toast, { Toaster } from "react-hot-toast";
import { useAddUserMutation } from "../../../hooks/user/useUsers";
import { Outlet, useLocation } from "react-router-dom";


export const ListUser = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const addUserMutation = useAddUserMutation()
  const location = useLocation()

  const handleOpenModal = () => {
    setIsOpen(prev => !prev);
  };


  const onSubmit = (data) => {
    try {
      addUserMutation.mutate(data)
      handleOpenModal()
      toast.success("User added successfully!")
    } catch (error) {
      toast.error("Error", error.message)
    }
  };

  return (
    <section className="ml-[23rem] bg-white">

      <Toaster position="bottom-right" className="absolute bottom-0 right-0" />

      {
        // render a component if the path do not starts with "/admin/user/usage/"
        !location.pathname.startsWith('/admin/user/usage/') && (
          <>
            <section className="bg-[#DDBB21] flex items-center w-full h-28 border-b border-black">
              <section className="flex items-center justify-between w-full px-16">
                <h2 className="font-bold text-[2.7rem] font-poppins">Users</h2>
                <button
                  onClick={() => setIsOpen(true)}
                  className=" w-60 h-16 bg-[#DDBB21] flex items-center border-2 border-white text-white gap-3 text-xl rounded-xl justify-center font-bold"
                >
                  <RiAddFill className=" " />
                  Add User
                </button>
                {isOpen && <NewUserModal handleOpenModal={handleOpenModal} onSubmit={onSubmit} />}
              </section>
            </section>


            <section className="flex items-center justify-center">
              <ListUserTable />
            </section>
          </>
        )
      }

      <Outlet />
    </section>
  );
};
