import axios from "axios";

const baseUrl = process.env.REACT_APP_NODE_API_BASE_URL;

export const SigninUser = async credentials => {
  try {
    const userName = credentials.username;
    const userPass = credentials.password;

    const data = JSON.stringify({
      username: userName,
      password: userPass,
    });


    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/v1/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    const response = await axios.request(config);

    const token = response.data.data.token;
    localStorage.setItem("accessToken", token);
    return response.data;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const SignupUser = async credentials => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/register`,
    headers: {
      "Content-Type": "application/json",
    },
    data: credentials,
  };

  const response = await axios.request(config);
  return response.data;
};
export const logoutUser = async () => {
  try {
    const token = localStorage.getItem("accessToken");

    const config = {
      method: "post",
      url: `${baseUrl}/api/v1/logout`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.request(config);
    console.log("Logout Response:", response);
    localStorage.clear();
    window.location.href = "/auth/login";
    return response.data;
  } catch (error) {
    console.error("Error during logout:", error);
    throw error;
  }
};

export const forgotPassword = async data => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/v1/password/email`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      data: data,
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log("Error during forgotPassword:", error);
    throw error;
  }
};
