import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { TextArea, CustomInput } from "./InputFields";
import Dropdown from "./Dropdown";
import { zodResolver } from "@hookform/resolvers/zod";
import { AddPromptTemplateSchema } from "../../../../schemas/NewPromptTemplateSchema";
import { useUser } from "../../../../context/UserContext";
import {
  addPromptTemplates,
  getPromptTemplates,
} from "../../../../API/Prompts";

const NewTemplateModal = ({ openModal, setOpenModal, setData }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(AddPromptTemplateSchema),
    defaultValues: {
      title: "",
      description: "",
      user_input: "",
      response_format: "",
      prompt_feature: "",
    },
  });
  const user = useUser();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const handleCategoryChange = selectedValue => {
    setSelectedCategory(selectedValue);
    console.log("this is the selected value: ", selectedValue);
  };

  const onSubmit = async templateData => {
    try {
      const token = user?.token;
      setLoading(true);
      const existingTemplates = await getPromptTemplates(token);
      const isTitleTaken = existingTemplates?.data.some(
        template => template?.title === templateData?.title
      );

      if (isTitleTaken) {
        toast.error("Title is already existed.");
      } else {
        const token = user?.token;
        await addPromptTemplates(templateData, selectedCategory, token);
        const response = await getPromptTemplates(token);
        setData(response?.data);
        reset();
        toast.success("Template added successfully");
        setOpenModal(false);
      }
    } catch (error) {
      toast.error("An error occurred, please try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {openModal && (
        <section className="fixed top-0  z-20 left-0 w-full h-full flex justify-center items-center bg-[#00000086]">
          <section className="w-[50rem] h-[58rem] border pt-3 bg-white rounded-2xl overflow-y-auto">
            {loading && (
              <section className="fixed top-0 left-0 w-full h-full bg-opacity-50 bg-gray-600 flex justify-center items-center z-50">
                <div className="animate-spin rounded-full h-32 w-32 border-t-8 border-[#eab646] border-solid"></div>
              </section>
            )}
            <section className="flex  items-center justify-between pt-1 px-5 pb-3">
              <h1 className="text-3xl mt-2 font-bold pl-3"> + New Template</h1>
              <IoMdCloseCircleOutline
                className="cursor-pointer text-3xl hover:text-[#b18900]"
                onClick={() => setOpenModal(false)}
              />
            </section>
            <div className="h-2 bg-[#e3b51a]"> </div>
            <div className="h-2 bg-[#b18900]"> </div>

            <section className="px-8 pt-2 text-sm ">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <Dropdown onChange={handleCategoryChange} />
                  <CustomInput
                    label="Prompt Title"
                    name="title"
                    register={register}
                    required
                  />
                  {errors?.title && (
                    <span className="block text-red-400 text-lg text-left">
                      {errors?.title?.message}
                    </span>
                  )}
                  <CustomInput
                    label="Prompt Description"
                    name="description"
                    type="textarea"
                    register={register}
                    required
                  />
                  {errors?.description && (
                    <span className="block text-red-400 text-lg text-left">
                      {errors?.description?.message}
                    </span>
                  )}
                  <CustomInput
                    label="User Input"
                    name="user_input"
                    register={register}
                    required
                  />
                  {errors?.user_input && (
                    <span className="block text-red-400 text-lg text-left">
                      {errors?.user_input?.message}
                    </span>
                  )}
                  <CustomInput
                    label="Response Format"
                    name="response_format"
                    register={register}
                    required
                  />
                  {errors?.response_format && (
                    <span className="block text-red-400 text-lg text-left">
                      {errors?.response_format?.message}
                    </span>
                  )}
                  <TextArea
                    label="Prompt Feature"
                    name="prompt_feature"
                    type="textarea"
                    register={register}
                    required
                  />
                  {errors?.prompt_feature && (
                    <span className="block text-red-400 text-lg text-left">
                      {errors?.prompt_feature.message}
                    </span>
                  )}
                </div>
                <section className="flex justify-between mt-2">
                  <section></section>
                  <button
                    type="submit"
                    className=" h-16 my-2 w-32 text-xl  text-white rounded-2xl bg-[#e3b51a]"
                  >
                    Create
                  </button>
                </section>
              </form>
            </section>
          </section>
        </section>
      )}
    </>
  );
};

export default NewTemplateModal;
