import React, { useContext, useEffect, useRef, useState } from "react";
import { Button } from "../../Authentication/Login/ui/button";
import { useUser } from "../../../context/UserContext";
import { PiNotePencilDuotone } from "react-icons/pi";
import { FaRegCopy } from "react-icons/fa6";
import { BiLike, BiX } from "react-icons/bi";
import { BiDislike } from "react-icons/bi";
import Feedback from "./Feedback";
import NegativeFeedback from "./NegativeFeedback";
import ThemeModeContext from "../../../context/ThemeContext";

const Chat = ({ conversation, isLast, onClickCopy }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const el = useRef(null);
  const user = useUser();

  useEffect(() => {
    el.current?.scrollIntoView({ behavior: "smooth" });
  }, [isLast]);

  const [feedback, setFeedback] = useState(false);
  const [negativeFeedback, setNegativeFeedback] = useState(false);
  const [feedbackValue, setFeedbackValue] = useState(null);
  const [conversationsData, setConversationsData] = useState([]);
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = hovered => {
    setIsHovered(hovered);
  };
  const handleClickChat = props => {
    if (props) {
      setIsOpen(!isOpen);
    }
  };

  const theme = useContext(ThemeModeContext);

  return (
    <div
      className={`border-l-[0.5rem] border-hsi-yellow resize-none py-[20px] px-[21px] mx-auto mt-10 w-[45vw] gap-10 bg-gray-500/20 text-xl rounded-3xl hover:shadow-lg hover:cursor-pointer
        ${conversation.role === "user" &&
        `border-l-[0.5rem] border-hsi-yellow/50 ${theme.theme === 'light' ? 'bg-gray-400/20' : 'bg-gray-600/30'}`
        } 
        ${isLast ? "mb-[20rem] " : ""} flex flex-col`}
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      onClick={() => handleClickChat(conversation.role === "assistant")}
    >
      <div className="row relative">
        <div className="col-lg-6 col-md-6 col-sm-6 flex flex-row">
          <div
            className={`rounded-full h-[25px] aspect-square ${conversation.role === "user" ? "bg-blue-500" : "bg-green-500"
              } `}
          ></div>
          <p
            className={`text-xl font-semibold ${conversation.role === "user" ? `${theme.theme === 'light' ? 'text-[#000000]' : 'text-white'}` : "text-yellow-500"
              } ml-5 mt-1.5`}
          >
            {conversation.role === "user" ? user?.username : "HAI"}{" "}
          </p>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 text-right">
          {isHovered && (
            <button className="">
              {conversation.role === "user" ? (
                <PiNotePencilDuotone
                  className={`${theme.theme === 'light' ? 'text-lightText' : 'text-white'} text-2xl  bg-transparent m-1 w-auto h-auto`}
                  onClick={handleOpen}
                />
              ) : (
                <FaRegCopy
                  className={`${theme.theme === 'light' ? 'text-lightText' : 'text-white'} text-2xl bg-transparent m-1 w-auto h-auto`}
                  onClick={e => {
                    e.stopPropagation();
                    onClickCopy(conversation.content);
                  }}
                />
              )}
            </button>
          )}
        </div>
        <div className="row w-[100%] pl-20 overflow-x-auto">
          <div className="col-lg-12 col-md-12 col-sm-12 flex flex-row overflow-x-auto ">
            <p className={`pt-6 font-[500] ${theme.theme === 'light' ? 'text-shark-950' : 'text-white'}`} ref={el}>
              {conversation.content}
            </p>
          </div>
        </div>
        {conversation.role === "assistant" && (
          <div className="row relative pr-[21px]">
            <div className="col-lg-12 col-md-12 col-sm-12 bottom-0 flex flex-row justify-end">
              {isOpen && (
                <>
                  <button
                    onClick={e => {
                      const iconD = document.querySelector(".iconDislike");
                      setFeedback(true);
                      e.target.style.color = "#EFC62F";
                      iconD.style.color = "#fefefe";
                      setFeedbackValue("like");
                      setConversationsData(conversation);
                    }}
                  >
                    <BiLike className={`${theme.theme === 'light' ? 'text-lightText' : 'text-white'} iconLike text-2xl bg-transparent m-1 w-auto h-auto pr-[5px] pl-[5px]`} />
                  </button>
                  <button
                    onClick={e => {
                      const iconL = document.querySelector(".iconLike");
                      setNegativeFeedback(true);
                      e.target.style.color = "#EFC62F";
                      iconL.style.color = "#fefefe";
                      setFeedbackValue("dislike");
                      setConversationsData(conversation);
                    }}
                  >
                    <BiDislike className={`${theme.theme === 'light' ? 'text-lightText' : 'text-white'} text-2xl iconDislike bg-transparent m-1 w-auto h-auto px-[5px]`} />
                  </button>
                </>
              )}
            </div>
          </div>
        )}

        {feedback && <Feedback closeFeedback={setFeedback} />}
        {negativeFeedback && (
          <NegativeFeedback closeFeedback={setNegativeFeedback} />
        )}
        <div className="row">
          <div className="relative w-full flex items-center">
            {conversation.role === "user" && (
              <>
                {isOpen && (
                  <Button
                    className={`mx-auto px-4 pr-2 content-center rounded-full text-3xl shadow-xl ring-1 ${theme.theme === 'light' ? 'ring-[#EBEDEF40]/30 bg-[#BDBDBD] text-black' : 'ring-gray-500/50 bg-shark-800 text-white'} `}
                  >
                    Submit
                    <BiX
                      onClick={() => handleOpen(false)}
                      className={`${theme.theme === 'light' ? 'bg-shark-500 text-white' : 'bg-[#EBEDEF40]'} py-3 px-3 rounded-full ml-4 text-black text-3xl h-auto w-auto hover:bg-hsi-yellow`}
                    />
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {feedback && (
        <Feedback
          closeFeedback={() => {
            setFeedback(false);
            setFeedbackValue(null);
          }}
          feedbackValue={feedbackValue}
          conversationsData={conversationsData}
        />
      )}
      {negativeFeedback && (
        <NegativeFeedback
          closeFeedback={() => {
            setNegativeFeedback(false);
            setFeedbackValue(null);
          }}
          feedbackValue={feedbackValue}
          conversationsData={conversationsData}
        />
      )}
    </div>
  );
};

export default Chat;
