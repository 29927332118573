import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BsFillCircleFill } from "react-icons/bs";
import { UpdateUserSchema } from "../../../../../schemas/UserSchema";
import { useUpdateUserMutation } from "../../../../../hooks/user/useUsers";
import toast from "react-hot-toast";

const DropdownForm = ({ user, handleDeleteUser, setIsMenuOpen }) => {

  const updateUserMutation = useUpdateUserMutation()
  const [status, setStatus] = useState(user.active);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(UpdateUserSchema),
    defaultValues: {
      first_name: "",
      middle_name: "",
      last_name: "",
      username: "",
      role: null,
      active: "",
      email: "",
      password: "",
    }
  })


  const generateError = (error) => {
    if (error.response.data.message) {
      const errorMessages = error.response.data.message;
      Object.keys(errorMessages).forEach(field => {
        toast.error(`${errorMessages[field]}`);
      });
    }
  }

  const onSubmit = async (data) => {
    const { username, first_name, middle_name, last_name, role, email, password } = data

    const updatedData = {
      ...data,
      active: status
    };

    updateUserMutation.mutate({
      id: user?.id,
      ...updatedData
    }, {
      onSuccess: (res) => {
        setIsMenuOpen(false)
        toast.success("User updated successfully!")
        reset()
      },
      onError: (error) => {
        console.error(error)
        generateError(error)
      }
    })
  }

  const updateStatus = (e) => {
    e.preventDefault()
    const newStatus = status === 'enable' ? 'disable' : 'enable';
    setStatus(newStatus);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 h-fit ">
      <section className="flex items-center justify-center flex-wrap gap-10 mt-4">
        <section className="w-[30%]">
          <label htmlFor="" className="block text-left font-normal">User name</label>
          <input
            {...register("username")}
            type="text"
            className="border border-black bg-gray-200 w-full h-12 outline-none rounded-lg px-4"
          />
          {errors?.username && <span className="block text-red-400 text-lg text-left">{errors?.username?.message}</span>}
        </section>

        <section className="w-[30%]">
          <label htmlFor="role" className="block text-left font-normal">Role</label>
          <select
            {...register("role")}
            className=" border border-black h-12 rounded-lg px-4 w-full"
          >
            <option value="" className="text-gray-300" selected disabled hidden>Role</option>
            <option value="user">user</option>
            <option value="admin">admin</option>
          </select>
          {errors?.role && <span className="block text-red-400 text-lg text-left">{errors?.role?.message}</span>}
        </section>

        <section className="w-[30%]">
          <label htmlFor="active" className="block text-left font-normal">Status</label>
          <button className="border border-black flex items-center capitalize h-12 gap-8 justify-center rounded-lg w-full"
            onClick={(e) => updateStatus(e)}
          >
            <BsFillCircleFill className={`${status === 'enable' ? "text-green-500" : "text-red-500"}`} />
            <span className="my-auto">{status === 'enable' ? "Active" : "Inactive"}</span>
          </button>
          {errors?.active && <span className="block text-red-400 text-lg text-left">{errors?.active?.message}</span>}
        </section>
      </section>


      <section className="flex items-center justify-center flex-wrap gap-10">
        <section className="w-[30%]">
          <label htmlFor="" className="block text-left font-normal">First Name</label>
          <input
            {...register("first_name")}
            type="text"
            className="border border-black bg-gray-200 w-full h-12 outline-none rounded-lg px-4"
          />
          {errors?.first_name && <span className="block text-red-400 text-lg text-left">{errors?.first_name?.message}</span>}
        </section>
        <section className="w-[30%]">
          <label htmlFor="" className="block text-left font-normal">Middle Name</label>
          <input
            {...register("middle_name")}
            type="text"
            className="border border-black bg-gray-200 w-full h-12 outline-none rounded-lg px-4"
          />
          {errors?.middle_name && <span className="block text-red-400 text-lg text-left">{errors?.middle_name?.message}</span>}
        </section>
        <section className="w-[30%]">
          <label htmlFor="" className="block text-left font-normal">Last Name</label>
          <input
            {...register("last_name")}
            type="text"
            className="border border-black bg-gray-200 w-full h-12 outline-none rounded-lg px-4"
          />
          {errors?.last_name && <span className="block text-red-400 text-lg text-left">{errors?.last_name?.message}</span>}
        </section>
      </section>

      <section className="flex items-center justify-center flex-wrap gap-10">
        <section className="w-[46%]">
          <label htmlFor="" className="block text-left font-normal">Email</label>
          <input
            {...register("email")}
            // type="email"
            className="border border-black bg-gray-200 w-full h-12 outline-none rounded-lg px-4"
          />
          {errors?.email && <span className="block text-red-400 text-lg text-left">{errors?.email?.message}</span>}
        </section>
        <section className="w-[46%]">
          <label htmlFor="" className="block text-left font-normal">Password</label>
          <input
            {...register("password")}
            type="text"
            className="border border-black bg-gray-200 w-full h-12 outline-none rounded-lg px-4"
          />
          {errors?.password && <span className="block text-red-400 text-lg text-left">{errors?.password?.message}</span>}
        </section>
      </section>

      <section className="h-2 bg-yellow-500 w-full"></section>

      <section className="flex items-center bg-white justify-between h-fit px-16 p-5 -mt-[1px]">
        <button
          className="w-auto h-auto py-2 px-10 rounded-2xl border border-black font-medium font-poppins bg-gray-300 hover:bg-red-500 text-red-500 hover:text-white"
          onClick={(e) => {
            e.preventDefault()
            handleDeleteUser(user?.id)
          }}
        >
          Delete
        </button>
        <button
          type="submit"
          className="w-auto h-auto py-2 px-10 rounded-2xl text-white font-medium font-poppins bg-yellow-500 hover:bg-yellow-600"
        >
          Update
        </button>
      </section>
    </form >
  );
}

export default DropdownForm;