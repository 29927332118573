import React, { useContext, useEffect, useState } from "react";
import {
  useConversationList,
  useConversationListUpdate,
} from "../../../context/ConversationListContext";
import { useConversationsUpdate } from "../../../context/ConversationsContext";
import { getConversationsList } from "../../../API/Conversations";
import { Button } from "../../Authentication/Login/ui/button";
import { Link, useNavigate } from "react-router-dom";
import History from "./History";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useUserUpdate, useUser } from "../../../context/UserContext";
import EllipsisModal from "./ellipsis.component";
import chatIcon from "../../../Images/chat.svg";
import { Toaster } from "react-hot-toast";
import ThemeModeContext from "../../../context/ThemeContext";

const LeftSideBar = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  const conversationsList = useConversationList();
  const updateConversationList = useConversationListUpdate();
  const updateConversations = useConversationsUpdate();

  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(window.innerWidth > 768);

  const theme = useContext(ThemeModeContext);

  useEffect(() => {
    const initializeConversationsList = async () => {
      try {
        const response = await getConversationsList();
        updateConversationList(response?.data);
      } catch (error) {
        console.log(error);
      }
    };
    initializeConversationsList();
  }, []);

  const toggleSideBar = () => {
    setExpanded(!expanded);
  };

  const handleResize = () => {
    setExpanded(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [openSettings, setOpenSettings] = useState(false);

  const handleOpenSettings = () => {
    setOpenSettings(!openSettings);
  };

  return (
    <div className="relative">
      <Button
        onClick={toggleSideBar}
        className="absolute -right-[4.5rem] bg-transparent z-50"
      >
        {!expanded ? (
          <FiChevronRight className="w-10 h-10 hover:text-[#eab646] text-[#959393]" />
        ) : (
          <FiChevronLeft className="w-10 h-10 hover:text-[#eab646] text-[#959393]" />
        )}
      </Button>

      <nav
        className={`h-screen ${theme.theme === 'light' ? 'bg-light' : 'bg-shark-950'} transition-[width] duration-300 ${expanded ? "w-[255px]" : "w-0"
          }`}
      >
        <div className="h-full flex flex-col justify-between gap-10 py-10 px-6">
          <Link
            onClick={() => updateConversations([])}
            to="/home"
            className={`${theme.theme === 'light' ? 'bg-gradient-to-b from-[#424242] to-[#EAB646]' : 'bg-gradient-to-b from-white to-yellow-200'} text-[2.75rem] font-verdana-bold bg-clip-text text-transparent  font-bold ml-3 mr-3 -mt-1 hover:text-transparent hover:no-underline focus:text-transparent focus:no-underline`}
          >
            HAI APP
          </Link>
          <Button
            onClick={() => {
              updateConversations([]);
              navigate("/home");
            }}
            className={`${theme.theme === 'light' ? 'bg-[#BDBDBD] ring-[#BDBDBD]/50' : 'bg-[#585858] ring-gray-500/50 text-shark-50'} min-w-[200px] min-h-[50px] flex items-start justify-start  rounded-full whitespace-nowrap hover:bg-hsi-yellow/100 duration-300`}
          >
            <div className={`${theme.theme === 'light' ? 'bg-tuna-800' : 'bg-tuna-950'} font-[600] w-16 h-16 rounded-full  p-4`}>
              <img src={chatIcon} alt="chat icon" className="text-white" />
            </div>
            <p className={`${theme.theme === 'light' ? 'text-lightText' : 'text-white'} text-2xl pl-7 mt-3.5 mb-2`}> New Chat</p>
          </Button>

          <div className="overflow-x-hidden  mt-4">
            <ul className="flex flex-col-reverse gap-2">
              {
                conversationsList &&
                conversationsList?.map((conversation, index) => (
                  <History
                    key={index}
                    index={index}
                    conversation={conversation}
                  />
                ))
              }
              {conversationsList?.length === 0 && <span className={`${theme.theme === 'light' ? 'text-lightText' : 'text-shark-950'} font-[600] text-lg mx-auto mt-12`}>No existing conversation</span>}
            </ul>
            {openSettings && (
              <EllipsisModal
                closeSettings={setOpenSettings}
                className="w-auto  "
              />
            )}
          </div>

          <div className="flex flex-col mt-auto gap-10 border-t border-t-gray-500/50 pt-7 pl-5 pr-5">
            <div className="grid grid-cols-2 gap-7 py-5">
              <div className="flex gap-3 text-xl items-center">
                <div
                  className={`rounded-full h-[25px] aspect-square bg-blue-500`}
                ></div>
                <p className={`m-0 ${theme.theme === 'light' ? "text-lightText" : "text-white"} font-[500] text-2xl`}>{user?.username}</p>
              </div>

              <div className="text-2xl text-right">
                <Button
                  className={`${theme.theme === 'light' ? "bg-light" : "bg-shark-950"} bg-transparent text-2xl`}
                  onClick={handleOpenSettings}
                >
                  <i className="fa-solid fa-ellipsis opacity-50 hover:opacity-100" style={{ color: theme.theme === 'light' ? 'black' : 'white' }}></i>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <Toaster position="top-right" />
    </div>
  );
};

export default LeftSideBar;
