import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FaPlus } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import { updateUserToken } from "../../../../API/UserUsage";
import { updateUserTokenSchema } from "../../../../schemas/UpdateUserTokenSchema";
import toast from "react-hot-toast";


export const UserUsageRequestIncreaseLimit = ({ handleOpenModal, userBearerToken, userId }) => {
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(updateUserTokenSchema)
  });

  const onSubmit = async data => {
    const { max_token } = data

    try {
      await updateUserToken({ id: userId, max_token }, userBearerToken)
      reset();
      handleOpenModal()
      toast.success("Token limit updated successfully")
    } catch (error) {
      handleOpenModal()
      toast.error(error?.response?.data?.message)
    }
  };
  return (
    <section className="fixed top-0 left-0 w-full h-full flex justify-center items-center  bg-[#00000065]">
      <section className="w-[50rem] h-fit border  bg-white rounded-3xl">
        <form onSubmit={handleSubmit(onSubmit)} className="py-4">
          <section className=" flex items-center justify-between text-3xl px-10 bg-yellow-500 rounded-t-3xl mt-[-11px] p-5">
            <div>
              <h1 className="text-4xl font-bold text-black"> Update Max Token</h1>
            </div>
          </section>

          <section className="flex gap-5 items-center px-10 py-5 ">

            <section className="w-full p-5">
              <h4 className="font-bold">Current Token Limit </h4>
              <input type="text"
                placeholder="Current Token Limit"
                {...register("max_token")}
                className="border-2 p-5 w-full mt-5" />
              {errors?.max_token && (
                <span className="block text-red-400 text-2xl mt-4 text-left">
                  {errors?.max_token?.message}
                </span>
              )}
            </section>

          </section>

          <section className=" w-full px-16 mt-12 mb-4 flex justify-between">
            <button
              className="w-48 h-auto rounded-xl py-4 bg-[#d9d9d9] font-medium mr-[5px] hover:bg-[#9a9999]"
              onClick={handleOpenModal}
            >
              Cancel
            </button>

            <button
              type="submit"
              className="w-48 h-auto py-4 flex items-center rounded-xl text-white  font-medium justify-center bg-[#e3b51a] hover:bg-[#eac446]"
            >
              <FaPlus /> Add
            </button>
          </section>
        </form>
      </section>
    </section>
  );
};