import React from "react";

const DeleteTemplateModal = ({
  openDeleteModal,
  handleDeleteTemplate,
  setOpenDeleteModal,
}) => {
  return (
    <>
      {" "}
      {openDeleteModal && (
        <section className="fixed top-0  z-20 left-0 w-full h-full flex justify-center items-center bg-[#00000086]">
          <section className="h-[200px] w-[500px]  pt-14 flex  flex-col items-center  font-[700] bg-white  rounded-2xl border-[1px] border-black">
            <span className="text-4xl">
              Are you sure to delete this prompt ?
            </span>
            <section className="flex gap-44 mt-10 text-xl">
              <button
                className=" h-16 my-2 w-36 text-white rounded-lg bg-[#e3b51a] hover:bg-[#b18900]"
                onClick={handleDeleteTemplate}
              >
                Yes
              </button>
              <button
                className=" h-16 my-2 w-36  text-white rounded-lg bg-[#e3b51a] hover:bg-[#b18900]"
                onClick={() => setOpenDeleteModal(false)}
              >
                No
              </button>
            </section>
          </section>
        </section>
      )}
    </>
  );
};

export default DeleteTemplateModal;
