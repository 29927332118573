import axios from "axios";

const baseUrl = process.env.REACT_APP_NODE_API_BASE_URL;

// Get filtered token usage for a certain time
// Return the overall organization token usage
export const getFilterUsage = async (month, year) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${baseUrl}/api/v1/admin/filtered-usage?month=${month}&year=${year}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    
    return response?.data?.data;

  } catch (error) {
    console.log(error);
  }
};

// Get lists of organization token usage
export const getListOfOrgTokenUsage = async () => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await axios.get(`${baseUrl}/api/v1/admin/organizations`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    });

    return response?.data?.data;

  } catch (error) {
    console.log(error);
  }
};

// Update organization token
export const updateOrgToken = async ( org ) => {
  const token = localStorage.getItem("accessToken");
  try {
    const response = await axios.put(`${baseUrl}/api/v1/admin/update-organization-token/${org.orgId}?max_token=${org.max_token}`, {},{
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      }
    });

    return response?.data?.data;

  } catch (error) {
    console.error("Error in updateOrgToken:", error);
    throw error;
  }
};