import { createContext, useContext, useState } from "react";

const ConversationListContext = createContext();
const ConversationListUpdateContext = createContext();

export const useConversationList = () => {
    return useContext(ConversationListContext);
}

export const useConversationListUpdate = () => {
    return useContext(ConversationListUpdateContext);
}

export const ConversationListProvider = ({ children }) => {
    const [conversationList, setConversationList] = useState([])

    function updateConversationList(newConversationList){
        setConversationList(newConversationList);
    }

    return (
        <ConversationListContext.Provider value={conversationList}>
            <ConversationListUpdateContext.Provider value={updateConversationList}>
                {children}
            </ConversationListUpdateContext.Provider>
        </ConversationListContext.Provider>
    )
}

// export default ConversationListProvider;

