import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../Authentication/Login/ui/button";
import {
  usePromptTemplates,
  usePromptTemplatesUpdate,
} from "../../../context/PromptTemplateContext";
import { getPromptTemplates } from "../../../API/PromptTemplates";
import Accordion from "../../../components/accordion/Accordion";
import Modal from "../../../components/modal/Modal";
import { getGroupedPrompts } from "../../../API/Department";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { LuFolderCheck, LuClipboardList } from "react-icons/lu";
import { LiaPencilRulerSolid } from "react-icons/lia";
import { AiOutlineBarChart } from "react-icons/ai";
import { TbWorldSearch, TbDeviceDesktopAnalytics } from "react-icons/tb";
import { BsGraphUpArrow } from "react-icons/bs";
import { VscGraph } from "react-icons/vsc";
import Prompt from "./Prompt";
import searchIcon from "../../../Images/wow.svg";
import ThemeModeContext from "../../../context/ThemeContext";

const RightSideBar = () => {
  // Context Initializations
  const promptTemplates = usePromptTemplates();
  const updatePromptTemplates = usePromptTemplatesUpdate();

  // Use states
  const [expanded, setExpanded] = useState(window.innerWidth > 768);
  const [searchedTemplate, setSearchedTemplate] = useState([]);
  const [searchBar, setSearchBar] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [id] = useState(null);

  const theme = useContext(ThemeModeContext);

  const iconOrder = [
    <BsGraphUpArrow className="h-8 w-8 mt-2" />,
    <VscGraph className="h-8 w-8 mt-2" />,
    <AiOutlineBarChart className="h-8 w-8 mt-2" />,
    <TbWorldSearch className="h-8 w-8 mt-2" />,
    <TbDeviceDesktopAnalytics className="h-8 w-8 mt-2" />,
    <LuFolderCheck className="h-8 w-8 mt-2" />,
    <LuClipboardList className="h-8 w-8 mt-2" />,
    <LiaPencilRulerSolid className="h-8 w-8 mt-2" />,
  ];

  useEffect(() => {
    const initializePromptTemplates = async () => {
      try {
        const response = await getGroupedPrompts();
        updatePromptTemplates(response);
      } catch (err) {
        console.error(err.response);
      }
    };

    initializePromptTemplates();
  }, [updatePromptTemplates]);

  const handleResize = () => {
    setExpanded(window.innerWidth > 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSideBar = () => {
    setExpanded(!expanded);
  };

  const handlePromptSelect = (prompt) => {
    setModalContent(prompt);
    setModalOpen(true);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchBar(value);
    if (value) {
      const filteredPrompts = prompts.filter(
        (prompt) =>
          prompt.category_name.toLowerCase().includes(value.toLowerCase()) ||
          prompt.prompt_feature.toLowerCase().includes(value.toLowerCase())
      );
      setSearchedTemplate(filteredPrompts);
    } else {
      setSearchedTemplate([]);
    }
  };

  const getIconForIndex = (index) => iconOrder[index % iconOrder.length];

  const [categories, setCategories] = useState([]);
  const [prompts, setPrompts] = useState([]);

  useEffect(() => {
    const fetchPromptTemplates = async () => {
      try {
        const response = await getPromptTemplates();
        setPrompts(response.data);

        const uniqueCategories = Array.from(
          new Set(response.data.map((item) => item.category_id))
        ).map((category_id) => {
          return response.data.find((item) => item.category_id === category_id);
        });

        const order = [
          "Sales",
          "Finance",
          "Marketing",
          "Human Resources",
          "Graphics",
          "Quality Assurance",
          "Software Engineering",
          "Research and Development",
        ];

        const sortedCategories = uniqueCategories
          .sort((a, b) => {
            return (
              order.indexOf(a.category_name) - order.indexOf(b.category_name)
            );
          })
          .map((category) => ({
            id: category.category_id,
            name: category.category_name,
          }));

        setCategories(sortedCategories);
      } catch (error) {
        console.log(error);
      }
    };

    fetchPromptTemplates();
  }, []);

  return (
    <>
      <Modal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        modalContent={modalContent}
        id={id}
      />

      <div className="relative">
        <Button
          onClick={toggleSideBar}
          className="absolute -left-[4.5rem] bg-transparent"
        >
          {!expanded ? (
            <FiChevronLeft className="w-10 h-10 hover:text-[#eab646] text-[#959393]" />
          ) : (
            <FiChevronRight className="w-10 h-10 hover:text-[#eab646] text-[#959393]" />
          )}
        </Button>
        <div
          className={`h-screen ${
            theme.theme === "light" ? "bg-light" : "bg-shark-950"
          } overflow-hidden transition-[width] duration-300 ${
            expanded ? "w-[255px]" : "w-0"
          }`}
        >
          <div className="flex flex-col h-full gap-10 px-6 py-10">
            <div className="flex p-2 relative text-[1.375rem] rounded-md">
              <input
                value={searchBar}
                onChange={handleSearchChange}
                type="text"
                className="flex-1 bg-[#7c7d7e] placeholder:text-[#b1b1b2] placeholder:pl-5 placeholder:drop-shadow-md text-shark-50 p-3 rounded-full relative placeholder:text-[1.5rem] truncate"
                placeholder="Search Category"
              />
              {expanded && (
                <img
                  src={searchIcon}
                  className="h-8 absolute shadow-sm right-16 fill-[#bdbebe] top-5"
                  alt="search icon"
                />
              )}
            </div>

            <div className="flex flex-col h-full gap-5 pt-2 overflow-x-hidden overflow-y-auto">
              {searchedTemplate.length > 0 || searchBar !== ""
                ? searchedTemplate.map((promptTemplate, index) => (
                    <Prompt
                      key={index}
                      promptTemplate={promptTemplate}
                      handlePromptSelect={handlePromptSelect}
                      setModalOpen={setModalOpen}
                      setModalContent={setModalContent}
                    />
                  ))
                : categories.map((category, index) => (
                    <Accordion
                      key={category.id}
                      header={
                        <>
                          <p>{getIconForIndex(index)}</p>
                          <p>{category.name}</p>
                        </>
                      }
                    >
                      {prompts
                        .filter((prompt) => prompt.category_id === category.id)
                        .map((prompt) => (
                          <Prompt
                            key={prompt.id}
                            promptTemplate={prompt}
                            handlePromptSelect={handlePromptSelect}
                            setModalOpen={setModalOpen}
                            setModalContent={setModalContent}
                          />
                        ))}
                    </Accordion>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RightSideBar;
