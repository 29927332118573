import React, { useState } from 'react';
import { MdPersonSearch } from "react-icons/md";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import RemoveMember from './RemoveMember';
import '../../Scroll/Scroll.css';
import { memberData } from './data/memberData';



const ManageMembers = ({closeManage}) => {

  const [removeMember, setRemoveMember] = useState(false);

  const handleRemove = () => {
    setRemoveMember(!removeMember);
  }

  return(
    <>
      {removeMember && <RemoveMember onCancel={setRemoveMember}/>}
      <div className='relative duration-300'>
          <div className='fixed z-10 top-0 left-0 w-[100%] h-[100%] bg-[rgba(0,0,0,0.5)]'> 
              <div className='w-[90%] sm:w-[70%] rounded-[12px] bg-[#fefefe] mt-5 mx-auto p-[20px] border-b-8 border-solid border-hsi-yellow/100 grid grid-cols-2'>
                  <div className='font-bold text-2xl '> Manage Members </div>
                  <div className='text-right px-5'> 
                      <button className='text-[#0e0e0e] hover:text-[#fefefe] duration-300' onClick={() => { closeManage(false) }}> 
                      
                        <IoMdCloseCircleOutline className='text-4xl' /> 
                      </button>
                  </div>
              </div>
              <div className='w-[90%] sm:w-[70%] rounded-[12px] h-[80%] bg-[#fefefe] mt-1 mb-7 mx-auto p-[20px] border-b-[4px] overflow-auto'>
                  <div className='grid grid-cols-3 overflow-auto'>

                      {/* Table Header */}
                      <div className='p-5 border-[1px] bg-hsi-yellow border-b-black text-black font-bold '>Name</div>
                      <div className='p-5 border-[1px] bg-hsi-yellow border-b-black text-black font-bold '>Position</div>
                      <div className='p-5 border-[1px] bg-hsi-yellow border-b-black text-black font-bold '>Action</div>
                      
                      {memberData.map(item => {
                          return (
                            <>
                              <div className='p-5 text-xl items-center border-[1px] border-b-black '>
                                <div className='flex flex-row items-center '>
                                  <img className="w-[50px] h-[100%]" src="https://www.shareicon.net/data/2016/09/15/829453_user_512x512.png" alt="user-profile-pic" />
                                  <p className='mt-2 ml-5'> {item.name} </p>
                                </div>
                              </div>
                              <div className='p-5 flex items-center text-xl border-[1px] border-b-black '> {item.role}</div>
                              <div className='p-5 flex items-center text-xl border-[1px] border-b-black gap-2'>
                                  <button className='p-3 bg-[#cfcfcf] hover:bg-[#ea4d4d] hover:text-[#fefefe] rounded-[5px] duration-300'
                                    onClick={handleRemove}
                                  >
                                    <AiOutlineClose className='text-3xl' />
                                  </button>
                              </div>
                            </>
                              );
                          })
                      }
                      

                      
                  </div>
                </div>
            </div>
        </div>
        
            
            
    </>
  );
}

export default ManageMembers;