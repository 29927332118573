import React, { useContext, useEffect, useState } from "react";
import { Button } from "../../Authentication/Login/ui/button";
import { newPrompt } from "../../../API/Conversations";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import {
  useConversations,
  useConversationsUpdate,
} from "../../../context/ConversationsContext";
import {
  useConversationList,
  useConversationListUpdate,
} from "../../../context/ConversationListContext";
import { usePromptTemplates } from "../../../context/PromptTemplateContext";
import { getConversations } from "../../../API/Conversations";
import NewChat from "../../../components/NewChat";
import { VscSend } from "react-icons/vsc";
import { FiRefreshCcw } from "react-icons/fi";
import ThemeModeContext from "../../../context/ThemeContext";

import Chat from "./Chat";

const Terminal = () => {
  // Form methods initializations
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // Theme Context for dark/light mode
  const theme = useContext(ThemeModeContext);

  // Context and route params initializations
  const conversations = useConversations();
  const updateConversations = useConversationsUpdate();
  const conversationsList = useConversationList();
  const updateConversationList = useConversationListUpdate();
  const promptTemplates = usePromptTemplates();
  const { conversation_id } = useParams();
  const navigate = useNavigate();

  // Use states
  const [prompt, setPrompt] = useState("");
  const [expandCommands, setExpandCommands] = useState(false);

  // Conversations value initialization
  useEffect(() => {
    async function initializeConversations() {
      try {
        const response = await getConversations(parseInt(conversation_id));
        updateConversations(response ?? []);
      } catch (err) {
        console.error(err);
      }
    }

    initializeConversations();
  }, []);


  // Handles the new chat input from the user
  const onSubmit = async data => {
    const userChat = {
      role: "user",
      content: prompt,
    };
    // Append the user's new chat
    updateConversations([...conversations, userChat]);
    setPrompt("");

    try {
      var messageBody = document.querySelector("#chatHolder");
      messageBody.scrollTop =
        messageBody.scrollHeight - messageBody.clientHeight;
      const res = await newPrompt(
        data,
        parseInt(conversation_id),
        !parseInt(conversation_id) ? data.newPrompt : ""
      );

      const responseChat = {
        role: "bot",
        content: res.response,
      };

      if (!conversation_id) {
        const newHistory = {
          id: res.sessionId,
          title: data.newPrompt,
        };

        updateConversationList([...conversationsList, newHistory]);
        navigate(`/chat/${res.sessionId}`);
      }

      updateConversations([...conversations, userChat, responseChat]);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePromptInput = e => {
    setPrompt(e.target.value);

    if (e.target.value.length === 1 && e.target.value === "!") {
      setExpandCommands(true);
    } else {
      setExpandCommands(false);
    }
  };

  const handlePromptSelect = prompt => {
    setPrompt(prompt);
    setExpandCommands(false);
  };

  const resizeTextArea = () => {
    const textArea = document.querySelector("#newPrompt");
    textArea.style.height = "18px";
    textArea.style.height = textArea.scrollHeight + "px";
  };

  useEffect(resizeTextArea, [prompt]);

  const handleCopyPrompt = content => {
    navigator.clipboard.writeText(content);
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <div className={`${theme.theme === 'light' ? 'bg-lightSecondary' : 'bg-tuna-900'} relative items-start flex-1 w-full overflow-hidden  h-screen`} >

      <div className="flex flex-col h-screen overflow-y-auto" id="chatHolder">
        {conversations?.length ? (
          conversations.map((conversation, index) => (
            <Chat
              key={index}
              conversation={conversation}
              isLast={index === conversations.length - 1}
              onClickCopy={handleCopyPrompt}
            />
          ))
        ) : (
          <NewChat />
        )}
      </div>

      {/* Prompt Input */}
      <div className={`absolute bottom-0 w-full px-10 py-10 bg-gradient-to-b from-transparent ${theme.theme === 'light' ? 'to-gray-400' : ' to-shark-950'}`}>
        <div className="relative mx-auto w-[45vw]">
          <section className="z-10 ">
            <div>
              <div
                className="relative w-full flex-col bottom-[1rem] flex items-center "
                onClick={handleSubmit(onSubmit)}
              >
                {conversations.length > 0 ? (
                  <Button
                    className={`mx-auto px-2 pr-5 content-center rounded-full text-3xl shadow-xl ring-1 ring-gray-500/50 ${theme.theme === 'light' ? 'bg-[#BDBDBD] text-black' : 'bg-shark-800 text-white'} hover:bg-hsi-yellow`}
                  >
                    <FiRefreshCcw className={`fill-white-500 ${theme.theme === 'light' ? 'bg-[#424242] text-white' : 'bg-shark-950 text-white'}  py-3 px-3 rounded-full ml-0 mr-6 text-3xl h-auto w-auto`} />
                    Regenerate
                  </Button>
                ) : null}
              </div>
              <div>
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className={`flex items-center flex-1 p-4 rounded-lg shadow-lg w-full ${theme.theme === 'light' ? 'bg-[#EBEDEF8C]' : 'bg-tuna-900'}  ring-1 overflow-y-auto  max-h-[500px]`}
                >
                  <textarea
                    id="newPrompt"
                    value={prompt}
                    onInput={handlePromptInput}
                    onKeyDown={handleKeyDown}
                    type="text"
                    className={`${theme.theme === 'light' ? 'text-lightText' : 'text-shark-50'} placeholder:text-left text-xl resize-none flex-1 bg-transparent  focus:outline-none font-[400] pl-4  pr-14 py-3`}
                    {...register("newPrompt")}
                    placeholder="Type a message or select a template from the right side bar."
                    required
                  ></textarea>

                  <button
                    type="submit"
                    className={`${theme.theme === 'light' ? 'text-black bg-[#C6C6C6]' : 'text-white bg-shark-700'} h-14 w-14 absolute right-5 bottom-4  rounded-full shadow-sm shadow-black  hover:bg-[#eab646]`}
                  >
                    <VscSend className="h-8 w-8 mx-auto" />
                  </button>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Terminal;
