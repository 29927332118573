import React, { lazy, Suspense } from "react";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";

const UserRoutes = lazy(() => import("../../routes/UserRoutes"));
const AdminRoutes = lazy(() => import("../../routes/AdminRoutes"));

export const HomePage = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  return (
    <section className="bg-white h-screen">
      <Suspense fallback={<Loading />}>
        {user?.role === "member" || user?.role === "user" || user?.role === "none" ? (
          <UserRoutes />
        ) : (
          user?.role === "superadmin" && <AdminRoutes />
        )}
      </Suspense>
    </section>
  );
};

export default HomePage;
