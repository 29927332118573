import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
// import "./ellipsis-modal.css";
import { IoSettingsOutline } from "react-icons/io5";
import MoreSettings from "./more-settings.component";
import ProfileModal from './Modal/Profile/ProfileModal';
import AccountSetting from './Profile/AccountSetting/AccountSetting';
import Header from './Modal/Header/Header';
import { useUser, useUserUpdate } from '../../../context/UserContext';
// import EditProfile from './EditProfile';
import EditProfile from './Profile/EditProfile/EditProfile'
import ThemeModeContext from '../../../context/ThemeContext';
import { updateUser } from '../../../API/User/user/displayUsers';

function EllipsisModal({ closeSettings }) {

  const user = useUser();

  const navigate = useNavigate();
  const [openMoreSettings, setOpenMoreSettings] = useState(false);
  const [openEditProfile, setOpenEditProfile] = useState(false)
  const { theme, toggleTheme } = useContext(ThemeModeContext);

  const handleMoreSettings = () => {
    navigate("/setting", { replace: true })
    setOpenMoreSettings(!openMoreSettings);
  }

  const handleLogout = () => {
    localStorage.clear();
    localStorage.setItem("staySignedIn", false);
    setTimeout(() => {
      return navigate("/auth/login", { replace: true });
    }, 2000);
  };


  const handleOpenProfile = () => {
    navigate("/profile", { replace: true })
    setOpenEditProfile(!openEditProfile)
  }

  return (
    <>
      <div className="relative bottom-0 overflow-hidden z-10 flex flex-row justify-center items-end h-auto w-[100%]">
        <div className={`${theme === 'light' ? 'bg-[#C8C8C8] text-lightText' : 'bg-[#303136] text-white'} fixed bottom-[105px] w-[13vw] rounded-[12px]`}>
          <div className='row text-center pt-2'>
            <button className=' w-[100%] p-3 hover:text-hsi-yellow/100 duration-300' onClick={handleMoreSettings}>
              <i className="fa-solid fa-gear"></i> <span>{'\u00A0 Settings'}</span>
            </button>
          </div>
          <div className='row text-center mt-3'>
            <button className={`${theme === 'light' ? 'bg-[#E0E0E0] text-lightText' : 'bg-gray-500/50'}  hover:text-red-500/70 duration-300 w-[85%] rounded-bl-[12px] rounded-br-[12px] p-5`}
              onClick={handleLogout}> Logout </button>
          </div>
        </div>
        {/* {openMoreSettings && <MoreSettings closeMoreSettings={setOpenMoreSettings} />} */}

        {openMoreSettings && (
          <ProfileModal>
            <Header close={closeSettings} user={user} navigate={navigate} />

            {!openEditProfile
              ? (<AccountSetting handleOpenProfile={handleOpenProfile} navigate={navigate} theme={theme} toggleTheme={toggleTheme} />)
              : (<EditProfile user={user} />)
            }

          </ProfileModal>
        )}

      </div>
    </>


  );
}

export default EllipsisModal;