import { z } from "zod";

export const UpdateProfileSchema = z.object({
  first_name: z
    .string()
    .max(50, { message: "First name should not exceed to 50 character long" })
    .optional(),
  middle_name: z
    .string()
    .max(50, { message: "Middle name should not exceed to 50 character long" })
    .optional(),
  last_name: z
    .string()
    .max(50, { message: "Last name should not exceed to 50 character long" })
    .optional(),
  username: z
    .string()
    .trim()
    .refine(value => value === "" || value.trim() !== "", { message: "Username is required" })
    .refine(value => value === "" || (value.length >= 6 && value.length <= 15), { message: "The username must be between 6 to 15 characters long" })
    .refine(value => value === "" || /\d/.test(value), { message: "Username should contain at least one digit" })
    .refine(value => value === "" || !/\s/.test(value), { message: "Username should not contain spaces" }),
  email: z
    .string()
    .toLowerCase()
    .trim()
    .refine(value => value === "" || value.trim() !== "", { message: "Email is required" })
    .refine(value => value === "" || /^[A-Za-z0-9.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]{2,})?$/.test(value), { message: "Invalid email address" }),
  password: z
    .string()
    .refine((value) => value === "" || value.trim() !== "", { message: "Password is required" })
    .refine(value => value === "" || value.length >= 6, { message: "Password should be at least 6 characters long" }),
  password_confirmation: z
    .string()
    .refine((value) => value === "" || value.length !== 0, { message: "Confirm Password is required" }),
})
  .refine(
    (data) => {
      return data.password === data.password_confirmation;
    },
    {
      message: "Passwords do not match",
      path: ["password_confirmation"],
    },
  );