import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const DoughnutGraph = ({ totalCurrentTokens, maxLimit }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    const options = {
      responsive: true,
      maintainAspectRatio: true,
    };

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const myChartRef = chartRef.current.getContext("2d");

    chartInstance.current = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        labels: [],
        datasets: [
          {
            data: [totalCurrentTokens, maxLimit > 0 ? maxLimit : 100000],
            backgroundColor: ["#e3b51a", "#d9d9d9"],
          },
        ],
      },
      options: options,
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [totalCurrentTokens, maxLimit]);

  return (
    <div className="w-[180px] ">
      <canvas ref={chartRef} width={200} height={30} />
    </div>
  );
};

export default DoughnutGraph;
