import { IoMdCloseCircleOutline } from "react-icons/io";
import { useConversationsUpdate } from "../../../../../context/ConversationsContext";
import ThemeModeContext from "../../../../../context/ThemeContext";
import { useContext } from "react";

const Header = ({ close, user, navigate }) => {
  const updateConversations = useConversationsUpdate()
  const theme = useContext(ThemeModeContext)

  const handleCloseModal = async () => {
    updateConversations([])
    close(false)
    navigate("/home", {replace:true})
  }
  return (
    <>
      <section
        className={`${theme.theme === 'light' ? 'bg-[#C8C8C8]' : 'bg-[#202123]'} h-32  w-full flex items-center justify-between px-8`}
        style={{ borderRadius: '20px 0 0 0' }}
      >
        <div className="flex items-center justify-center gap-5">
          <div className="bg-sky-700 h-10 w-10 rounded-full ">

          </div>
          <p className={`${theme.theme === 'light' ? 'text-lightText' : 'text-shark-50'} mt-4 text-xl font-[600]`}>{user?.username} </p>
        </div>
        <button
          className="text-shark-400 hover:text-yellow-500"
          onClick={handleCloseModal}
        >
          <IoMdCloseCircleOutline
            className='text-4xl' />
        </button>
      </section>
      <section className="h-2 bg-yellow-500"></section>
      <section className="h-3 bg-transparent"></section>
    </>
  );
}

export default Header;