import axios from "axios";

const baseUrl = process.env.REACT_APP_NODE_API_BASE_URL;

export const getListOrg = async token => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/admin/organizations`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
};

export const getListMembers = async token => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/team`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
};

export const getDetails = async (token) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/admin/users/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
};
