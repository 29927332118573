import axios from "axios";

const baseUrl = process.env.REACT_APP_NODE_API_BASE_URL;

export const generateApi = async token => {
  try {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/v1/admin/api-keys`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getApiKey = async token => {
  try {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/v1/admin/api-keys`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
