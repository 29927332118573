import { createContext, useContext, useState } from "react";

const PromptTemplateContext = createContext();
const PromptTemplateUpdateContext = createContext();

export const usePromptTemplates = () => {
    return useContext(PromptTemplateContext);
}

export const usePromptTemplatesUpdate = () => {
    return useContext(PromptTemplateUpdateContext);
}

export const PromptTemplatesProvider = ({ children }) => {
    const [promptTemplates, setPromptTemplates] = useState([])

    function updatePromptTemplates(newConversations){
        setPromptTemplates(newConversations);
    }

    return (
        <PromptTemplateContext.Provider value={promptTemplates}>
            <PromptTemplateUpdateContext.Provider value={updatePromptTemplates}>
                {children}
            </PromptTemplateUpdateContext.Provider>
        </PromptTemplateContext.Provider>
    )
}


