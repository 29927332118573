import React, { useEffect, useState } from "react";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { getPromptTemplates } from "../../../../API/Prompts";
import { useUser } from "../../../../context/UserContext";

const Dropdown = ({ onChange, defaultValue }) => {
  const user = useUser();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [categories, setCategories] = useState([]);

  const handleCategoryChange = category => {
    setSelectedCategory(category?.name);
    onChange(category?.name);
    closeDropdown();
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedCategory(defaultValue);
    }
  }, [defaultValue]);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const token = user?.token;
      const response = await getPromptTemplates(token);
      const uniqueCategories = response?.data?.reduce((acc, item) => {
        if (!acc.some(category => category?.id === item?.category_id)) {
          acc.push({
            id: item?.category_id,
            name: item?.category_name,
          });
        }
        return acc;
      }, []);
      setCategories(uniqueCategories);
    };

    fetchCategories();
  }, []);

  return (
    <>
      <span className="pl-3 text-xl font-bold">Category</span>
      <div className="h-4 bg-[#e3b51a] border-[1px] border-[#202020] rounded-t-2xl mt-1"></div>
      <div className="relative">
        <div
          className={`border-x-[1px] border-b-[1px] pt-3 w-full h-14 text-xl outline-none pl-5 bg-[#dedede] border-[#202020] ${
            isDropdownOpen ? "" : "rounded-b-2xl"
          } mb-3 relative cursor-pointer`}
          onClick={toggleDropdown}
        >
          {selectedCategory || "Select Category"}
          {isDropdownOpen ? (
            <IoMdArrowDropup className="absolute text-3xl top-1/2 right-4 transform -translate-y-1/2" />
          ) : (
            <IoMdArrowDropdown className="absolute  text-3xl top-1/2 right-4 transform -translate-y-1/2" />
          )}
        </div>
        {isDropdownOpen && (
          <div className="absolute top-[90%] left-0 w-full border-[1px] border-t-[1px] bg-[#dedede] border-[#202020] rounded-b-2xl">
            {categories?.length > 0 ? (
              categories?.map(item => (
                <div
                  key={item?.id}
                  className="hover:bg-[#e3b51a] text-2xl font-bold hover:text-white p-2 cursor-pointer"
                  onClick={() => handleCategoryChange(item)}
                >
                  {item?.name}
                </div>
              ))
            ) : (
              <div className="hover:bg-[#e3b51a] text-2xl  flex items-center justify-center text-center font-bold hover:text-white p-2 cursor-pointer">
                <p className="loader"></p>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Dropdown;
