import { IoMdCloseCircleOutline } from "react-icons/io";
import React, { useEffect, useState } from "react-hook-form";
import { useForm } from "react-hook-form";

import { postAddTeam, getListTeam } from "../../../../../API/Team";
import toast, { Toaster } from "react-hot-toast";

import { useUser } from "../../../../../context/UserContext";


const AddMember = ({ handleReturn, setListMember }) => {
  const user = useUser();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async data => {
    try {
      const token = user?.token;
      await postAddTeam(data, token);
      const Response = await getListTeam(token);
      setListMember(Response.data);
      toast.success("You have successfully added a member.");
      handleReturn(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div className="relative text-white">
        <div className="fixed z-20 flex flex-row justify-center items-center top-0 left-0 h-[100%] w-[100%] bg-[#000000ad]">
          <div className="flex flex-col items-center">
            <div className="bg-[#2f3031] w-[30vw] p-12 grid grid-cols-2 border-b-[5px] rounded-t-[12px] border-[#efc62f] ">
              <div className="flex justify-start text-4xl font-bold">
                {" "}
                Add Member{" "}
              </div>
              <button
                className="flex justify-end items-center hover:text-[#EFC62F] duration-300 "
                onClick={() => handleReturn(false)}
              >
                <IoMdCloseCircleOutline className="text-3xl bg-tuna-800 rounded-full m-1 w-auto h-auto p-1" />
              </button>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col p-12 gap-[10px] w-[30vw] bg-[#2f3031] "
            >
              <div className="text-center italic text-[#5a5a5a] text-xl">
                This feature adds a new member to the Team. Please make sure
                that you got the correct username.
              </div>
              <div className="grid ">
                <div className="relative flex flex-col gap-[5px] justify-center items-center p-5">
                  <label className="absolute top-1 left-[55px] text-2xl bg-white text-black px-3 text-left">
                    Username
                  </label>
                  <input
                    type="text"
                    className="rounded-[12px] w-[85%] bg-[#3c3d40] border border-[#fefefe] py-6 px-8"
                    placeholder="johnd03"
                    {...register("username")}
                  />
                </div>
              </div>

              <div className="flex justify-center items-center">
                <button className="border border-[#fefefe] rounded-[12px] py-5 px-8 bg-[#efc62f] text-2xl text-black font-bold hover:bg-[#c4ab52] duration-300">
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default AddMember;
