import api from "../apiInstance";

export const getUserProfile = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("user"))

    const response = await api.get('/api/v1/profile', {

      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user?.token}`
      }
    })
    return response?.data?.data
  } catch (error) {
    console.log(error)
  }
}