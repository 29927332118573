import React from "react";
import NavBar from "../Components/NavBar/NavBar";

const LandingPage = () => {
    return (
        <div className="min-h-screen flex flex-col text-shark-950 bg-shark-50 sm:p-4 md:p-8 lg:p-12">
            <NavBar/>

            <div className="flex flex-col flex-1 items-center justify-center p-4 md:p-8">
                <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-6x1">Welcome to Highly GPT</h1>
                <p className="m-0 text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-2xl">The Essential AI assistant for HSI Employees</p>
                

            </div>
        </div>
    )
};
export default LandingPage;