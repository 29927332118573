import axios from "axios";

const baseUrl = process.env.REACT_APP_NODE_API_BASE_URL;

// get api key for open ai
export const getApiKey = async () => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/admin/api-keys`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
};

// get conversation
export const getConversations = async conversationId => {
  if (!conversationId) return null;

  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/session-history/${conversationId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  };

  const response = await axios.request(config);
  return response.data.data[0].chats;
};

//new prompt for existing chat
export const newPrompt = async (
  message,
  conversationId,
  conversationName = "",
  promptId = "",
  responseFormat = ""
) => {
  let sessionId;

  if (isNaN(conversationId)) {
    let sessionConfig = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/v1/chat-session/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const sessionResponse = await axios.request(sessionConfig);
    sessionId = sessionResponse.data.data;
  } else {
    sessionId = conversationId;
  }

  const data = JSON.stringify({
    promptId: promptId,
    message: message.newPrompt,
    convoName: conversationName,
    responseFormat: responseFormat,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/chat-session/${sessionId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
    data: data,
  };

  const response = await axios.request(config);
  return {
    userinput: response.data.data.user_message,
    conversationId: response.data.data.id,
    response: response.data.data.response,
    sessionId: response.data.data.chat_session_id,
  };
};

//new prompt
export const getConversationsList = async message => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/session-history`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
};

//delete
export const deleteConversation = async conversationId => {
  let config = {
    method: "delete",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/session-history/${conversationId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
};

export const CreateUserFeedback = async (feedback, sessionId, chatId) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/feedback/${sessionId}/${chatId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
    data: feedback,
  };

  const response = await axios.request(config);
  return response.data;
};
