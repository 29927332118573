import { Routes, Route, useLocation } from "react-router-dom";
import { ListUser } from "../Pages/Admin/ListUser/ListUser";
import Home from "../Pages/Admin/Dashboard/Dashboard";
import ApiKey from "../Pages/Admin/ApiKey/ApiKey";
import PromptTemplate from "../Pages/Admin/PromptTemplate/PromptTemplate";
import Team from "../Pages/Admin/Team/Team";
import ListUserFeedback from "../Pages/Admin/Modal/Feedback/ListUserFeedback";
import ProfileModal from "../Pages/Admin/Modal/ProfileModal/ProfileModal";
import UserUsage from "../Pages/Admin/Table/UserUsage";
import AdminPage from "../Pages/Admin/AdminPage";
import ListUserTable from "../Pages/Admin/Table/ListUserTable/ListUserTable";

const AdminRoutes = () => {
  return (
    <>
      <AdminPage />
      <Routes>
        <Route path="user" element={<ListUser />}>
          <Route path="action/:id" element={<></>} />
          <Route path="usage/:id" element={<UserUsage />} />
        </Route>
        <Route path="dashboard" element={<Home />} />
        <Route path="api-keys" element={<ApiKey />} />
        <Route path="prompt-template" element={<PromptTemplate />} />
        <Route path="/team" element={<Team />}></Route>
        <Route path="user-feedback" element={<ListUserFeedback />} />
      </Routes>
    </>
  );
};

export default AdminRoutes;
