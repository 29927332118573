
import React from "react";
import { Outlet } from "react-router-dom";
import AutoSignIn from "../../routes/AutoSignIn.jsx";


export default function AuthIndex() {

  return (
    <div className="min-h-screen">
      <Outlet />
    </div>
    
  );
}