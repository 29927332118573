import React from 'react';
import { Outlet } from 'react-router-dom';
import AutoSignIn from './AutoSignIn';

export default function InitalRoute () {
    const user = JSON.parse(localStorage.getItem("user") || null);
    const staySignedIn = JSON.parse(localStorage.getItem("staySignedIn") || false);

    if ((user && !staySignedIn) || (user && staySignedIn)) {
        return <AutoSignIn/>;
    } else {
        return <Outlet />;
    }
};