import React, { useContext, useState } from "react";
import { deleteConversation } from "../../../API/Conversations";
import { useNavigate, useParams } from "react-router-dom";
import {
  useConversationList,
  useConversationListUpdate,
} from "../../../context/ConversationListContext";
import { useConversationsUpdate } from "../../../context/ConversationsContext";
import { getConversations } from "../../../API/Conversations";
import { BiMessageDetail } from "react-icons/bi";
import { FaRegTrashAlt } from "react-icons/fa";
import DeleteModal from "./DeleteModal";
import ThemeModeContext from "../../../context/ThemeContext";

const History = ({ index, conversation }) => {
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const conversationsList = useConversationList();
  const updateConversationList = useConversationListUpdate();
  const updateConversations = useConversationsUpdate();
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { conversation_id } = useParams();

  const handleDelete = async () => {
    setDeleteModalVisible(true);
  };


  const handleHover = hovered => {
    setIsHovered(hovered);
  };

  const handleDeleteConfirmed = async () => {
    updateConversationList(
      conversationsList.filter(cv => cv.id !== conversation.id)
    );
    deleteConversation(conversation.id);
    updateConversations([]);
    navigate(`/home`);

    // if (conversationsList[index + 1] ?? conversationsList[index - 1]) {
    //   handleHistorySelect(
    //     conversationsList[index + 1] ?? conversationsList[index - 1]
    //   );
    // } else {
    //   updateConversations([]);
    //   navigate(`/home`);
    // }
    setDeleteModalVisible(false);
  };

  // Function to handle the selection of a conversation from the history
  const handleHistorySelect = async conversation => {

    navigate(`/chat/${conversation.id}`);
    try {
      const response = await getConversations(conversation.id);
      updateConversations(response);
    } catch (err) {
      console.error(err);
    }
  };

  const theme = useContext(ThemeModeContext);

  return (
    <>
      {/* Change the parsed if throws an error */}
      <li
        className={`${parseInt(conversation_id) === conversation.id
          ? "bg-gray-500/50"
          : "hover:bg-gray-500/50"
          } ${theme.theme === 'light' ? "text-lightText" : "text-white"} font-[500] rounded-full flex justify-center h-14 relative overflow-hidden`}
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      >
        <div
          className={`w-8 ${parseInt(conversation_id) === conversation.id && "bg-[#9c824b] brightness-20 "
            }`}
        ></div>

        <div className=" flex-1 flex items-center justify-center  pl-4">
          <button
            className="w-full flex items-center h-full text-clip"
            onClick={() => {
              handleHistorySelect(conversation);
              navigate(`/chat/${conversation.id}`)
            }}
          >
            <BiMessageDetail className="w-6 h-6 mr-10 " />
            <span className=" w-full text-left line-clamp-1">
              {conversation.title}
            </span>
          </button>
        </div>

        <div
          className=" w-16 flex items-center justify-center"
        >
          {(parseInt(conversation_id) === conversation.id || isHovered) && (
            <button onClick={handleDelete}>
              <FaRegTrashAlt className={`${theme.theme === 'light' ? "text-shark-950" : "text-white"} hover:text-[#F7B442] w-5 h-5 duration-300`} />
            </button>
          )}
        </div>
      </li>
      {isDeleteModalVisible && (
        <DeleteModal
          onDeleteConfirmed={handleDeleteConfirmed}
          onCancel={() => setDeleteModalVisible(false)}
        />
      )}
    </>
  );
}


export default History;
