const ProfileModal = ({ children }) => {
  return (
    <>
      <section
        className={`fixed top-0 left-0 w-full z-50 bg-[#202123]/40 h-full flex justify-center items-center overflow-hidden`}
      >
        <section className="w-[45rem]" >
          {children}
        </section>
      </section>
    </>
  );
}

export default ProfileModal;