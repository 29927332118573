import { useNavigate } from "react-router-dom";

const ProfileMenu = ({ handleTriggerModal, userProfile }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    localStorage.setItem("staySignedIn", false);
    setTimeout(() => {
      return navigate("/auth/login", { replace: true });
    }, 2000);
  };
  return (
    <section className="absolute left-20 top-12 z-40 w-[17rem] bg-yellow-500 rounded-2xl">
      <ul className="flex flex-col mt-2 font-medium gap-1">
        <li className="hover:bg-[#f2c03a]/80 pl-8  hover:cursor-pointer">
          Signed in as <br />
          <span className="text-base">{userProfile?.email}</span>
        </li>
        <hr className=" m-0 p-0" />
        <li
          className="hover:bg-[#f2c03a]/80 pl-8 py-3 hover:cursor-pointer"
          onClick={() => handleTriggerModal(true)}
        >
          Profile
        </li>
        <li className="hover:bg-[#f2c03a]/80 pl-8 py-3 hover:cursor-pointer">Setting</li>
        <hr className=" m-0 p-0" />
        <li className="hover:bg-[#f2c03a]/80 pl-8 py-3 hover:cursor-pointer" onClick={handleLogout}>Sign Out</li>
      </ul>
    </section>
  );
}

export default ProfileMenu;