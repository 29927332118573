export const memberData = [
    {
        name: "HSI Pro Employee",
        role: "Team Leader",
    },
    {
        name: "HSI Pro Employee (Quality Assurance)",
        role: "Member",
    },
    {
        name: "HSI Pro Employee (Documentation and Audit)",
        role: "Member",
    },
    {
        name: "HSI Pro Employee (UI/UX Designer)",
        role: "Member",
    },
    {
        name: "HSI Pro Employee (Database Manager)",
        role: "Member",
    },
    {
        name: "HSI Pro Employee (Pancit Canton Cooker)",
        role: "Member",
    },
    {
        name: "HSI Pro Employee (Front-end Web Designer)",
        role: "Member",
    },
    {
        name: "HSI Pro Employee (Back-end Web Desginer)",
        role: "Member",
    },

]