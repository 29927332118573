const ProfileForm = ({ userProfile }) => {
  return (
    <form className="w-[65%] mb-16">
      <section className=" relative w-full my-3 flex flex-col items-center justify-center">
        <label className="absolute left-14 top-1 focus:bg-[#202123] bg-white font-normal text-black h-10 w-auto  px-3 rounded-full">
          Username
        </label>
        <input
          type="text"
          placeholder="Enter your username"
          className="w-full h-20 p-2 mt-5 pl-20 rounded-full bg-transparent text-[#585858] text-xl border border-[#585858] outline-0"
          value={userProfile?.username}
        />
      </section>

      <section className=" relative w-full my-3 flex flex-col items-center justify-center">
        <label className="absolute left-14 top-1 max-sm:left-20 focus:bg-[#202123] bg-white font-normal text-black h-10 w-auto px-3 rounded-full">
          Email
        </label>
        <input
          type="text"
          placeholder="Enter your email"
          className="w-full h-20 p-2 mt-5 pl-20 rounded-full bg-transparent text-[#585858] text-xl border border-[#585858] outline-0"
          value={userProfile?.email}
        />
      </section>

      <section className=" relative w-full my-3 flex flex-col items-center justify-center">
        <label className="absolute left-14 top-1 max-sm:left-20 focus:bg-[#202123] bg-white font-normal text-black h-10 w-auto  px-3 rounded-full">
          Password
        </label>
        <input
          type="password"
          placeholder="Enter your password"
          className="w-full h-20 p-2 mt-5 pl-20 rounded-full bg-transparent text-[#585858] text-xl border border-[#585858] outline-0"
          value={userProfile?.password}
        />
      </section>
    </form>
  );
}

export default ProfileForm;