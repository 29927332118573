import React, { useContext, useEffect, useState } from "react";
import { Label } from "../../Pages/Authentication/Login/ui/label";
import { Input } from "../../Pages/Authentication/Login/ui/input";
import { Button } from "../../Pages/Authentication/Login/ui/button";
import { newPrompt } from "../../API/Conversations";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import {
  useConversations,
  useConversationsUpdate,
} from "../../context/ConversationsContext";
import {
  useConversationList,
  useConversationListUpdate,
} from "../../context/ConversationListContext";
import { useForm } from "react-hook-form";
import { data } from "autoprefixer";
import ThemeModeContext from "../../context/ThemeContext";
// import {promptChat} from "../../API/Prompts"

const Modal = ({ modalOpen, setModalOpen, modalContent, id }) => {
  const conversations = useConversations();
  const updateConversations = useConversationsUpdate();
  const conversationsList = useConversationList();
  const updateConversationList = useConversationListUpdate();

  const [promptDetails, setPromptDetails] = useState({});

  const [promptId, setPromptId] = useState(modalContent?.prompt_id ?? "");
  const [promptName, setPromptName] = useState(modalContent?.prompt_name ?? "");
  const [promptDescription, setPromptDescription] = useState(
    modalContent?.prompt_description ?? ""
  );
  const [promptFeature, setPromptFeature] = useState(
    modalContent?.prompt_feature ?? ""
  );
  const [responseFormat, setResponseFormat] = useState(
    modalContent?.response_format ?? ""
  );
  const [responseUserInput, setResponseUserInput] = useState(
    modalContent?.user_input ?? ""
  );
  const [responseMessage, setResponseMessage] = useState("");
  const [responseType, setResponseType] = useState("");
  const [userInput, setUserInput] = useState("");
  const { conversation_id } = useParams();

  const navigate = useNavigate();

  const { register, reset, setValue, handleSubmit, formState: { errors } } = useForm()

  const onSubmit = async data => {
    try {
      // await promptChat(data, id)
      console.log(data)
    } catch (error) {
      console.log(error)

    }
  }


  useEffect(() => {
    setValue("responseFormat", responseFormat)
    setValue("user_message", userInput)
  }, [])






  useEffect(() => {
    setPromptId(modalContent?.prompt_id ?? "");
    setPromptName(modalContent?.title ?? "");
    setPromptDescription(modalContent?.description ?? "");
    setPromptFeature(modalContent?.prompt_feature ?? "");
    setResponseFormat(modalContent?.response_format ?? "");
    setResponseUserInput(modalContent?.user_input ?? "");
    setResponseType("");
    setResponseMessage("");
  }, [modalContent]);

  const handleBackgroundClick = e => {
    if (e.target !== e.currentTarget) return;
    setResponseType("");
    setResponseMessage("");
    setModalOpen(false);
  };

  const handleEnterPrompt = async e => {
    setPromptId("");
    setPromptFeature("");
    setResponseFormat("");
    setUserInput("");

    e.preventDefault();
    const generatedMessage = `${promptId} is called with user input: ${responseUserInput}`;
    const userChat = {
      role: "user",
      content: generatedMessage,
    };

    // Append the user's new chat
    updateConversations([...conversations, userChat]);

    setModalOpen(false);

    console.log(promptId);
    try {
      const res = await newPrompt(
        { newPrompt: generatedMessage },
        conversation_id,
        !conversation_id ? generatedMessage : "",
        promptId,
        responseFormat
      );

      const responseChat = {
        role: "bot",
        content: res.response,
      };

      if (!conversation_id) {
        const newHistory = {
          id: res.sessionId,
          title: res.userinput,
        };

        updateConversationList([...conversationsList, newHistory]);
        navigate(`/chat/${res.sessionId}`);
      }

      updateConversations([...conversations, userChat, responseChat]);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const theme = useContext(ThemeModeContext);

  return (
    <>
      {modalOpen && (
        <div
          onClick={handleBackgroundClick}
          className={` fixed h-screen w-screen z-[50] flex items-center justify-center  py-10`}
        >
          <div className={`${theme.theme === 'light' ? 'bg-[#C8C8C8] ring-gray-500' : 'bg-shark-950 ring-gray-500'} flex flex-col  ring-1  rounded-lg shadow-lg text-white min-w-[calc(50%)] h-[90%] overflow-auto gap-10`}>
            <div className="flex items-center justify-between px-10 pt-10 ">
              <p className={`${theme.theme === 'light' ? 'text-lightText' : 'text-white'} m-0 text-3xl font-bold`}>{promptName}</p>
              <MdOutlineCancel
                className="text-5xl hover:fill-[#eab646] cursor-pointer"
                onClick={handleCloseModal}
              />
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-5 ">
              <div className="w-full h-3 bg-[#eab646] "></div>
              <div className="flex flex-col gap-1 px-10 pt-10">
                <Label className={`${theme.theme === 'light' ? 'text-lightText' : 'text-white'} text-2xl`} htmlFor="description">
                  Prompt Description
                </Label>
                <p
                  className={`p-4 text-xl rounded-lg resize-none ${theme.theme === 'light' ? 'bg-[#EBEDEF40]/50 text-lightText' : 'bg-[#303136] text-white'}`}
                  name="user_input"
                  id="user_input"
                  readOnly
                >
                  <span className=" pl-5"> {promptDescription}</span>
                </p>
              </div>
              <div className="flex flex-col gap-1 px-10">
                <Label className={`${theme.theme === 'light' ? 'text-lightText' : 'text-white'} text-2xl`} htmlFor="user_input">
                  User Input
                </Label>
                <textarea
                  className={`${theme.theme === 'light' ? 'bg-[#EBEDEF40]/50 text-lightText' : 'bg-[#303136]'} p-4 text-[1.375rem] rounded-lg resize-none `}
                  name="user_input"
                  id="user_input"
                  cols="30"
                  rows="10"

                >{responseUserInput}</textarea>
              </div>

              <div className="flex flex-col gap-1 px-10 ">
                <Label className={`${theme.theme === 'light' ? 'text-lightText' : 'text-white'} text-2xl`} htmlFor="response_format">
                  Response Format
                </Label>
                <div className={`${theme.theme === 'light' ? 'bg-[#EBEDEF40]/50' : 'bg-[#2b2d31]'} w-full h-16    rounded-t-lg`}  ></div>
                <textarea
                  className={`${theme.theme === 'light' ? 'bg-[#EBEDEF40]/50 text-lightText' : 'bg-[#303136]'} p-5 text-[1.375rem] rounded-b-lg resize-none  `}
                  name="response_format"
                  id="response_format"
                  cols="30"
                  rows="10"
                  {...register("responseFormat", { required: true })}
                  onInput={e => {
                    setResponseFormat(e.target.value);
                  }}
                  value={responseFormat}
                >
                  {" "}
                </textarea>
              </div>

              <Button
                onClick={handleEnterPrompt}
                type="submit"
                className="py-5 ml-auto  mr-10 mb-5 text-[1.375rem] w-44 transition-all bg-[#585858] hover:bg-[#eab646]"
              >
                Enter
              </Button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
