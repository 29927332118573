import { IoMdCloseCircleOutline } from "react-icons/io";

const ViewFeedback = ({ openViewModal, setOpenViewFeedback, userDetails, selectedFeedback, date }) => {
    const filterData = userDetails.find(list => list?.id === selectedFeedback)
    const formattedDate = (created) => {
        return new Date(created).toISOString().split('T')[0]
    }

    return (
        <>
            {" "}
            {openViewModal && filterData && (
                <div className='relative'>
                    <div className='fixed z-20 top-0 left-0 w-[100%] h-[100%] bg-[rgba(0,0,0,0.5)]'>
                        <div className='fixed z-50 rounded-[12px] overflow-auto w-[33%] scroll-smooth bg-[#fefefe] shadow-lg shadow-[rgba(0,0,100,0.35)]/900 top-[5%] left-[55%] translate-x-[-50%]'>

                            <div className=''>
                                <div className='flex flex-row w-full px-10 justify-between bg-hsi-yellow grid-rows-2'>
                                    <div className='py-7 font-black'>
                                        <div className='text-2xl '>User Feedback </div>
                                    </div>
                                    {/* <div className='bg-hsi-yellow py-7 font-black'></div> */}
                                    {/* <div className='text-right px-[20px]'>  */}
                                    <button
                                        className='text-[#0e0e0e] hover:text-[#fefefe] duration-300'
                                        onClick={() => setOpenViewFeedback(false)}

                                    >

                                        <IoMdCloseCircleOutline className='text-4xl' />
                                    </button>

                                    {/* </div> */}
                                </div>
                                <div className='grid-cols-2 flex flex-row'>
                                    <div className='flex flex-col grid-rows-2'>
                                        <div className='flex flex-col px-10 py-3 mt-3'>
                                            <span className='text-2xl'>Name</span>
                                            <input type='text' className='border-[1px] text-left border-[#0e0e2e] my-2 p-3 pl-4 w-[25rem] rounded-md'
                                                value={`${filterData.first_name} ${filterData.middle_name} ${filterData.last_name}`} readOnly />
                                        </div>
                                        <div className='flex flex-col px-10'>
                                            <span className='text-2xl'>Email</span>
                                            <input type='email' className='border-[1px] border-[#0e0e2e] text-md my-2 p-3 pl-4 w-[25rem]  rounded-md' value={filterData.email} readOnly />
                                        </div>
                                    </div>
                                    <div className='flex flex-col pr-10 py-3 mt-3'>
                                        <span className='text-2xl'>Date</span>
                                        <input type='text' className='border-[1px] border-[#0e0e2e] text-md my-2 p-3 pl-4 w-[25rem] text-center rounded-md' value={formattedDate(date.created_at)} readOnly />
                                    </div>
                                </div>
                                <div className='flex flex-col grid-rows-2 w-auto overflow-auto'>
                                    <div className='flex flex-col px-10 py-3 mt-3'>
                                        <span className='text-2xl'>Generated Text</span>
                                        <div className='border-[1px] w-full min-h-[100px] max-h-[100px] border-[#0e0e2e] my-2 py-5 px-7 rounded-md overflow-auto' readOnly> {date.ai_content} </div>
                                    </div>
                                    <div className='flex flex-col px-10 py-3 mt-3 pb-10'>
                                        <span className='text-2xl'>Feedback</span>
                                        <div className='border-[1px] w-full min-h-[250px] max-h-[100px] border-[#0e0e2e] my-2 py-5 px-7 rounded-md overflow-auto' readOnly> {date.feedback} </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </>
    );
}

export default ViewFeedback;