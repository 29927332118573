import React, { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import { IoIosSearch } from "react-icons/io";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiOutlineLike } from "react-icons/ai";
import { BiDislike } from "react-icons/bi";
import NewTemplateModal from "../Modal/PromptTemplateModal/NewTemplateModal";
import DeleteTemplateModal from "../Modal/PromptTemplateModal/DeleteTemplateModal";
import PromptTemplateModal from "../Modal/PromptTemplateModal/PromptTemplateModal";
import toast, { Toaster } from "react-hot-toast";
import editLogo from "../../../Images/image 14.png";
import { HiOutlineTrash } from "react-icons/hi";
import { useUser } from "../../../context/UserContext";
import {
  getPromptTemplates,
  getPromptCategories,
  deletePromptTemplates,
} from "../../../API/Prompts";
import axios from "axios";
import Sidebar from "../Sidebar/Sidebar";
const PromptTemplate = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [data, setData] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [filteredCateg, setFilteredCateg] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleOpenModal = list => {
    setIsOpen(true);
    setSelectedTemplate(list);
  };
  const user = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    const fetchdata = async () => {
      try {
        const token = user?.token;
        setLoading(true);
        const response = await getPromptTemplates(token);
        setData(response?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchdata();
  }, []);

  const handleOpenNewTemplateModal = () => {
    setOpenModal(!openModal);
  };

  const handleInputSubmit = newTemplateData => {
    setData(prevData => [...prevData, newTemplateData]);
  };

  const handleDeleteIconClick = async (e, title) => {
    setSelectedTemplate(title?.id);
    e.stopPropagation();
    setOpenDeleteModal(true);
  };

  const handleDeleteTemplate = async () => {
    try {
      const token = user?.token;
      await deletePromptTemplates(selectedTemplate, token);
      setData(prevData =>
        prevData.filter(list => list?.id !== selectedTemplate)
      );
      setOpenDeleteModal(false);
      toast.success("Template deleted successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error deleting template");
    }
  };
  const formatTitle = title => {
    const titleWithoutGet = title?.replace(/^get/, "");
    return titleWithoutGet.replace(/([A-Z])/g, " $1").trim();
  };

  const filteredData = data.filter(list =>
    formatTitle(list?.title)
      ?.toLowerCase()
      .trim()
      .includes(searchInput.toLowerCase().trim())
  );

  return (
    <div>
      {/* <Sidebar /> */}
      <section className="ml-[23rem]">
        <header className="border  relative  w-full flex items-center justify-between px-44 gap-10 py-4  max-sm:px-10 bg-[#eac446]">
          <div className="relative">
            <input
              onInput={e => setSearchInput(e.target.value)}
              type="search"
              placeholder="Search Templates.."
              className="bg-[#e3b51a] pl-14 placeholder:text-[#ffd856] py-3 w-[230px] font-bold rounded-2xl  border-2 border-[#a17d00] outline-none"
            />
            <section className=" absolute left-3 top-[1rem] flex items-center justify-center  w-8 h-8 bg-[#eac446] border-[1px] rounded-full border-black ">
              <IoIosSearch className="text-xl " />
            </section>
          </div>
          <section className="flex     ">
            <button
              onClick={handleOpenNewTemplateModal}
              className="border-2  flex items-center justify-center gap-4 rounded-lg p-3 bg-transparent px-10 text-white font-medium"
            >
              <MdAdd />
              New Template
            </button>
          </section>
        </header>
        <div className="text-center  h-screen bg-white ">
          <h1 className="text-4xl font-bold text-center pt-8">
            Template Library
          </h1>

          <div className="grid place-items-center gap-6 xl:gap-8 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 mt-10 px-6 pb-10">
            {filteredData?.length > 0 ? (
              filteredData?.map(list => (
                <div
                  key={list?.id}
                  className="border custom-shadow h-[180px] w-[34rem] md:w-[30rem] lg:w-[35rem] xl:w-[32rem]  flex flex-col cursor-pointer justify-between bg-[#e3b51a] rounded-2xl text-left border-black px-5 pb-5 font-bold"
                >
                  <div className="w-full">
                    <div className="mt-5 font-bold mb-5 w-full ">
                      <h1 className="text-4xl  text-ellipsis overflow-hidden">
                        {formatTitle(list?.title)}
                      </h1>
                      {list?.category_name}
                    </div>
                    <p className="text-lg font-semibold">{list?.description}</p>
                  </div>

                  <div className="flex items-center ml-auto ">
                    <img
                      src={editLogo}
                      className="h-8 mr-3 cursor-pointer"
                      alt=""
                      onClick={() => {
                        handleOpenModal(list);
                      }}
                    />
                    <HiOutlineTrash
                      className="text-4xl mr-3 text-[#010100] cursor-pointer"
                      onClick={e => handleDeleteIconClick(e, list)}
                    />
                  </div>
                </div>
              ))
            ) : loading ? (
              <section className="fixed top-0 left-0 w-full h-full bg-opacity-50 bg-gray-600 flex justify-center items-center z-50">
                <div className="animate-spin rounded-full h-32 w-32  border-t-8 border-[#eab646] border-solid"></div>
              </section>
            ) : (
              <section className="w-[1000px] 2xl:w-[1400px] h-[450px] flex items-center justify-center text-center">
                <p className="w-[500px] text-2xl bg-yellow-200 text-yellow-800 p-4 rounded-lg shadow-md">
                  Sorry, we couldn't find anything matching your search. Don't
                  worry, try a different search term.
                </p>
              </section>
            )}
          </div>
        </div>
      </section>
      <NewTemplateModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        onInputSubmit={handleInputSubmit}
        setData={setData}
      />
      <DeleteTemplateModal
        openDeleteModal={openDeleteModal}
        handleDeleteTemplate={handleDeleteTemplate}
        setOpenDeleteModal={setOpenDeleteModal}
      />
      <PromptTemplateModal
        isOpen={isOpen}
        selectedTemplate={selectedTemplate}
        setIsOpen={setIsOpen}
        filteredCateg={filteredCateg}
        setData={setData}
      />
      <Toaster position="bottom-right" />
    </div>
  );
};

export default PromptTemplate;
