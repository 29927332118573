import React from "react";
import { GoAlertFill } from "react-icons/go";
import ThemeModeContext from "../../../context/ThemeContext";
import { useContext } from "react";

const DeleteModal = ({ onDeleteConfirmed, onCancel }) => {
  const theme = useContext(ThemeModeContext);
  return (
    <>
      <div className={`${theme.theme === 'light' ? 'text-lightText' : 'text-white'} relative `}>
        <div className="fixed z-10 flex flex-row justify-center items-start top-0 left-0 h-[100%] w-[100%] backdrop-blur-sm">
          <div className={`${theme.theme === 'light' ? 'bg-[#D7D9DC]' : 'bg-[#202123]'} w-[25%] max-w-[600px] rounded-[12px]  shadow-xl shadow-black-900 mt-[10%] mb-[10%] p-[20px]`}>
            <div className="row flex justify-center">
              <GoAlertFill className={`${theme.theme === 'light' ? 'bg-white text-lightText' : 'bg-[#2e3032] text-white'} text-7xl rounded-full  m-1 w-auto h-auto p-5`} />
            </div>
            <div className="row text-center">
              <h2 className={`${theme.theme === 'light' ? 'text-lightText' : 'text-white'} pt-2 pb-2 font-bold tracking-wide`}>Are you sure?</h2>
              <p className={`${theme.theme === 'light' ? 'text-gray-500' : 'text-gray-400'} pt-2 pb-2 `}>
                This action cannot be undone
              </p>
            </div>
            <div className="row pl-5 pr-5 mb-2 text-center">
              <button
                className={`${theme.theme === 'light' ? 'text-lightText hover:bg-red-500/50 duration-300' : 'text-white hover:bg-red-500/40 duration-300'} font-[900] text-[20px] rounded-[12px] w-[100%] p-6 bg-red-500/80 `}
                onClick={onDeleteConfirmed}
              >
                Delete Chat
              </button>
            </div>
            <div className="row pl-5 pr-5 mt-2 text-center">
              <button
                className={`${theme.theme === 'light' ? 'text-lightText hover:bg-gray-500/40 duration-300' : 'text-white hover:bg-gray-500/40 duration-300'} font-[900] text-[20px] bg-gray-500/80 rounded-[12px] w-[100%]   p-6`}
                onClick={onCancel}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
