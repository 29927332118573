import React from "react";

const Loading = () => {
  return (
    <div className="min-h-screen w-full bg-shark-950 flex items-center justify-center text-2xl text-white">
      Loading...
    </div>
  )
};

export default Loading;