import { z } from "zod";

export const AddPromptTemplateSchema = z.object({
  title: z
    .string()
    .trim()
    .refine(value => value.trim() !== "", {
      message: "Prompt title is required",
    }),

  description: z
    .string()
    .trim()
    .refine(value => value.trim() !== "", {
      message: "Description is required",
    })
    .refine(value => value.length <= 255, {
      message: "The description field must not be greater than 255 characters.",
    }),
  user_input: z
    .string()
    .trim()
    .refine(value => value.trim() !== "", {
      message: "User input is required",
    }),
  response_format: z
    .string()
    .trim()
    .refine(value => value.trim() !== "", {
      message: "Response format is required",
    }),
  prompt_feature: z
    .string()
    .trim()
    .refine(value => value.trim() !== "", {
      message: "Prompt feature is required",
    }),
});
