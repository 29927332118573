import React from "react";
import flag from "../../../Admin/ApiKey/assets/image 1.png";
import "./Scrollbar.css";
export const ListOfCountry = () => {
  return (
    <>
      <section className="flex absolute z-10 right-10   top-12 flex-col h-[300px]  overflow-scroll w-[320px] bg-white border">
        <section className="flex h-  w-full gap-4 p-5 hover:bg-[#e8e8e8] font-normal items-center">
          <img src={flag} alt="flag" className=" h-8" />
          <span className="text-2xl"> Philippines</span>
        </section>
        <section className="flex h-20  w-full gap-4 p-5 font-normal items-center hover:bg-[#e8e8e8]">
          <img src={flag} alt="flag" className=" h-8" />
          <span className="text-2xl"> Philippines</span>
        </section>
        <section className="flex h-20  w-full gap-4 p-5 hover:bg-[#e8e8e8] font-normal items-center">
          <img src={flag} alt="flag" className=" h-8" />
          <span className="text-2xl"> Philippines</span>
        </section>
        <section className="flex h-20  w-full gap-4 p-5 hover:bg-[#e8e8e8] font-normal items-center">
          <img src={flag} alt="flag" className=" h-8" />
          <span className="text-2xl"> Philippines</span>
        </section>
        <section className="flex h-20  w-full gap-4 p-5 hover:bg-[#e8e8e8] font-normal items-center">
          <img src={flag} alt="flag" className=" h-8" />
          <span className="text-2xl"> Philippines</span>
        </section>
        <section className="flex h-20  w-full gap-4 p-5 hover:bg-[#e8e8e8] font-normal items-center">
          <img src={flag} alt="flag" className=" h-8" />
          <span className="text-2xl"> Philippines</span>
        </section>
        <section className="flex h-20  w-full gap-4 p-5 hover:bg-[#e8e8e8] font-normal items-center">
          <img src={flag} alt="flag" className=" h-8" />
          <span className="text-2xl"> Philippines</span>
        </section>
        <section className="flex h-20  w-full gap-4 p-5 hover:bg-[#e8e8e8] font-normal items-center">
          <img src={flag} alt="flag" className=" h-8" />
          <span className="text-2xl"> Philippines</span>
        </section>
        <section className="flex h-20  w-full gap-4 p-5 hover:bg-[#e8e8e8] font-normal items-center">
          <img src={flag} alt="flag" className=" h-8" />
          <span className="text-2xl"> Philippines</span>
        </section>
        <section className="flex h-20  w-full gap-4 p-5 hover:bg-[#e8e8e8] font-normal items-center">
          <img src={flag} alt="flag" className=" h-8" />
          <span className="text-2xl"> Philippines</span>
        </section>{" "}
      </section>
    </>
  );
};
