import React, { useState, useEffect } from "react";
import { FaAngleDown } from "react-icons/fa";
import { getListOrg } from "../../../API/TeamAdmin";
import { useUser } from "../../../context/UserContext";

import ViewTeam from "./ViewTeam";

import toast, { Toaster } from "react-hot-toast";

const Team = ({ handleReturn }) => {
  const [storeOrgName, setStoreOrgName] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [viewTeam, setViewTeam] = useState(false);
  const [userDetails, setUserDetails] = useState([]);

  const handleViewTeam = (item) => {
    setStoreOrgName(item.organization_name);
    setOrgId(item.organization_id);
    setViewTeam(!viewTeam);
  };
  const [listOrg, setListOrg] = useState([]);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = user?.token;
        const Response = await getListOrg(token);
        setListOrg(Response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      {!viewTeam && (
        <div className="content h-screen w-screen pl-[23rem] bg-white">
          <div className="text-4xl bg-[#DDBB21] text-black font-bold flex flex-row gap-4 p-10 mb-5">
            Organizations
          </div>
          <div className="grid grid-cols-2 px-10">
            <div className="bg-[#DDBB21] text-black font-bold text-center outline outline-1 outline-t outline-l outline-b outline-black p-3 my-3 rounded-tl-lg">
              <div className="absolute top-0 left-0 right-0 h-3 bg-[#DDBB21] rounded-tl-lg"></div>
              <div className="relative z-10">Team Leader</div>
            </div>
            <div className="bg-[#DDBB21] text-black font-bold text-center outline outline-1 outline-t outline-r outline-b outline-black p-3 my-3 rounded-tr-lg">
              <div className="absolute top-0 left-0 right-0 h-3 bg-[#DDBB21] rounded-tr-lg"></div>
              <div className="relative z-10">Organization Name</div>
            </div>
          </div>
          {listOrg.map((item) => (
            <div
              key={item.id}
              className="grid grid-cols-2 px-10 hover:bg-[#fff2c4] cursor-pointer duration-300"
              onClick={() => handleViewTeam(item)}
            >

              <div className="relative bg-white text-black font-regular text-center border border-black p-3 my-3 border-r-1 border-l-0 rounded-l-lg">
                <div className="absolute left-0 top-0 bottom-0 w-7 bg-[#DDBB21] border border-black rounded-l-lg"></div>
                <div className="relative ml-4">{item.organization_owner}</div>
              </div>
              <div className="relative bg-white text-black font-regular text-center border border-black p-3 my-3 border-r-0 border-l-0 rounded-r-lg">
                <div className="absolute right-0 top-0 bottom-0 w-7 bg-[#DDBB21] border border-black rounded-r-lg"></div>
                <div className="relative mr-4">{item.organization_name}</div>
              </div>
            </div>
          ))}
        </div>
      )}

      {viewTeam && (
        <ViewTeam
          closeViewTeam={setViewTeam}
          storeOrgName={storeOrgName}
          userDetails={userDetails}
          orgId={orgId}
          listOrg={listOrg}
        />
      )}
      <Toaster position="bottom-right" />
    </>
  );
};

export default Team;