import axios from "axios";

const baseUrl = process.env.REACT_APP_NODE_API_BASE_URL;

export const getUserProfile = async token => {
  try {
    const response = await axios.get(`${baseUrl}/api/v1/profile`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    return response?.data?.data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};

export const updateUserProfile = async (data, token) => {
  try {
    const response = await axios.put(`${baseUrl}/api/v1/profile`, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
    );

    return response?.data?.data;
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};
