
import axios from "axios";

const baseUrl = process.env.REACT_APP_NODE_API_BASE_URL;

export const getListUser = async (month) => {
const user = JSON.parse(localStorage.getItem("user"));
let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/admin/overall/users?q=${month}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
}

// --------------------------------------------------------------------------- //
export const getListToken = async (month) => {
const user = JSON.parse(localStorage.getItem("user"));
let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/admin/overall/tokens?q=${month}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
}
// --------------------------------------------------------------------------- //

export const getListStatus = async (month) => {
const user = JSON.parse(localStorage.getItem("user"));
let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/admin/overall/account-status?q=${month}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
}




export const TotalTokenUsed = async () => {
const user = JSON.parse(localStorage.getItem("user"));
let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/admin/usage`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.token}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
}