import React, { useEffect, useState, useCallback } from "react";
import { MdAdd } from "react-icons/md";
import Accordion from "./Accordion";
import VerificationModal from "../Modal/VerificationModal/VerificationModal";
import { generateApi, getApiKey } from "../../../API/GenerateApi";
import toast, { Toaster } from "react-hot-toast";
import { useUser } from "../../../context/UserContext";
import editKey from "../../../Pages/Admin/ApiKey/assets/edit-icon.svg";
import deleteKey from "../../../Pages/Admin/ApiKey/assets/delete-icon.svg";
import ellipsis from "../../../Pages/Admin/ApiKey/assets/ellipsis.svg";
import arrow from "../../../Pages/Admin/ApiKey/assets/arrow.svg";
import './keyTable.css';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import SearchKey from "./SearchKey";

const ApiKey = () => {
  const [isOpen, setIsOpen] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const [keyList, setKeyList] = useState([]);
  const columnHelper = createColumnHelper([keyList]);


  const columns = [
    columnHelper.accessor("id", {
      header: 'Name',
      cell: info => info.getValue()
    }),
    columnHelper.accessor('key', {
      header: 'Key',
      cell: info => info.getValue()
    }),
    columnHelper.display({
      id: 'actions',
      header: 'Actions',
      cell: info => (
        <div className="flex gap-5 items-center justify-center">
          <button>
            <img
              src={editKey}
              alt=""
              className="w-8 h-8 cursor-pointer hover:scale-125" />
          </button>
          <button>
            <img
              src={deleteKey}
              alt=""
              className="w-10 h-10 cursor-pointer hover:scale-125" />
          </button>
        </div>)
    })
  ]


  const handleAddApiKey = async () => {
    try {
      const token = user?.token;
      await generateApi(token);
      toast.success("API Key Generated Successfully");
      fetchApiKey();
    } catch (error) {
      toast.error("Error Getting API Key");
      console.log("Error Getting API Key", error);
    }
  };
  const fetchApiKey = useCallback(async () => {
    try {
      const token = user?.token;
      const response = await getApiKey(token);
      setKeyList(response.data);
    } catch (error) {
      console.log("Error Getting API Key", error);
    }
  }, [user?.token]);

  useEffect(() => {
    fetchApiKey();
  }, [fetchApiKey]);

  const [globalFilter, setGlobalFilter] = useState("");
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 100,
  });
  const table = useReactTable({
    columns,
    data: keyList,
    getCoreRowModel: getCoreRowModel(),
    state: { globalFilter, pagination },
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination
  });

  return (
    <>
      <section className="ml-[23rem] bg-white h-full">
        <header className="w-full bg-[#d9d9d9] py-3 px-10 ">
          <h1 className="font-bold text-4xl "> API Keys</h1>
        </header>

        <section className="p-10">
          <section className=" w-[62%]  font">
            <p className="pb-5">
              Your secret API keys are listed below, Please note that we do not
              display your secret API keys again after you generate them,
            </p>
            <p className="pb-5">
              DO not Share your API key With Others, or expose it in the browser
              or Other Client-side code, In Order to protect the security of
              your account, OpenAi may also automatically disable any API key
              that we've found has leaked publicly.
            </p>
            <p> Enable tracking to see usage per API key on the Usage page.</p>
          </section>
          <section className="w-[80%] bg-[#f1de9e] flex mt-5 h-32 items-center justify-between px-5 rounded-lg border-2 font-bold   border-[#c9a93b] ">
            <p className=""> Verify your phone number to create an API Key</p>
            <button
              className=" h-16 w-auto px-10  bg-[#e3b51a] rounded-xl text-white text-lg border-2 border-[#8e8e8e] hover:bg-[#b18900]"
              onClick={() => setIsOpen(!isOpen)}
            >
              Start Verification
            </button>
          </section>

          <section className="w-[90%] mt-5 h-50  px-5 rounded-lg border-2 border-gray-300 bg-[#F7F7F7]">

            {keyList && keyList.length ? (<>
              <div className="table-top">

                <div className="left-container">
                  <p>Show</p>
                  <select
                    value={table.getState().pagination.pageSize}
                    onChange={e => {
                      table.setPageSize(Number(e.target.value))
                    }}
                    className="border border-gray-400 bg-[#F7F7F7] rounded-[5px]">

                    {[20, 40, 60, 80, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </select>
                  <p>entries</p>
                </div>

                <div className="right-container">
                  <SearchKey
                    value={globalFilter ?? ""}
                    onChange={(value) => setGlobalFilter(String(value))}
                    placeholder="Table search"
                  />
                  <div className="container-buttons">
                    <button className="button1 hover:bg-gray-200">
                      <img
                        src={ellipsis}
                        alt=""
                        className="w-7 h-7 cursor-pointer"
                      />
                    </button>
                    <button className="button2 hover:bg-gray-200">
                      <img
                        src={arrow}
                        alt=""
                        className="w-7 h-7 cursor-pointer"
                      />
                    </button>
                  </div>
                </div>

              </div>

              <table>
                <thead className="">
                  {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map(header => (
                        <th key={header.id} className="table-head">

                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map(row => (
                    <tr key={row.id} className="table-row">
                      {row.getVisibleCells().map(cell => (
                        <td key={cell.id} className="cells">
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table> </>

            ) : (
              <div className="text-left m-10">
                <p className="text-3xl"><b>You currently do not have any API Keys.</b></p>
                <p>Create one using the button below to get started.</p>
              </div>
            )}
          </section>
          <button
            onClick={handleAddApiKey}
            className="flex items-center justify-center gap-2 h-20 text-[#8e8e8e] w-auto   pr-10 rounded-xl  pl-5 mt-5  border-2 border-[#939393]  bg-[#e8e8e8]"
          >
            <MdAdd /> Create new secret key
          </button>
          <h1 className="font-bold text-4xl mt-5">Default organization</h1>
          <section className="w-[60%] mt-5 font- ">
            <p>
              If you belong to multiple organizations, this setting controls
              which organization is used by default when making requests with
              the API keys above.
            </p>
          </section>
          <Accordion>helo world</Accordion>
        </section>
      </section>
      <VerificationModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <Toaster position="top-center" />
    </>
  );
};

export default ApiKey;
