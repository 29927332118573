import { Link } from "@mui/material";
import { useState } from "react";

// import icons
import { BiSolidDownArrow, BiSolidUpArrow  } from "react-icons/bi";
import { CiSearch } from "react-icons/ci";

function UserDropDown() {

    const [isOpen, setIsOpen] = useState(false)
    return (
    <div>

        <button 
        onClick={() => setIsOpen((prev) => !prev)} 
        className="border border-[#e3b51a] rounded-2xl p-5 mr-6 text-[#e3b51a] bg-white hover:bg-[#e3b51a] hover:text-white hover:no-underline flex justify-evenly items-center" >
        More 
                
        {!isOpen ? (
        <BiSolidDownArrow className="ml-2"/>
        ): (
            <BiSolidUpArrow className="ml-2"/>
        )}
        </button>
        
        {isOpen && <div className="w-[300px] h-[100px] rounded-2xl mt-2 absolute right-[300px] bg-[#d9d9d9]"> 

            {/* search container */}
            <div className="round rounded-t-xl grid grid-cols-2 gap-4" style={{ borderBottom: "1px solid #e3b51a" }}>

                <div class="relative w-full min-w-[300px] h-full flex space-between items-center">
                    <CiSearch className="w-[40px] h-[20px] select-none pointer-events-none left-0 font-normal !overflow-visible truncate float-left"/>
            
                    <input
                    class="absolute w-full h-full bg-transparent indent-[35px] text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 border border-transparent focus:border-2 focus:border-[#e3b51a] focus:rounded-t-xl"
                    placeholder="Search..."/>
                </div>  
            </div> 

            <div className="w-full h-[80px] flex items-center grid justify-items-center p-2 rounded-b-2xl">
                <p>No content</p>
            </div> 
        </div> }
    </div>
    );
}

export default  UserDropDown;