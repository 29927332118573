import React, { useContext } from "react";
import ThemeModeContext from "../context/ThemeContext";

const NewChat = () => {
  const theme = useContext(ThemeModeContext);
  return (
    <div className="flex flex-col items-center text-white h-full w-full py-28 gap-7">
      <div className="text-center mt-16">
        <h2 className={`mb-8 font-poppins font-bold text-6xl bg-clip-text text-transparent bg-gradient-to-b ${theme.theme === 'light' ? 'from-[#424242] to-[#F7B442]/90' : 'from-white to-[#F7B442]/90'}  py-2`}>
          Welcome to HighlyGPT
        </h2>
        <p className={`text-[1.25rem] ${theme.theme === 'light' ? 'text-lightText' : 'text-white'}`}>The Essential AI assistant for All</p>
      </div>
    </div>
  );
};

export default NewChat;
