import React, { useState, useEffect, useRef } from "react";
import {
  useDeleteUserMutation,
  useDisplayUsers,
} from "../../../../hooks/user/useUsers";
import { BiDownArrow } from "react-icons/bi";
import { BsFillCircleFill } from "react-icons/bs";
import DropdownForm from "./Form/DropdownForm";
import toast from "react-hot-toast";
import { Link, NavLink, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useUserTokenUsage } from "../../../../context/UserContext";


const ListUserTable = () => {
  // custom hooks
  const { data: displayUsers, isLoading } = useDisplayUsers()
  const deleteUserMutation = useDeleteUserMutation()

  // context
  const { assignUserId } = useUserTokenUsage()

  const [isTableRowHover, setIsTableRowHover] = useState(false);
  const [activeRow, setActiveRow] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isToggle, setIsToggle] = useState(null);
  const [isDropDownButtonHover, setIsDropDownButtonHover] = useState(false);
  const [isDropDownFormHover, setIsDropDownFormHover] = useState(false);
  const [previousLocation, setPreviousLocation] = useState(null); // Track previous location

  const dropdownFormeRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        dropdownFormeRef.current &&
        !dropdownFormeRef.current.contains(event.target) &&
        !event.target.closest(".dropdown-button") // Check if clicked outside of dropdown form and not on dropdown button
      ) {
        setIsMenuOpen(false);
        if (previousLocation) {
          navigate("/admin/user"); // Navigate back to previous location
        }
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [previousLocation, navigate]);

  const toggleMenu = id => {
    setIsToggle(id);
  };

  const handleTableRowHover = (isTrue, id) => {
    setIsTableRowHover(isTrue, id);
  };

  const handleDropdownActive = (isTrue, id) => {
    setActiveRow(id);
    setIsMenuOpen(isTrue);
    setPreviousLocation(location.pathname);
    navigate(`action/${id}`);
  };

  const handleDropdownHover = (isTrue, id) => {
    setIsDropDownButtonHover(isTrue, id);
  };

  const handleDropdownFormHover = (isTrue, id) => {
    setIsDropDownFormHover(isTrue, id);
  };

  const handleTableRowClick = (id) => {
    assignUserId(id)
    navigate(`usage/${id}`)
  }

  const handleDeleteUser = async id => {
    try {
      await deleteUserMutation.mutate(id);
      setIsMenuOpen(false);
      toast.success("User deleted successfully!");

      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      toast.error("Error", error.message);
    }
  };
  return (
    <section className="w-[95%] bg-[#D8D8D8] min-h-[calc(100vh-7rem)]">
      <table className="border-collapse w-[88%] mx-auto mt-10 ">
        <thead className="h-24 font-bold bg-[#DDBB21] text-white outline outline-1 outline-t outline-l outline-b outline-black rounded-l-3xl rounded-r-3xl">
          <tr className="p-2">
            <th className="text-center rounded-l-3xl">Name</th>
            <th className="text-center">Email</th>
            {/* temporary */}
            <th className="text-center">Role</th>
            <th className="text-center">Organization</th>
            <th className="text-center">Status</th>
            <th className="text-center rounded-r-3xl">More</th>
          </tr>
        </thead>
        <section className="h-10 "></section>

        <tbody className="w-[100%]">
          {
            displayUsers
              ?.filter(user => user?.role !== "superadmin")
              .map(user => {
                return (
                  <React.Fragment key={user?.id}>
                    <tr
                      key={user?.id}
                      className={`${isTableRowHover === user?.id ||
                        (isMenuOpen && activeRow === user?.id)
                        ? "bg-[#ddbb21]"
                        : ""
                        } border-none text-center h-24 bg-white hover:cursor-pointer hover:bg-[#ddbb21] ${isMenuOpen && "relative"
                        }`}
                      style={{
                        borderRadius: "50px",
                        transition: "background-color 0.1s, color 0.9s",
                      }}
                      onMouseEnter={() => handleTableRowHover(true, user?.id)}
                      onMouseLeave={() => handleTableRowHover(false, null)}
                      onClick={() =>
                        !isDropDownButtonHover &&
                        !isDropDownFormHover &&
                        handleTableRowClick(user?.id)
                      }
                    >
                      <td className="rounded-l-2xl capitalize">
                        {user?.first_name} {user?.middle_name} {user?.last_name}
                      </td>
                      <td className="">{user?.email}</td>
                      <td className="capitalize">{user?.role}</td>
                      <td className="capitalize">{user?.organization_name}</td>
                      <td>
                        <button className="border border-black flex items-center capitalize gap-4 pl-10 2xl:pl-14 py-1 rounded-lg w-[12rem] 2xl:w-[15rem] mx-auto">
                          <BsFillCircleFill
                            className={`${user?.active === "enable"
                              ? "text-green-500"
                              : "text-red-500"
                              }`}
                          />
                          <span className="my-auto">
                            {user?.active === "enable" ? "Active" : "Inactive"}
                          </span>
                        </button>
                      </td>
                      <td className="p-2 border-none rounded-r-2xl">
                        <button
                          className={`border border-black px-6 py-3 rounded-lg bg-white hover:bg-[#ddbb21] dropdown-button`}
                          onMouseEnter={() => handleDropdownHover(true, user?.id)}
                          onMouseLeave={() => handleDropdownHover(false, null)}
                          onClick={() => {
                            handleDropdownActive(!isMenuOpen, user?.id);
                            toggleMenu(user?.id);
                          }}
                        >
                          <BiDownArrow
                            className={`w-6 h-6 text-black ${isMenuOpen && activeRow === user?.id
                              ? "rotate-180"
                              : ""
                              }`}
                          />
                        </button>
                      </td>

                      {isToggle === user?.id && isMenuOpen && (
                        <div
                          ref={dropdownFormeRef}
                          class="absolute z-20 w-full h-fit top-24 right-0 rounded-xl bg-white shadow-lg"
                          onMouseEnter={() =>
                            handleDropdownFormHover(true, user?.id)
                          }
                          onMouseLeave={() =>
                            handleDropdownFormHover(false, null)
                          }
                        >
                          <DropdownForm
                            user={user}
                            handleDeleteUser={handleDeleteUser}
                            setIsMenuOpen={setIsMenuOpen}
                            navigate={navigate}
                          />
                        </div>
                      )}
                    </tr>
                    <section className="h-5"></section>
                  </React.Fragment>
                );
              })
          }

        </tbody>
      </table>

      {
        isLoading && (
          <div className="mt-[20%] mx-auto my-auto flex flex-row items-center justify-center gap-6">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-[#ddbb21] border-solid"></div>
            <span>Loading...</span>
          </div>
        )
      }

      {
        displayUsers?.length === 0 && (
          <div className="w-[88%] mt-20 flex items-center justify-center mx-auto">
            <h2 className="text-shark-300 text-2xl text-center">No list of user</h2>
          </div>
        )
      }
    </section>
  );
};

export default ListUserTable;
