import { Outlet } from "react-router-dom";
import Content from "./Content/Content";

const AdminPage = () => {
  return (
    <>
      <Content />
      <Outlet />
    </>
  )
}

export default AdminPage;

