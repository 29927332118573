import { IoMdReturnLeft } from "react-icons/io";
import { TfiReload } from "react-icons/tfi";
import { RiHandCoinFill, RiAddLine, RiDeleteBin6Fill } from "react-icons/ri";
import { BsPeopleFill } from "react-icons/bs";
import { organizeTeamData } from "./data/organizeTeamData";
import { useState, useEffect } from "react";
import ResetToken from "./resetToken";
import GrantToken from "./grantToken";
import AddMember from "./addMember";
import GrantOneMember from "./grantOneMember";
import RemoveMember from "./removeMember";
import { getListTeam, resetToken, deleteTeam } from "../../../../../API/Team";
import toast, { Toaster } from "react-hot-toast";
import { useUser } from "../../../../../context/UserContext";

const OrganizeTeam = ({ handleReturn, navigate }) => {
  const user = useUser();
  const [reset, setReset] = useState(false);
  const [grant, setGrant] = useState();
  const [addMember, setAddMember] = useState();

  const [grantMember, setGrantMember] = useState();
  const [removeMember, setRemoveMember] = useState();

  const [listOrg, setListOrg] = useState(null);

  const [listMember, setListMember] = useState(null);

  const [storeData, setStoreData] = useState([]);

  const [storeID, setStoreID] = useState(null);

  const handleResetToken = () => {
    setReset(prev => !prev);
  };

  const handleGrantToken = () => {
    setGrant(!grant);
  };

  const handleAddMember = () => {
    setAddMember(!addMember);
  };

  const handleGrantOneMember = item => {
    setGrantMember(!grantMember);
    setStoreData(item);
  };

  const handleRemoveMember = item => {
    setRemoveMember(prev => !prev);
    setStoreID(item);
  };

  const funcRemoveMember = async () => {
    try {
      const token = user?.token;
      await deleteTeam(storeID, token);
      toast.success("You have removed a member");
      fetchData();
      handleRemoveMember(false);
    } catch (error) {
      toast.error();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const token = user?.token;
      const Response = await getListTeam(token);
      setListMember(Response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const funcResetToken = async () => {
    try {
      const token = user?.token;
      await resetToken(token);
      toast.success(
        "You have successfully reset you and you're member's token"
      );
      setReset(false);
      fetchData();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  

  return (
    <>
      {removeMember && (
        <RemoveMember
          handleReturn={handleRemoveMember}
          funcRemoveMember={funcRemoveMember}
        />
      )}
      {grantMember && (
        <GrantOneMember
          handleReturn={setGrantMember}
          setStoreData={storeData}
          listMember={setListMember}
        />
      )}
      {addMember && (
        <AddMember handleReturn={setAddMember} setListMember={setListMember} />
      )}
      {grant && (
        <GrantToken handleReturn={setGrant} listMember={setListMember} />
      )}
      {reset && (
        <ResetToken setReset={setReset} funcResetToken={funcResetToken} />
      )}
      <div className="relative text-white">
        <div className="fixed z-10 flex flex-row justify-center items-center top-0 left-0 h-[100%] w-[100%] bg-[#000000ad]">
          <div className="bg-[#2f3031] p-12 ">
            <div className="grid grid-cols-2">
              <div className="flex flex-row items-center">
                <img
                  src="https://www.worldatlas.com/r/w1200/upload/90/92/1d/shutterstock-370060808.jpg"
                  className="rounded-[100%] w-[150px] h-[150px] mx-5"
                  alt=""
                />
                <div className="flex flex-col ">
                  <div className="text-7xl font-black capitalize pb-2">
                    {user.organization}
                  </div>
                  <div className="text-3xl font-normal capitalize pb-2">
                    Team Leader:
                    {`${user.firstName} ${user.middleName} ${user.lastName}`}
                  </div>
                  <div className="text-xl font-light pb-2">
                    Member Count:{" "}
                    {listMember?.length > 0 ? listMember?.length : "0"}
                  </div>
                </div>
              </div>
              <div className="flex justify-end items-start ">
                <button
                  className=" flex justify-between items-center gap-[3px] hover:text-white text-[#9b9b9b] p-5 cursor-pointer duration-300"
                  onClick={() => {
                    navigate(-1)
                    handleReturn(false)
                  }}
                >
                  <IoMdReturnLeft /> Return
                </button>
              </div>
            </div>
            <div className="bg-[#d9d9d9] flex justify-end gap-[10px] rounded-t-[12px] mt-3 p-5">
              <button
                className=" flex justify-between gap-[3px] items-center bg-hsi-yellow text-black font-semibold p-5 rounded-[12px] hover:bg-[#c4ab52] drop-shadow-lg duration-300"
                onClick={handleResetToken}
              >
                <TfiReload className="text-3xl" /> Reset Token
              </button>
              <button
                className=" flex justify-between gap-[3px] items-center bg-hsi-yellow text-black font-semibold p-5 rounded-[12px] hover:bg-[#c4ab52] drop-shadow-lg duration-300"
                onClick={handleGrantToken}
              >
                <RiHandCoinFill className="text-3xl" /> Grant Token
              </button>
              <button
                className=" flex justify-between gap-[3px] items-center bg-hsi-yellow text-black font-semibold p-5 rounded-[12px] hover:bg-[#c4ab52] drop-shadow-lg duration-300"
                onClick={handleAddMember}
              >
                <BsPeopleFill /> Add Member
              </button>
            </div>
            <div className="grid grid-cols-5 items-center text-center  ">
              <div className="bg-hsi-yellow font-bold p-5 border-2 border-[#5f5f5f]  text-black ">
                Username
              </div>
              <div className="bg-hsi-yellow font-bold p-5 border-2 border-[#5f5f5f]  text-black ">
                Name
              </div>
              <div className="bg-hsi-yellow font-bold p-5 border-2 border-[#5f5f5f]  text-black ">
                Position
              </div>
              <div className="bg-hsi-yellow font-bold p-5 border-2 border-[#5f5f5f]  text-black ">
                Chat Token
              </div>
              <div className="bg-hsi-yellow font-bold p-5 border-2 border-[#5f5f5f]  text-black ">
                Actions
              </div>
            </div>
            <div className="h-[40vh] overflow-auto">
              {listMember?.map(item => {
                return (
                  <div
                    key={item.id}
                    className="grid grid-cols-5 items-center text-center "
                  >
                    <div className="bg-white h-[100%] p-5 border-2  text-black">
                      {item.username}
                    </div>
                    <div className="bg-white h-[100%] p-5 border-2  text-black ">{`${item.first_name} ${item.middle_name} ${item.last_name}`}</div>
                    <div className="bg-white h-[100%] p-5 border-2  text-black ">
                      {item.role}
                    </div>
                    <div className="bg-white h-[100%] p-5 border-2  text-black ">
                      {item.member_token}
                    </div>
                    <div className="bg-white h-[100%] p-5 border-2  text-black flex justify-center gap-[5px]">
                      <button
                        className=" bg-[#268154] rounded-[5px] text-[#fefefe] text-3xl font-bold p-[3px]  "
                        onClick={() => handleGrantOneMember(item)}
                      >
                        <RiAddLine className="" />
                      </button>
                      <button
                        className=" bg-[#a53232] rounded-[5px] text-[#fefefe] text-3xl font-bold p-[3px]  "
                        onClick={() => handleRemoveMember(item.member_user_id)}
                      >
                        <RiDeleteBin6Fill className="" />
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Toaster position="top-right" />
    </>
  );
};

export default OrganizeTeam;
