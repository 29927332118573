import axios from 'axios'

const baseUrl = process.env.REACT_APP_NODE_API_BASE_URL;

export const getPromptTemplates = async () => {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/prompt-templates`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
    },
  };

  const response = await axios.request(config);
  return response.data;
}