import React, { useState, useEffect } from "react";
import {
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";
import ViewFeedback from "./ViewFeedback";
import DeleteFeedback from "./DeleteFeedback";
import { IoIosSearch } from "react-icons/io";
import { TbTriangle } from "react-icons/tb";
import {
  getFeedback,
  deleteFeedback,
  getChatUser,
  getUser,
} from "../../../../API/Feedback";
// import { resolvePath } from "react-router-dom";
// import { array } from "zod";
import toast, { Toaster } from "react-hot-toast";

const ListUserFeedback = ({ header, children }) => {
  const [viewFeedbacks, setViewFeedback] = useState(false);
  const [openDeleteFeedback, setOpenDeleteFeedback] = useState(false);
  const [openViewFeedback, setOpenViewFeedback] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [listFeedback, setlistFeedback] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [data, setData] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [date, setDate] = useState(null);
  const [loading, setloading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const toggleDropdown = index => {
    setExpandedRows(prevExpandedRows => {
      const newExpandedRows = [...prevExpandedRows];
      newExpandedRows[index] = !newExpandedRows[index];
      return newExpandedRows;
    });
  };

  const handleDeleteOnClick = async (e, title) => {
    setSelectedFeedback(title.id);
    e.stopPropagation();
    setOpenDeleteFeedback(true);
  };

  const handleDeleteFeedback = async () => {
    try {
      setloading(true);
      await deleteFeedback(selectedFeedback);
      setData(prevData =>
        prevData.filter(list => list.id !== selectedFeedback)
      );
      const response = await getFeedback();
      setlistFeedback(response.data);
      setOpenDeleteFeedback(false);
      toast.success("Feedback deleted successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error deleting Feedback");
    } finally {
      setloading(false);
    }
  };
  const handleViewOnClick = async title => {
    setSelectedFeedback(title.user_id);
    setOpenViewFeedback(true);
    setDate(title);
    // const filterData = setUserDetails.filter(list =>)
  };

  const formattedDate = date => {
    return new Date(date).toISOString().split("T")[0];
  };

  useEffect(() => {
    const getFeedbacks = async () => {
      try {
        setloading(true);
        const Response = await getFeedback();
        setlistFeedback(Response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setloading(false);
      }
    };
    const getChat = async () => {
      try {
        const id = listFeedback.chat_id;
        const Response = await getChatUser(id);
        setlistFeedback(Response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getChat();
    getFeedbacks();
  }, []);

  useEffect(() => {
    const getViewFeedbacks = async () => {
      try {
        const Response = await getUser();
        setUserDetails(Response.data);
      } catch (error) {
        console.log(error);
      }
    };
    getViewFeedbacks();
  }, []);

  const filteredFeedback = listFeedback.filter(feedback => {
    const user = userDetails.find(user => user.id === feedback.user_id);
    const fullName = `${user?.first_name} ${user?.middle_name} ${user?.last_name}`;
    return fullName.toLowerCase().includes(searchInput.toLowerCase());
  });
  return (
    <>
      <div className="content h-screen bg-white w-full overflow-auto pl-[23rem] ">
        <div className="grid grid-cols-2 sticky top-0 bg-gray-300 p-10 z-10">
          <div className="text-5xl font-bold"> Feedback </div>
          <div className="flex justify-end">
            <section className="relative left-12 top-[1rem] flex items-center justify-center  w-8 h-8 bg-[#eac446] border-[1px] rounded-full border-black ">
              <IoIosSearch className="text-md " />
            </section>
            <input
              type="search"
              placeholder="Search Feedback.."
              className="pl-14 placeholder:text-gray py-3 w-[230px] rounded-2xl  border-2 border-[#a17d00] outline-none"
              onChange={e => setSearchInput(e.target.value)}
            />
          </div>
        </div>

        {/* Table Header */}
        <div
          className="grid grid-cols-5 bg-white sticky top-[8.9rem] pt-10 px-10 z-10"
          style={{
            gridTemplateColumns:
              "minmax(0, 30rem) minmax(0, 1fr) minmax(0, 25rem) minmax(0, 25rem) minmax(0, 20rem)",
          }}
        >
          <div className="p-5 py-10 border-[1px] bg-hsi-yellow border-b-black border-r-black rounded-tl-3xl text-black font-bold flex justify-center ">
            User
          </div>
          <div className="p-5 py-10 border-[1px] border-l-[0px] bg-hsi-yellow border-b-black border-r-black text-black font-bold flex justify-center ">
            Prompt
          </div>
          <div className="p-5 py-10 border-[1px] border-l-[0px] bg-hsi-yellow border-b-black border-r-black text-black font-bold flex justify-center ">
            Response
          </div>
          <div className="p-5 py-10 border-[1px] border-l-[0px] border-r-[0px] bg-hsi-yellow border-b-black text-black font-bold flex justify-center ">
            Date
          </div>
          <div className="p-5 py-10 border-[1px] border-l-[0px] bg-hsi-yellow border-b-black rounded-tr-3xl text-black font-bold flex justify-center "></div>
        </div>
        <div className="pb-20">
          {loading && (
            <div className="w-full h-[500px] mb-20 flex items-center justify-center text-3xl">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-[#eab646] border-solid"></div>
            </div>
          )}
          {filteredFeedback.length === 0 && !loading ? (
            <div className="w-full h-[500px] mb-20 flex items-center justify-center text-3xl">
              {" "}
              No Result Found
            </div>
          ) : (
            filteredFeedback?.map(list => {
              const filterName = userDetails.find(
                item => item.id === list.user_id
              );
              return (
                <div
                  key={list.id}
                  className=" grid grid-cols-5 bg-white pt-5 px-10 flex-1 "
                  style={{
                    gridTemplateColumns:
                      "minmax(0, 30rem) minmax(0, 1fr) minmax(0, 25rem) minmax(0, 25rem) minmax(0, 20rem)",
                  }}
                >
                  {/* Table Data 1 */}
                  <div className="p-5 text-xl border-[1px] border-r-[0px] overflow-hidden pl-20 pb-10 border-gray-400 border-b-hsi-yellow border-b-[10px] border-r-gray flex flex-row align-center items-center mb-3">
                    <p className="flex flex-col gap-3 text-ellipsis font-medium mt-2 ml-5">
                      {`${filterName?.first_name} ${filterName?.middle_name} ${filterName?.last_name}`}
                      <p className="text-gray-500 font-normal">{filterName?.email}</p>
                    </p>
                  </div>
                  <div className="p-5 flex justify-center text-xl border-[1px] border-gray-400 border-b-hsi-yellow border-b-[10px] mb-3 ">
                    <p className="line-clamp-5 font-medium">{list.user_content}</p>
                  </div>
                  
                  <div className="p-5 flex items-center text-xl border-l-[0px] border-[1px] border-gray-400 border-b-hsi-yellow border-b-[10px] justify-center gap-10 mb-3">
                    <button
                      className={` rounded-[5px] duration-300 ${list.like === "like" && "text-[#49c14b] focus:text-red-900 active:text-red-900"
                        }`}
                    >
                      <AiOutlineLike className="text-5xl" />
                    </button>

                    <button
                      className={` rounded-[5px] duration-300 ${list.like === "dislike" && "text-red-500"
                        }`}
                    >
                      <AiOutlineDislike className="text-5xl" />
                    </button>
                  </div>



                  <div className="p-5 flex items-center justify-center text-xl font-medium border-[1px] border-l-[0px] border-gray-400 border-b-hsi-yellow border-b-[10px]  mb-3 ">
                    {" "}
                    {formattedDate(list.created_at)}
                  </div>
                  <div className="p-5 flex items-center text-xl border-[1px] border-l-[0px] border-gray-400 justify-center gap-10 border-b-hsi-yellow border-b-[10px] mb-3">
                    <div
                      className={`flex flex-col relative items-center w-full justify-center rounded-lg  ${expandedRows[list.id] ? " gap-4" : ""
                        }`}
                    >
                      <button
                        onClick={() => toggleDropdown(list.id)}
                        className={`text-black text-[20px] py-3 hover:text-[#F7B442] ${expandedRows[list.id] && ""
                          }`}
                      >
                        <div className="w-full flex justify-center items-center gap-4 pt-2 ">
                          {expandedRows[list.id] ? (
                            <TbTriangle className="text-black transition duration-300" />
                          ) : (
                            <TbTriangle className=" transition md:rotate-180 duration-300 " />
                          )}
                        </div>
                      </button>
                      <div
                        className={`absolute top-full w-full h-auto left-0 grid grid-rows-2 overflow-hidden ${expandedRows[list.id] ? "max-h-[1000rem] " : "max-h-0"
                          }`}
                      >
                        {children}
                        <button
                          className="bg-white border-[2px] hover:bg-hsi-yellow hover:text-[#fefefe] duration-300"
                          onClick={() => handleViewOnClick(list)}
                        >
                          View
                        </button>

                        <button
                          className="py-2 bg-white border-x-[2px] rounded-b-2xl hover:bg-[#ea4d4d] hover:text-[#fefefe] duration-300 border-b-hsi-yellow border-b-[10px]"
                          onClick={e => handleDeleteOnClick(e, list)}
                        >
                          Delete
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
        <ViewFeedback
          openViewModal={openViewFeedback}
          handleView={setViewFeedback}
          setOpenViewFeedback={setOpenViewFeedback}
          userDetails={userDetails}
          selectedFeedback={selectedFeedback}
          formattedDate={formattedDate}
          date={date}
        />
        <DeleteFeedback
          openDeleteModal={openDeleteFeedback}
          handleDeleteFeedback={handleDeleteFeedback}
          setOpenDeleteFeedback={setOpenDeleteFeedback}
          loading={loading}
        />
      </div>
      <Toaster position="bottom-right" />
    </>
  );
};

export default ListUserFeedback;
