import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const AutoSignIn = ({ children }) => {
    const userInfo = JSON.parse(localStorage.getItem("user")) || false;
    const staySignedIn = JSON.parse(localStorage.getItem("staySignedIn") || false); //
    const navigate = useNavigate();

    useEffect(() => {
        if (userInfo.role === "superadmin"){
            return navigate('/admin/user', {replace:true});
        } else if (userInfo.role === "member" || userInfo.role === "none"){
            return navigate('/home', {replace:true});
        }
    }, []);

    return null;

}

export default AutoSignIn;