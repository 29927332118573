import { GoAlertFill } from "react-icons/go";

const RemoveMember = ({onCancel}) => {
    
    return(
        <>
            <div className='relative duration-300'>
                <div className='fixed z-20 top-0 left-0 w-[100%] h-[100%] bg-[rgba(0,0,0,0.5)]'> 
                    <div className='w-[70%] sm:w-[50%] md:w-[30%] rounded-[12px] bg-[#fefefe] my-[10%] mx-auto p-[20px] overflow-auto'>
                        <div className="row flex justify-center">
                            <GoAlertFill className="text-7xl rounded-full bg-[#e0e0e0] m-1 w-auto h-auto p-5" />
                        </div>
                        <div className="row text-center">
                            <div className="text-4xl font-bold py-2">Are you sure?</div>
                            <p className="text-2xl py-5 text-gray-400">
                                This action cannot be undone
                            </p>
                        </div>
                        <div className="row pl-5 pr-5 mb-2 text-center">
                            <button className="rounded-[12px] w-[100%] p-6 text-white font-bold bg-red-500 hover:opacity-50 duration-300" onClick={''}> Delete Chat </button>
                        </div>
                        <div className="row pl-5 pr-5 mt-2 text-center">
                            <button className="rounded-[12px] w-[100%] p-6 text-white font-bold bg-black hover:opacity-50 duration-300 " onClick={() => {
                                onCancel(false)
                            }}> Cancel </button>
                        </div>
                    </div>
                </div>
            </div>
        
        </>
            
    );
}

export default RemoveMember;