import { useState, useEffect, createContext } from "react";

const ThemeModeContext = createContext();

export const ThemeModeContextProvider = ({ children }) => {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'dark');

    useEffect(() => {
        localStorage.setItem('theme', theme.toLowerCase());
    }, [theme]);

    const toggleTheme = (newTheme) => {
        setTheme(newTheme.toLowerCase());
    };

    return (
        <ThemeModeContext.Provider value={{ theme, toggleTheme }}>
            {children}
        </ThemeModeContext.Provider>
    );
};

export default ThemeModeContext;
