import React, { useState } from "react";
//import { AddUserSchema } from "../../schemas/UserSchema";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export const OrgUsageExport = ({ handleOpenModal }) => {
  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
  //resolver: zodResolver(AddUserSchema),
  });

  const [selectedOption, setSelectedOption] = useState("csv");

  const handleFileChange = e => {
    setSelectedOption(e.target.value);
  };

  const convertToTSV = data => {
    // Convert JSON to TSV format
    const rows = data.map(obj => Object.values(obj).join("\t"));
    return rows.join("\n");
  };

  const handleExportFiles = () => {
    console.log("Selected option:", selectedOption);
    fetch("https://dummyjson.com/todos")
      .then(res => res.json())
      .then(response => {
        let fileName = "sample1";
        let exportFileType;
        let mimeType;

        switch (selectedOption) {
          case "csv":
            exportFileType = response.todos;
            fileName += ".csv";
            mimeType = "text/csv";
            break;
          case "tsv":
            exportFileType = convertToTSV(response.todos);
            fileName += ".tsv";
            mimeType = "text/tab-separated-values";
            break;
          case "json":
            exportFileType = JSON.stringify(response.todos);
            fileName += ".json";
            mimeType = "application/json";
            break;
          default:
            exportFileType = response.todos;
            fileName += ".csv";
            mimeType = "text/csv";
        }

        const blob = new Blob([exportFileType], { type: mimeType });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      });
  };

  const onSubmit = data => {
    alert(data);
    reset();
  };

  return (
    <section className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-[#00000065]">
      <section className="w-[50rem] h-fit border bg-white rounded-3xl ">
        <form onSubmit={handleSubmit(onSubmit)} className="py-4">
          <section className="flex items-center justify-between text-3xl px-10 ">
            <h1 className="text-4xl font-bold"> Export cost data</h1>
            <IoIosCloseCircleOutline
              onClick={handleOpenModal}
              className="hover:text-yellow-500 hover:cursor-pointer"
            />
          </section>

          <section className="flex gap-5 items-center px-10 py-5 ">
            <section className="w-full">
              <h4 className="font-bold">Date Range</h4>
              <p>Maximum of 60 day range</p>

              <div className="flex justify-between mb-[30px] mt-[30px] items-center">
                <input
                  type="date"
                  name=""
                  id=""
                  className="border-4 w-[500px] text-center"
                />
                <div className="w-[50%] h-2 bg-[#DDBB21] rounded-xl mx-[20px]"></div>
                <input
                  type="date"
                  name=""
                  id=""
                  className="border-4 w-[500px] text-center"
                />
              </div>

              <h4 className="font-bold">File format</h4>
              <div className="flex items-center">
                <div className="mr-[5px]">
                  <input
                    type="radio"
                    name="file"
                    value="csv"
                    checked={selectedOption === "csv"}
                    onChange={handleFileChange}
                    className="align-middle"
                  />
                  <label className="font-normal"> CSV</label>
                </div>

                <div className="mr-[5px]">
                  <input
                    type="radio"
                    name="file"
                    value="tsv"
                    checked={selectedOption === "tsv"}
                    onChange={handleFileChange}
                    className="align-middle"
                  />
                  <label className="font-normal"> TSV</label>
                </div>

                <div className="mr-[5px]">
                  <input
                    type="radio"
                    name="file"
                    value="json"
                    checked={selectedOption === "json"}
                    onChange={handleFileChange}
                    className="align-middle"
                  />
                  <label className="font-normal"> JSON</label>
                </div>
              </div>
            </section>
          </section>

          <section className=" w-full flex justify-start px-16 mt-12 mb-4">
            <button
              onClick={handleExportFiles}
              type="button"
              className="w-48 h-auto py-4 flex items-center rounded-xl text-white  font-medium justify-center bg-[#DDBB21] mr-[5px]"
            >
              Export
            </button>
            <button
              className="w-48 h-auto rounded-xl py-4 bg-[#d9d9d9] font-medium"
              onClick={handleOpenModal}
            >
              Cancel
            </button>
          </section>
        </form>
      </section>
    </section>
  );
};

export default OrgUsageExport;
