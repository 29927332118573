import ProfileForm from "./Form/ProfileForm";
import { IoCloseCircleOutline } from "react-icons/io5";
import luffy from '../../../../Images/luffy.png'

const ProfileModal = ({ handleTriggerModal, userProfile }) => {
  return (
    <section className="fixed top-0 left-0 z-50 w-full h-full flex justify-center items-center bg-black/10">
      <section
        className="w-[52rem] h-fit border bg-white"
      >
        <section className=" flex justify-between px-10 h-28">
          <div className=" flex items-center gap-4 h-full">
            <div className="w-12">
              <img
                className="w-full h-auto"
                src={luffy}
                alt="user-profile-pic"
              />
            </div>
            <p className="text-xl mt-4">{userProfile?.email}</p>
          </div>
          <button
            onClick={() => handleTriggerModal(false)}
            className="hover:text-[#EFB312]"
          >
            <IoCloseCircleOutline className="w-10 h-10" />
          </button>
        </section>
        <div>
          <div className="bg-[#EFB312] h-4"></div>
          <div className="bg-[#463200] h-4"></div>
        </div>
        <section className="flex flex-col h-auto space-y-8">
          <section className="border-b border-black py-6">
            <h2 className="pl-10 font-semibold font-poppins text-[2rem]">Account Profile</h2>
          </section>
          <section className="mx-auto">
            <div className="w-44">
              <img
                className="w-full h-auto"
                src={luffy}
                alt="user-profile-pic"
              />
            </div>
          </section>
          <section className="flex flex-col items-center">
            <ProfileForm userProfile={userProfile} />
          </section>
        </section>

      </section>
    </section>
  );
}

export default ProfileModal;