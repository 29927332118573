import React from 'react'
import LeftSideBar from '../Components/SideBar/LeftSideBar'
import RightSideBar from '../Components/SideBar/RightSideBar'
import Terminal from '../Components/Terminal/TerminalBox'
import { ConversationListProvider } from '../../context/ConversationListContext'
import { ConvsersationsProvider } from '../../context/ConversationsContext'
import { PromptTemplatesProvider } from '../../context/PromptTemplateContext'
import { ThemeModeContextProvider } from '../../context/ThemeContext'


const DashboardPage = () => {
  return (
    <PromptTemplatesProvider>
      <ConversationListProvider>
        <ConvsersationsProvider>
          <ThemeModeContextProvider>
            <div className='bg-transparent min-h-screen relative flex'>
              <LeftSideBar />
              <Terminal />
              <RightSideBar />
            </div>
          </ThemeModeContextProvider>
        </ConvsersationsProvider>
      </ConversationListProvider>
    </PromptTemplatesProvider>
  )

}

export default DashboardPage