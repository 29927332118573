import axios from "axios";

const baseUrl = process.env.REACT_APP_NODE_API_BASE_URL;

// Get filtered token usage for a certain time
// return the overall user token usage
export const getFilterUsage = async (month, year, token) => {
  try {
    const response = await axios.get(`${baseUrl}/api/v1/admin/filtered-usage?month=${month}&year=${year}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    })

    return response?.data?.data

  } catch (error) {
    console.log(error)
  }
}

// Get lists of user token usage
export const getListOfUsersTokenUsage = async (token) => {
  try {
    const response = await axios.get(`${baseUrl}/api/v1/admin/usage`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      }
    })

    return response?.data?.data

  } catch (error) {
    console.log(error)
  }
}

export const updateUserToken = async (user, token) => {
  try {
    const response = await
      axios.put(`${baseUrl}/api/v1/admin/update-personal-token/${user.id}`, user, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      })

    return response?.data?.data

  } catch (error) {
    console.log(error)
    throw error
  }
}
