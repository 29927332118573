import { IoMdCloseCircleOutline } from "react-icons/io";
import { useForm } from "react-hook-form";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import { limitTokenAll, getListTeam } from "../../../../../API/Team";
import { useUser } from "../../../../../context/UserContext";
const GrantToken = ({ handleReturn, listMember }) => {
  const user = useUser();
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { error, errors },
  } = useForm();

  const onSubmit = async data => {
    try {
      const token = user?.token;
      await limitTokenAll(data, token);
      const Response = await getListTeam(token);
      listMember(Response.data);
      handleReturn(false);
      reset();
      toast.success("You have added token to a member");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      <div className="relative text-white">
        <div className="fixed z-20 flex flex-row justify-center items-center top-0 left-0 h-[100%] w-[100%] bg-[#000000ad]">
          <div className="flex flex-col items-center">
            <div className="bg-[#2f3031] w-[30vw] p-12 grid grid-cols-2 border-b-[5px] rounded-t-[12px] border-[#efc62f] ">
              <div className="flex justify-start text-4xl font-bold">
                {" "}
                Grant Members{" "}
              </div>
              <button
                className="flex justify-end items-center  hover:text-[#EFC62F] duration-300 "
                onClick={() => handleReturn(false)}
              >
                <IoMdCloseCircleOutline className="text-3xl bg-tuna-800 rounded-full m-1 w-auto h-auto p-1" />
              </button>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col  p-12 gap-[10px] w-[30vw] bg-[#2f3031] "
            >
              <div className="text-center italic text-[#5a5a5a]  px-10 text-xl">
                This feature grants token/s to every members of the team.
              </div>
              <div className="relative flex flex-col gap-[5px] justify-center items-center p-5">
                <label className="absolute top-1 left-[55px] text-2xl bg-white text-black px-3 text-left">
                  Token
                </label>
                <input
                  type="number"
                  className="rounded-[12px] w-[85%] bg-[#3c3d40] border border-[#fefefe] py-6 px-8"
                  placeholder="MAX: 9999"
                  min="1"
                  max="9999"
                  {...register("member_token", {
                    required: "This field is required",
                  })}
                />
                <span> {errors?.member_token?.message} </span>
              </div>

              <div className="flex justify-center items-center">
                <button className="border border-[#fefefe] rounded-[12px] py-5 px-8 bg-[#efc62f] text-2xl text-black font-bold hover:bg-[#c4ab52] duration-300">
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Toaster position="bottom-right" />
    </>
  );
};

export default GrantToken;
