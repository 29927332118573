import { IoMdCloseCircleOutline } from "react-icons/io";



const ResetToken = ({handleReturn, setReset, funcResetToken}) => {
    return(
        <>
            <div className="relative text-white">
                <div className="fixed z-20 flex flex-row justify-center items-center top-0 left-0 h-[100%] w-[100%] bg-[#20212346]">
                    <div className="w-[25%] max-w-[600px] bg-[#2f3031] mx-[10%] p-12 ">
                        <div className="flex flex-col text-center justify-center py-2">
                            <h2>Alert</h2>
                            <p className="py-2 text-gray-400 ">You are trying to reset you and your members' token. Do you still want to proceed?</p>
                        </div>
                        <div className="flex justify-center gap-[20px]">
                            
                            <button
                                className="bg-gray-500/80 rounded-[12px] w-[100%] hover:opacity-50  p-6"
                                onClick={() => setReset(false)}
                            >
                                No
                            </button>
                            <button
                                className="rounded-[12px] w-[100%] p-6 bg-hsi-yellow hover:opacity-50 duration-300"
                                onClick={funcResetToken}
                            >
                                Yes
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetToken;