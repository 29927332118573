import axios from "axios";

const baseUrl = process.env.REACT_APP_NODE_API_BASE_URL;

export const getPromptTemplates = async token => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/prompt-templates`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
};

export const getPromptCategories = async token => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/admin/prompt-category/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.request(config);
  return response.data;
};

export const addPromptTemplates = async (templateData, name, token) => {
  try {
    const data = {
      category: name,
      title: templateData.title,
      user_input: templateData.user_input,
      description: templateData.description,
      response_format: templateData.response_format,
      prompt_feature: templateData.prompt_feature,
    };

    const config = {
      method: "post",
      url: `${baseUrl}/api/v1/admin/prompt-templates/`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: data,
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log("Error:", error.response.data);
  }
};

export const deletePromptTemplates = async (id, token) => {
  try {
    const config = {
      method: "delete",
      url: `${baseUrl}/api/v1/admin/prompt-templates/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log("Error:", error.response.data);
  }
};

export const updatePromptTemplate = async (templateData, id, token) => {
  try {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl}/api/v1/admin/prompt-templates/${id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: templateData,
    };

    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    console.log("Error:", error.response.data);
  }
};
