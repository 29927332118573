import React from "react";

const DeleteFeedback = ({
  openDeleteModal,
  handleDeleteFeedback,
  setOpenDeleteFeedback,
  loading,
}) => {
  return (
    <>
      {" "}
      {openDeleteModal && (
        <>
          {loading && (
            <section className="fixed top-0 left-0 w-full h-full bg-opacity-50 bg-gray-500 flex justify-center items-center z-50">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-[#eab646] border-solid"></div>
            </section>
          )}
          <div className="relative text-white">
            <div className="fixed z-20 flex flex-row justify-center items-start top-0 left-0 h-[100%] w-[100%] bg-[rgba(0,0,0,0.5)]">
              <div className="w-[35%] max-w-[500px] rounded-[12px] bg-white ring-1 ring-black shadow-lg shadow-black-900 mt-[15%] mb-[10%] p-[20px] ">
                <div className="row text-center">
                  <h2 className="py-2 text-black text-3xl">
                    Are you sure to delete this prompt?
                  </h2>
                </div>
                <div className="flex flex-row grid-rows-2 items-center justify-center gap-20">
                  <div className="flex flex-col px-10 py-3 mt-3">
                    <div className=" text-left hover:bg-[#B18900] bg-[#E3B51A] my-2 w-[10rem] h-[4rem] rounded-lg">
                      <button
                        className="w-full py-3 text-3xl h-full"
                        onClick={handleDeleteFeedback}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                  <div className="flex flex-col px-10 py-3 mt-3">
                    <div className="hover:bg-[#B18900] bg-[#E3B51A] text-left my-2 w-[10rem] h-[4rem] rounded-lg">
                      <button
                        className="w-full py-3 text-3xl h-full"
                        onClick={() => setOpenDeleteFeedback(false)}
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DeleteFeedback;
