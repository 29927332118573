import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { TextArea, CustomInput } from "./InputFields";
import toast, { Toaster } from "react-hot-toast";
import Dropdown from "./Dropdown";
import { useUser } from "../../../../context/UserContext";
import {
  updatePromptTemplate,
  getPromptTemplates,
} from "../../../../API/Prompts";

const PromptTemplateModal = ({
  isOpen,
  selectedTemplate,
  setIsOpen,
  filteredCateg,
  setData,
}) => {
  const user = useUser();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const onSubmit = async data => {
    try {
      const token = user?.token;
      setLoading(true);
      const id = selectedTemplate.id;
      await updatePromptTemplate(data, id, token);
      const response = await getPromptTemplates(token);
      setData(response?.data);
      setIsOpen(false);
      toast.success("Prompt Template Updated Successfully");
    } catch (error) {
      console.log("error:", error);
      toast.error("An error occurred, please try again");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedTemplate) {
      setValue("category", selectedTemplate?.category_name);
      setValue("title", selectedTemplate?.title);
      setValue("description", selectedTemplate?.description);
      setValue("user_input", selectedTemplate?.user_input);
      setValue("response_format", selectedTemplate?.response_format);
      setValue("prompt_feature", selectedTemplate?.prompt_feature);
    }
  }, [setValue, selectedTemplate, filteredCateg]);

  return (
    <>
      {isOpen && selectedTemplate && (
        <section className="fixed top-0 left-0 w-full h-full flex justify-center z-20 items-center bg-[#00000086]">
          <section className="w-[50rem] h-[58 rem] border bg-white rounded-2xl">
            {loading && (
              <section className="fixed top-0 left-0 w-full h-full bg-opacity-50 bg-gray-600 flex justify-center items-center z-50">
                <div className="animate-spin rounded-full h-32 w-32 border-t-8 border-[#eab646] border-solid"></div>
              </section>
            )}
            <section className="flex  items-center justify-between pt-5 px-5 pb-3">
              <h1 className="text-3xl mt-5 font-bold pl-3"> Update Category</h1>
              <IoMdCloseCircleOutline
                onClick={() => setIsOpen(false)}
                className="cursor-pointer text-3xl hover:text-[#b18900]"
              />
            </section>
            <div className="h-2 bg-[#e3b51a]"> </div>
            <div className="h-2 bg-[#b18900]"> </div>

            <section className="px-8 pt-2 text-sm ">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <Dropdown
                    onChange={value => setValue("category", value)}
                    defaultValue={selectedTemplate?.category_name || ""}
                  />

                  <CustomInput
                    label="Prompt Title"
                    name="title"
                    register={register}
                    required
                  />
                  <CustomInput
                    label="Prompt Description"
                    name="description"
                    type="textarea"
                    register={register}
                  />
                  <CustomInput
                    label="User Input"
                    name="user_input"
                    register={register}
                    required
                  />
                  <CustomInput
                    label="Response Format"
                    name="response_format"
                    register={register}
                    required
                  />
                  <TextArea
                    label="Prompt Feature"
                    name="prompt_feature"
                    type="textarea"
                    register={register}
                    required
                  />
                </div>
                ;
                <section className="flex justify-between">
                  <section></section>
                  <button
                    type="submit"
                    className=" h-16 my-2 w-28 text-xl text-white rounded-lg bg-[#e3b51a]"
                  >
                    Update{" "}
                  </button>
                </section>
              </form>
            </section>
          </section>
        </section>
      )}

      <Toaster position="bottom-right" />
    </>
  );
};

export default PromptTemplateModal;
