import { IoBulbOutline } from "react-icons/io5";
import ThemeModeContext from "../../../context/ThemeContext";
import { useContext } from "react";

const Prompt = ({ promptTemplate, handlePromptSelect, searchValue }) => {
  const theme = useContext(ThemeModeContext);
  return (
    <button
      onClick={() => {
        handlePromptSelect(promptTemplate);
      }}
      className={`text-lg text-shark-300 hover:text-white rounded-lg flex justify-center items-center py-1 px-4 hover:bg-gray-500/20`}
    >
      <IoBulbOutline className="h-7 w-7 mr-8 text-[#F7B442]" />
      <span className={`${theme.theme === 'light' ? 'text-lightText' : 'text-white'} font-[500] w-72 break-normal text-left text-black`}>
        {promptTemplate.title} {searchValue < 1 ? '' : `- ${promptTemplate.category_name}`}
      </span>
    </button>
  );
};

export default Prompt;