import React, { useContext, useState } from "react";
import { CreateUserFeedback } from "../../../API/Conversations";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import ThemeModeContext from "../../../context/ThemeContext";
const Feedback = ({ closeFeedback, feedbackValue, conversationsData }) => {
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const theme = useContext(ThemeModeContext);
  const onSubmit = async data => {
    setIsLoading(true);
    try {
      const { id, chat_session_id } = conversationsData;
      const feedbackInformation = { ...data, like: feedbackValue };
      await CreateUserFeedback(feedbackInformation, chat_session_id, id);
      toast.success("Thank you for your positive feedback!");
      closeFeedback(false);
      reset();
    } catch (error) {
      toast.error("Failed to submit feedback");
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="relative text-white">
        <div className="fixed z-10 flex flex-row justify-center items-start top-0 left-0 h-[100%] w-[100%] bg-[#17181a]/50">
          {isLoading ? (
            <div className="mt-[20%] mx-auto my-auto flex flex-row items-center justify-center gap-6">
              <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-[#eab646] border-solid"></div>
              <span>Loading...</span>
            </div>
          ) : (
            <div className={`w-[50%] max-w-[500px] rounded-tl-[20px] rounded-bl-[20px] rounded-br-[20px] ${theme.theme === 'light' ? 'bg-gray-300 text-black' : 'bg-[#17181a]'} shadow-xl shadow-black-900 mt-[8%] p-[40px] pt-[10px] pb-[15px]`} >
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row text-center">
                  <h4 className={`p-[50px] text-bold ${theme.theme === 'light' ? 'text-black' : ''}`}>POSITIVE FEEDBACK</h4>
                </div>
                <div className={`row ${theme.theme === 'light' ? 'bg-gray-500 text-black' : 'bg-[#202123]'}`}>
                  <p className="pl-8 p-[20px] text-bold">
                    Do you have any thoughts you'd like to share?
                  </p>
                  <textarea
                    className={`w-[100%] h-[100px] min-h-[100px] max-h-[100px] ${theme.theme === 'light' ? 'bg-gray-300 text-black' : 'bg-[#2e3032]'} pl-8 p-5`}
                    placeholder="What do you like about the response?"
                    {...register("feedback", {
                      required: "Please provide your feedback",
                    })}
                  />
                </div>
                {errors?.feedback && (
                  <span className="block text-red-400 text-lg text-left">
                    {errors?.feedback.message}
                  </span>
                )}
                <div className="row pl-5 pr-5 mb-2 text-right">
                  <button
                    onClick={() => {
                      closeFeedback(false);
                    }}
                    className={`text-xl hover:bg-gray-700/80 duration-300 bg-[#202123]/100 rounded-[12px] w-auto pl-7 pr-7 pt-5 pb-5 m-3 ${theme.theme === 'light' ? 'text-white' : 'text-black'}`}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={`text-xl hover:bg-hsi-yellow/90 duration-300 bg-gray-500/80 rounded-[12px] w-auto pl-7 pr-7 pt-5 pb-5 m-3 mr-0 ${theme.theme === 'light' ? 'text-white' : 'text-black'}`}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Feedback;
