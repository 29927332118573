import { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useGetUserProfile } from "../../../hooks/user/useUserProfile";
import { sidebarData } from "./data/sidebarData";
import { FaRegUser, FaRegBell } from "react-icons/fa6";
import ProfileMenu from "../Dropdown/ProfileMenu/ProfileMenu";
import NotificationMenu from "../Dropdown/NotificationMenu/NotificationMenu";
import "./style.css";
import hsiLogo from "../../../Images/hsi-logo.png";
import luffy from "../../../Images/luffy.png";

import ProfileModal from "../Modal/ProfileModal/ProfileModal";

const Sidebar = () => {
  const { data: userProfile } = useGetUserProfile();

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();
  const profileMenuRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        profileMenuRef.current &&
        !profileMenuRef.current.contains(event.target)
      ) {
        setIsProfileMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleProfileMenuOpen = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
    setIsNotificationMenuOpen(false);
  };

  const handleNotificationMenuOpen = () => {
    setIsNotificationMenuOpen(!isNotificationMenuOpen);
    setIsProfileMenuOpen(false);
  };

  const handleTriggerModal = (isOpen) => {
    setOpenModal(isOpen);
  };

  return (
    <aside className="w-[23rem] h-full bg-[#f1f1f1] fixed left-0 top-0 px-2 z-20">
      <div className="row mt-4 px-2">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <div className="w-8 h-auto">
            <Link to="/admin/dashboard">
              <img className="w-full h-auto" src={hsiLogo} alt="HSI Logo" />
            </Link>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 flex flex-row justify-end gap-2">
          <div className="relative">
            {openModal && (
              <ProfileModal
                handleTriggerModal={handleTriggerModal}
                userProfile={userProfile}
              />
            )}
          </div>

          <button
            className="text-2xl border-[1px] rounded-full p-2 border-black"
            onClick={handleNotificationMenuOpen}
          >
            <FaRegBell />
          </button>

          {isNotificationMenuOpen && <NotificationMenu />}

          <div ref={profileMenuRef}>
            <button
              className="text-2xl border-[1px] rounded-full p-2 border-black mr-3"
              onClick={handleProfileMenuOpen}
            >
              <FaRegUser />
            </button>

            {isProfileMenuOpen && (
              <ProfileMenu
                handleTriggerModal={handleTriggerModal}
                userProfile={userProfile}
              />
            )}
          </div>
        </div>
      </div>

      <div className=" flex flex-col items-center justify-center my-20 gap-4">
        <div className="w-32">
          <img className="w-full h-auto" src={luffy} alt="user profile" />
        </div>
        <p className="w-auto mx-2 text-black font-black text-center">
          <p className="capitalize">
            {userProfile?.first_name} {userProfile?.last_name}
          </p>
          <span className="font-light text-xl">{userProfile?.email}</span>
        </p>
      </div>

      <ul className="">
        {sidebarData.map((item) => {
          // const isActive = location.pathname === item.path;
          return (
            <li
              key={item.path}
              className={`flex w-full flex-col text-[0.8rem] p-1`}
            >
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  `nav-link flex gap-8 h-12 items-center pl-5 rounded-xl font-poppins font-medium text-2xl ${
                    isActive
                      ? "bg-[#EFB312] text-white focus:text-white"
                      : "hover:bg-[#EFB312] hover:text-white"
                  }`
                }
              >
                <span>{item.icon}</span>
                <span>{item.title}</span>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </aside>
  );
};

export default Sidebar;
