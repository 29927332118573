import React, { useEffect, useState } from "react";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import iconLogo from "../assest/username-icon.svg";
import lockLogo from "../assest/lock-icon.svg";
import toast, { Toaster } from "react-hot-toast";
import "../newLogin.css";
import ForgotPassword from "../../modal/ForgotPassword";
import ArrowIcon from "../assest/Mask-group.svg";
import { useUser, useUserUpdate } from "../../../../context/UserContext.jsx";

const NewPage = props => {
  const [message, setMessage] = React.useState("");
  const [modal, setModal] = useState(false);
  const { staySignedInHandler } = useUserUpdate();
  const staySignedInValue = JSON.parse(localStorage.getItem('staySignedIn') || false);
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async data => {
    // setMessage("");
    // setMessage(await props.tryLoginFunction(data));
    // toast.error("Please enter a valid username and password");
    try {
      setMessage("");
      const loginMessage = await props.tryLoginFunction(data);
      setMessage(loginMessage);
    } catch (error) {
      console.log("Error during login:", error);
    }
  };

  const handleOpenModal = e => {
    e.preventDefault();
    setModal(prev => !prev);
  };
  const handleKeyPress = e => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission behavior
      handleSubmit(onSubmit)(); // Manually trigger form submission
    }
  };


  return (
    <>
      <section className="main overflow-hidden flex items-center justify-center   min-h-screen min-w-screen  max-sm:w-auto   ">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="z-10 "
          onKeyPress={handleKeyPress}
        >
          <section className="card   h-[600px] w-[800px] rounded-3xl relative  flex-col pt-15 items-center justify-center flex  max-sm:w-[450px]">
            <section className="w-[200px] h-[200px] bg-[#e4ded3] rounded-full absolute left-[-70px]  z-[-1] top-[-80px] left-circle"></section>
            <section className="w-[200px] h-[200px] bg-[#e4e7ed] rounded-full absolute right-[-150px] z-20 top-[10rem] right-circle"></section>
            <section className="w-[170px] h-[170px]  rounded-full absolute left-[-150px] z-[-1] bottom-[-30px] bottom-circle    "></section>{" "}
            <h1 className="text-[#eab646] font-verdana-bold font-bold text-7xl title">
              HAI APP
            </h1>
            <p className="text-xl font-verdana-regular  ">
              Sign in to your account
            </p>
            <section className=" relative w-full flex flex-col mt-20 items-center justify-center">
              <label
                htmlFor="username"
                className="absolute left-52 top-1 max-sm:left-20 label bg-[#e9ecf5] h-10 w-auto px-5 rounded-full"
              >
                Username
              </label>
              <input
                {...register("username", {
                  required: "This field is required",
                })}
                type="text"
                id="username"
                placeholder="Enter your username"
                className= {`w-9/12 h-20 mt-5 pl-20  max-sm:w-[400px]  placeholder:font-verdana-bold placeholder:text-[#BCBFC7]  rounded-full bg-[#e9ecf5] border-[1px] ${errors['username'] ? "border-red-500 border-2" : "border-[#77787b]"}`}
              />
              <img
                src={iconLogo}
                alt=""
                className="absolute left-44 bottom-4 w-12 h-12  max-sm:left-16"
              />

              <p className="input-field-error ">
                {errors.username?.message}
              </p>
            </section>

            <section className=" relative w-full flex flex-col items-center justify-center mt-8">
              <label
                htmlFor="password"
                className="absolute left-52 top-6 max-sm:left-20  bg-[#e9ecf5]   label h-10 w-auto px-5 rounded-full"
              >
                Password
              </label>
              <input
                {...register("password", {
                  required: "This field is required",
                })}
                type="password"
                id="username"
                placeholder="Enter your password"
                className={`w-9/12 h-20 mt-10 pl-20  max-sm:w-[400px]  placeholder:font-verdana-bold placeholder:text-[#BCBFC7]  rounded-full bg-[#e9ecf5] border-[1px] ${errors['password'] ? "border-red-500 border-2" : "border-[#77787b]"}`}
              />
              <img
                src={lockLogo}
                alt=""
                className="absolute left-44 bottom-4 w-12 h-12  max-sm:left-16"
              />
              
              <p className="input-field-error login-field-error">
                {errors.password?.message}
              </p>
            </section>
            <section className=" px-40 mt-10 pt-5 w-full flex justify-between  max-sm:p-10 ">
              <section className="flex items-center justify-center select-none ml-5 text-[#77787c] font-verdana-regular">
                <label class="custom-checkbox">
                  <input type="checkbox" checked={staySignedInValue} onChange={staySignedInHandler}/>
                  <span class="checkmark"></span>
                </label>
                Stay signed in
              </section>
              <section>
                <button
                  modal={modal}
                  onClick={handleOpenModal}
                  className="text-[#77787c] cursor-pointer font-verdana-regular"
                >
                  Forgot your password?
                </button>
              </section>
            </section>
            <section className="my-14 max-sm:mt-5 max-sm:mb-20">
              <p className="flex items-center gap-3  ">
                <Link to="/auth/register" style={{ textDecoration: "none" }}>
                  <span className="text-[#49494b] font-verdana-regular font-[400]  ml-3 create-account ">
                    Create Your Account{" "}
                  </span>
                </Link>
                <img src={ArrowIcon} className="h-[30px] w-[20px]" alt="Fit" />
              </p>
              <button
                type="submit"
                className="w-[160px] bg-[#EAB646] hover:bg-[#dca027] custom-shadow font-[800] font-verdana-regular text-white h-20"
              >
                Sign In
              </button> 
            </section>
          </section>
        </form>
      </section>
      <ForgotPassword modal={modal} handleOpenModal={handleOpenModal} />
      <Toaster position="bottom-right" />
    </>
  );
};

export default NewPage;
