import { Outlet } from "react-router-dom"

export default function RootLayout() {
    return (
      <>
        <div className="bg-shark-950 min-h-screen font-poppins">
          <Outlet/>
        </div>
      </>
    )
}

//removed html and body here since it is conflicting with react router dom
