import React, { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext();
const UserUpdateContext = createContext();
const UserTokenUsageContext = createContext();


export const useUser = () => {
  return useContext(UserContext);
};

export const useUserUpdate = () => {
  return useContext(UserUpdateContext);
};

export const useUserTokenUsage = () => {
  return useContext(UserTokenUsageContext);
}

export const UserProvider = ({ children }) => {
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState();
  const [userId, setUserId] = useState(null)

  const staySignedInValue = JSON.parse(localStorage.getItem('staySignedIn') || false);
  const [staySignedIn, setStaySignedIn] = useState(staySignedInValue);

  useEffect(() => {
    if (staySignedInValue){
      setStaySignedIn(staySignedInValue);
    }
  }, [staySignedInValue]);

  useEffect(() => {
    setUser(storedUser);
  }, [])

  const staySignedInHandler = () => {
    setStaySignedIn(!staySignedIn);
    localStorage.setItem("staySignedIn", JSON.stringify(!staySignedIn));
  };

  const updateUser = newUser => {
    setUser(newUser);
  };

  const assignUserId = (id) => {
    setUserId(id)
  }

  return (
    <UserContext.Provider value={user}>
      <UserUpdateContext.Provider value={{ updateUser, staySignedInHandler }} >
        <UserTokenUsageContext.Provider value={{ userId, assignUserId }}>
          {children}
        </UserTokenUsageContext.Provider>
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  );
};
