import axios from 'axios'

const api = axios.create({
  // will be move to .env file
  baseURL: process.env.REACT_APP_NODE_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  }
})

export default api;