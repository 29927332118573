import React, { useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  useBeforeUnload
} from "react-router-dom";
import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import RootLayout from "./components/Layout";
import AuthIndex from "./Pages/Authentication/AuthenticationPage";
import Login from "./Pages/Authentication/Login/login";
import LandingPage from "./Pages/LandingPage";
import DashboardPage from "./Pages/Dashboard/DashboardPage";
import { UserProvider } from "./context/UserContext";
import NewRegister from "./Pages/Authentication/Register/NewRegister";

import HomePage from "./Pages/Home/Home";
import PrivateRoute from "./routes/PrivateRoute";
import AutoSignIn from "./routes/AutoSignIn";
import AdminRoutes from "./routes/AdminRoutes";
import InitialRoute from "./routes/InitialRoute";

const queryClient = new QueryClient()

const staySignedIn = JSON.parse(localStorage.getItem("staySignedIn") || false);

const router = createBrowserRouter(
  createRoutesFromElements(

    <Route path="/" element={<RootLayout />}>

      <Route element={<InitialRoute />}>

      <Route index element={staySignedIn ? <AutoSignIn /> : <LandingPage />} />

      <Route path="auth" element={<AuthIndex />}>
        <Route index path="login" element={<Login />} />
        <Route path="register" element={<NewRegister />} />
      </Route>

      </Route>

      {/* USER PRIVATE ROUTE */}
      <Route path="" element={<PrivateRoute />}>
        <Route path="/*" element={<HomePage />} />
      </Route>

      {/* ADMIN PRIVATE ROUTE */}
      <Route path="" element={<PrivateRoute />}>
        <Route path="admin/*" element={<HomePage />} />
      </Route>
    </Route>

  )
);

export default function AppRouter() {

  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <RouterProvider router={router} />
      </UserProvider>
    </QueryClientProvider>
  );
}
