import React, { useState } from "react";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import { RiExpandUpDownFill } from "react-icons/ri";

const Accordion = ({ header, children }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleDropdown = () => {
    setExpanded(!expanded);
  };
  return (
    <div
      className={`relative flex flex-col items-center justify-center pt-2 mt-5 rounded-lg transition-all border-2 border-[#b8b8b8]  h-auto w-56  ${
        expanded ? " gap-4" : "bg-none gap-0"
      }`}
    >
      <button
        onClick={toggleDropdown}
        className={`w-full text-shark-50   hover:text-[#F7B442] px-4   h-8 ${
          expanded && "border-shark-200  "
        }`}
      >
        <div className="flex items-center gap-4  text-black   ">
          <p> Personal</p>
          <p className="ml-auto">
            <RiExpandUpDownFill />
          </p>
        </div>
      </button>

      <div
        className={`w-full overflow-hidden p-1  px-4 ${
          expanded ? "max-h-[1000rem] border" : "max-h-0"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
