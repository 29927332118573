import React from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
// import { Button } from "../../Authentication/Login/ui/button";
import NavDropdown from '../NavBar/NavDropdown'

const NavBar = () => {
    const [login, setLogin] = useState(false);
    const [register, setRegister] = useState(false);
    const navigate = useNavigate();

    const handleLoginClick = () => {
        setLogin(!login);
        navigate('auth/login', {replace: true});
    };

    const handleRegisterClick = () => {
        setLogin(!register);
        navigate('auth/register', {replace: true});
    };

    return(
        <div className="flex flex-col md:flex-row p-4 md:p-10 text-shark-950 justify-between items-center">
            <h2 className="font-bold text-hsi-yellow mb-4 md:mb-0">Highly GPT</h2>

            <div className="flex md:ml-4">
                {/* <NavDropdown label="Developers">
                    <a href="#">Item 1</a>
                    <a href="#">Item 2</a>
                    <a href="#">Item 3</a>
                    <a href="#">Item 4</a>
                </NavDropdown> */}
            </div>

            <div className="flex gap-4 ">
                <button className="h-fit transition-all hover:no-underline text-white bg-hsi-yellow py-3 px-6 rounded-lg hover:bg-shark-950 hover:text-white" onClick={handleLoginClick}>Login</button>
                <button className="h-fit transition-all hover:no-underline text-white bg-hsi-yellow py-3 px-6 rounded-lg hover:bg-shark-950 hover:text-white" onClick={handleRegisterClick}>Register</button>
            </div>

        </div>
    )
}

export default NavBar