import React, { useContext, useState } from "react";
import { FaAngleDown, FaPen, FaUser, FaRegTrashAlt } from "react-icons/fa";
import { GiArtificialHive } from "react-icons/gi";
import { VscSettingsGear } from "react-icons/vsc";
import { TiUser } from "react-icons/ti";
import { CgArrangeFront } from "react-icons/cg";

import CreateTeam from "../Team/CreateTeam";
import OrganizeTeam from "../Team/OrganizeTeam";
import { useUser } from "../../../../../context/UserContext";
import { deleteAccount } from "../../../../../API/UserProfile";
import { set } from "react-hook-form";

const AccountSetting = ({ handleOpenProfile, navigate, theme, toggleTheme }) => {
  const user = useUser();
  const [editProfile, setEditProfile] = useState(false);
  const [isEditButtonHover, setIsEditButtonHover] = useState(false);
  const [isTeamSetting, setIsTeamSetting] = useState(false);
  const [organizeSettingHover, setOrganizeSettingHover] = useState(false);
  const [isDeleteAccountHover, setIsDeleteAccountHover] = useState(false);

  const [team, setTeam] = useState(false);
  const [organizeTeam, setOrganizeTeam] = useState(false);

  const handleEditHover = isTrue => {
    setIsEditButtonHover(isTrue);
  };

  const handleTeamSetting = isTrue => {
    setIsTeamSetting(isTrue);
  };

  const handleOrganizeSettingsHover = isTrue => {
    setOrganizeSettingHover(isTrue);
  };

  const handleDeleteAccount = isTrue => {
    setIsDeleteAccountHover(isTrue);
  };

  const handleJoinTeam = () => {
    setTeam(!team);
  };

  const handleOrganizeTeam = () => {
    navigate("/organization")
    setOrganizeTeam(!organizeTeam);
  };

  const accountDelete = async () => {
    console.log("delete account");
    await deleteAccount();
    localStorage.clear();
    navigate("/auth/login", { replace: true });
  }

  const handleChange = (e) => {
    const newTheme = e.target.value;
    localStorage.setItem('theme', newTheme);
    toggleTheme(newTheme);
  };

  return (
    <>
      {organizeTeam && <OrganizeTeam handleReturn={setOrganizeTeam} navigate={navigate} />}
      {team && <CreateTeam handleReturn={setTeam} />}
      {!organizeTeam && !team && (
        <div
          className={`${theme === 'light' ? 'bg-[#C8C8C8]' : 'bg-[#202123]'} shadow-lg shadow-[rgba(0,0,0,0.35)]/900`}
          style={{ borderRadius: "0 0 15px 15px" }}
        >
          <div className={`${theme === 'light' ? 'bg-[#C8C8C8] border-[#C8C8C8]' : 'bg-[#202123] border-[#202123]'} border border-b  h-20 flex items-center`}>
            <h4 className={`${theme === 'light' ? 'text-lightText' : 'text-white'} font-bold font-poppins ml-12`}>
              Settings
            </h4>
          </div>
          <section className="p-6 space-y-4">
            {/* General */}
            <section className="grid grid-cols-5 h-40 gap-10">
              <div className="col-span-2 flex justify-center pt-6">
                <div className="flex gap-8 w-52">
                  <div className={`${theme === 'light' ? 'bg-black text-white' : 'bg-white text-black'} h-10 w-10 p-1 rounded-lg flex items-center justify-center`}>
                    <GiArtificialHive className="text-[2rem]" />
                  </div>
                  <p className={`${theme === 'light' ? 'text-lightText' : 'text-shark-50'} text-[1.3rem] font-semibold font-poppins mt-1`}>
                    General
                  </p>
                </div>
              </div>

              <div className={`${theme === 'light' ? 'bg-gray-400/50' : 'bg-[#313338]'} col-span-3 flex justify-center pt-6  rounded-2xl`}>
                <select
                  className={`${theme === 'light' ? 'bg-[#C8C8C8] hover:bg-[#C8C8C8] hover:text-lightText' : 'text-white bg-[#444548] hover:bg-[#68696a] hover:text-slate-200 '} w-[70%] transition-color duration-300 ease-linear h-12 text-left font-normal hover:cursor-pointer  rounded-lg px-1`}
                  value={theme}
                  onChange={handleChange} >
                  <option
                    value="system"
                    className={`${theme === 'light' ? 'bg-[#C8C8C8] hover:bg-[#C8C8C8]/30' : 'bg-[#68696A] hover:bg-[#68696A]'}  text-center`}
                  >
                    System
                  </option>
                  <option
                    value="dark"
                    className={`${theme === 'light' ? 'bg-[#C8C8C8] hover:bg-[#C8C8C8]/30' : 'bg-[#68696A] hover:bg-[#68696A]'}  text-center`}
                  >
                    Dark
                  </option>
                  <option
                    value="light"
                    className={`${theme === 'light' ? 'bg-[#C8C8C8] hover:bg-[#C8C8C8]/30' : 'bg-[#68696A] hover:bg-[#68696A]'}  text-center`}
                  >
                    Light
                  </option>
                </select>
              </div>
            </section>

            {/* Profile */}
            <section className="grid grid-cols-5 bg-[#] h-64 gap-10">
              <div className="col-span-2 flex justify-center pt-6">
                <div className="flex gap-8 w-52">
                  <div className={`${theme === 'light' ? 'bg-black text-white' : 'bg-white text-black'} h-10 w-10 p-1 rounded-lg  flex items-center justify-center`}>
                    <TiUser className="text-[2rem]" />
                  </div>
                  <p className={`${theme === 'light' ? 'text-lightText' : 'text-shark-50'} text-[1.3rem] font-semibold font-poppins mt-1`}>
                    Profile
                  </p>
                </div>
              </div>

              <div className={`${theme === 'light' ? 'bg-gray-400/50' : 'bg-[#313338]'} col-span-3 flex flex-col justify-center items-center gap-4 `}>
                <button
                  className={`${theme === 'light' ? 'bg-[#C8C8C8]' : 'text-white bg-[#444548]  hover:text-white'} w-[70%] transition-color duration-300 ease-linear flex items-center justify-between text-xl hover:bg-[#9f9fa0] py-2 px-3 rounded-lg text-left`}
                  onMouseEnter={() => handleEditHover(true)}
                  onMouseLeave={() => handleEditHover(false)}
                  onClick={handleOpenProfile}
                >
                  Edit Profile
                  {isEditButtonHover && <FaPen />}
                </button>

                {/* {user.organization_name === "personal" && (
                  <button
                    className={`${theme === 'light' ? 'bg-[#C8C8C8]' : 'text-white bg-[#444548] '} w-[70%] transition-color duration-300 ease-linear flex items-center justify-between text-xl hover:bg-[#f3d876b4] hover:text-white py-2 px-3 rounded-lg text-left`}
                    onMouseEnter={() => handleTeamSetting(true)}
                    onMouseLeave={() => handleTeamSetting(false)}
                    onClick={handleJoinTeam}
                  >
                    <span className="capitalize">{user.organization_name}</span>
                    {isTeamSetting && <VscSettingsGear />}
                  </button>
                )}

                <button
                  className={`${theme === 'light' ? 'bg-[#C8C8C8]' : 'text-white bg-[#444548] '} w-[70%] transition-color duration-300 ease-linear flex items-center justify-between text-xl hover:bg-[#76f376b4] hover:text-white py-2 px-3 rounded-lg text-left`}
                  onMouseEnter={() => handleOrganizeSettingsHover(true)}
                  onMouseLeave={() => handleOrganizeSettingsHover(false)}
                  onClick={handleOrganizeTeam}
                >
                  Organize Team
                  {organizeSettingHover && <CgArrangeFront />}
                </button> */}

                <button
                  className={`${theme === 'light' ? 'bg-[#C8C8C8]' : 'text-white bg-[#444548]'} w-[70%] transition-color duration-300 ease-linear flex items-center justify-between text-xl  hover:bg-red-500 hover:text-white py-2 px-3 rounded-lg text-left`}
                  onMouseEnter={() => handleDeleteAccount(true)}
                  onMouseLeave={() => handleDeleteAccount(false)}
                  onClick={accountDelete}
                >
                  Delete Account
                  {isDeleteAccountHover && <FaRegTrashAlt />}
                </button>
              </div>
            </section>
          </section>
        </div>
      )}
    </>
  );
};

export default AccountSetting;
