import "./login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import NewPage from "./loginTest/NewPage";
import { SigninUser } from "../../../API/Authenticate";
import { useUser, useUserUpdate } from "../../../context/UserContext";
import AutoSignIn from "../../../routes/AutoSignIn";

function Login() {

  const updateUser = useUserUpdate();
  const Navigate = useNavigate();

  async function submitCredentials(data) {
    try {
      const result = await SigninUser(data);  
      console.log(result, 'rs');
      result && (result?.data?.role === "member" || result?.data?.role === "none")
        ? Navigate("/home", {replace:true})
        : result && result?.data?.role === "superadmin" && Navigate("/admin/user", {replace:true})

      localStorage.setItem("user", JSON.stringify(result?.data));

      if (Object.values(result).length > 1) {

        const userData = {
          id: result?.data?.id,
          username: result?.data?.username,
          email: result?.data?.email,
          token: result?.data?.token,
          organization: result?.data?.organization_name,
          role: result?.data?.role,
        };

        updateUser(userData);
      }
    } catch (err) {
      console.log(err, 'err');
      return err.response.data.message;
    }
  }

  return <NewPage tryLoginFunction={submitCredentials} />;

}

export default Login;
