import axios from 'axios'

const baseUrl = process.env.REACT_APP_NODE_API_BASE_URL;

// get api key for open ai
export const getFeedback = async () => {
  const user = JSON.parse(localStorage.getItem("user"))
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/admin/feedback`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user?.token}`
    },
  };

  const response = await axios.request(config);
  return response.data;
}

export const deleteFeedback = async (id) => {
  const user = JSON.parse(localStorage.getItem("user"))
  let config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/admin/feedback/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user?.token}`
    },
  };

  const response = await axios.request(config);
  return response.data;
}

export const getUserFeedback = async (id) => {
  const user = JSON.parse(localStorage.getItem("user"))
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/admin/feedback/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user?.token}`
    },
  };

  const response = await axios.request(config);
  return response.data;
}

export const getUser = async (id) => {
  const user = JSON.parse(localStorage.getItem("user"))
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/admin/users`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user?.token}`
    },
  };

  const response = await axios.request(config);
  return response.data;
}

export const getChatUser = async (id) => {
  const user = JSON.parse(localStorage.getItem("user"))
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/session-history/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user?.token}`
    },
  };

  const response = await axios.request(config);
  return response.data;
}

export const updateFeedback = async (id) => {
  const user = JSON.parse(localStorage.getItem("user"))
  let config = {
    method: 'patch',
    maxBodyLength: Infinity,
    url: `${baseUrl}/api/v1/admin/feedback/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${user?.token}`
    },
  };

  const response = await axios.request(config);
  return response.data;
}