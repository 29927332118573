import { z } from "zod";
import api from "../API/User/apiInstance";

export const isFieldAvailable = async (field, value) => {
  try {

    const user = JSON.parse(localStorage.getItem("user"))

    const response = await api.get("/api/v1/admin/users", {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user?.token}`
      }
    });
    const userWithField = response?.data?.data?.find(user => user[field] === value);
    return !userWithField;

  } catch (error) {
    console.error(`Error checking ${field} availability:`, error);
    return false;
  }
};

// Add User Schema
export const AddUserSchema = z.object({
  first_name: z
    .string()
    .max(50,)
    .refine(value => value.trim() !== "", { message: "First name is required" }),
  middle_name: z
    .string()
    .refine(value => value.trim() !== "", { message: "Middle name is required" }),
  last_name: z
    .string()
    .refine(value => value.trim() !== "", { message: "Last name is required" }),
  username: z
    .string()
    .trim()
    .refine(value => value.trim() !== "", { message: "Username is required" })
    .refine(async (value) => isFieldAvailable("username", value), { message: "This username is already taken" })
    .refine(value => value.length >= 6 && value.length <= 15, { message: "The username must be between 6 to 15 characters long" })
    .refine(value => /\d/.test(value), { message: "Username should contain at least one digit" })
    .refine(value => !/\s/.test(value), { message: "Username should not contain spaces" }),
  role: z
    .enum(["user", "admin"]),
  email: z
    .string()
    .email()
    .toLowerCase()
    .trim()
    .refine(value => value.trim() !== "", { message: "Email is required" })
    .refine(async (value) => isFieldAvailable("email", value), { message: "This email is already taken" })
    .refine(value => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]{2,})?$/.test(value), { message: "Invalid email address" }),
  password: z
    .string()
    .refine(value => value.trim() !== "", { message: "Password is required" })
    .refine(value => value.length >= 6, { message: "Password should be at least 6 characters long" }),
});



// Update User Schema
export const UpdateUserSchema = z.object({
  first_name: z
    .string()
    .max(50, { message: "First name must not exceed to 50 characters long" })
    .optional(),
  middle_name: z
    .string()
    .max(50, { message: "First name must not exceed to 50 characters long" })
    .optional(),
  last_name: z
    .string()
    .max(50, { message: "First name must not exceed to 50 characters long" })
    .optional(),
  username: z
    .string()
    .trim()
    .max(15, { message: "Username must not exceed to 15 characters long" })
    .refine(value => value === "" || /\d/.test(value), { message: "Username should contain at least one digit" })
    .refine(value => value === "" || !/\s/.test(value), { message: "Username should not contain spaces" })
    .optional(),
  role: z
    .enum(["user", "admin"])
    .nullable(),
  email: z
    .string()
    .toLowerCase()
    .trim()
    .refine(value => value === '' || /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/.test(value), { message: "Invalid email format" })
    .optional(),
  password: z
    .string()
    .max(15, { message: "Password must not exceed to 15 characters long" })
    .optional(),
})
  .refine(obj => obj.username === undefined || obj.username.length >= 6 || obj.username === '', {
    message: "Username must be at least 6 characters long",
    path: ['username'],
  })
  .refine(obj => obj.password === undefined || obj.password.length >= 6 || obj.password === '', {
    message: "Password must be at least 6 characters long",
    path: ['password'],
  });;