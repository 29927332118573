import React from "react";
import { useForm } from "react-hook-form";
import { forgotPassword } from "../../../API/Authenticate";
import toast, { Toaster } from "react-hot-toast";
const ForgotPassword = ({ handleOpenModal, modal }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async data => {
    try {
      await forgotPassword(data);
      toast.success("Password reset link sent to your email");
      reset();
      handleOpenModal(false);
    } catch (error) {
      toast.error(error.response.data.message);
      console.log("Error during forgotPassword:", error);
    }
  };

  return (
    <>
      {modal && (
        <section className="fixed top-0 z-10 bg-[#00000049] left-0 w-full h-full flex justify-center items-center">
          <section className="w-[60rem] h-[23rem] border rounded-2xl bg-white">
            <section className="flex flex-col items-center justify-center h-full">
              <h1 className="text-4xl font-bold">Forgot Password</h1>
              <p className="text-lg text-center">
                Please enter your email to receive a password reset link.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="email"
                  placeholder="Your Email"
                  name="email"
                  {...register("email", { required: "Email is required" })}
                  className="w-[350px] h-12 border rounded-lg px-4 mt-4"
                />
                {errors.email && (
                  <p className=" text-lg ml-2    text-red-500">
                    {errors.email.message}
                  </p>
                )}
                <div className="flex mt-6 gap-10">
                  <button
                    type="submit"
                    className="w-[160px] rounded-full text-2xl text-white h-16 bg-[#4CAF50] mr-4"
                  >
                    Submit
                  </button>
                  <button
                    onClick={handleOpenModal}
                    type="button"
                    className="w-[160px] rounded-full text-2xl text-white h-16 bg-[#eab646]"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </section>
          </section>
        </section>
      )}
      <Toaster position="bottom-right" />
    </>
  );
};

export default ForgotPassword;
