import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { UpdateProfileSchema } from "./Schema/UpdateProfileScheme";
import { updateUserProfile } from "../../../../../API/UserRelatedData";
import toast, { Toaster } from "react-hot-toast";
import { useUser } from "../../../../../context/UserContext";
import ThemeModeContext from "../../../../../context/ThemeContext";
import { useContext } from "react";

const AccountSetting = () => {

  const theme = useContext(ThemeModeContext);
  const user = useUser()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(UpdateProfileSchema),
    defaultValues: {
      first_name: "",
      middle_name: "",
      last_name: "",
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
    }
  });

  // generate a toast base on the number of error
  const generateError = (error) => {
    if (error.response.data.message) {
      const errorMessages = error.response.data.message;
      Object.keys(errorMessages).forEach(field => {
        toast.error(`${errorMessages[field]}`);
      });
    }
  }

  const onSubmit = async (data) => {
    try {
      await updateUserProfile(data, user?.token);
      console.log(data);
      reset();
      toast.success("User updated successfully!");
    } catch (error) {
      generateError(error)
    }
  };

  return (
    <div
      className={`${theme.theme === 'light' ? 'bg-[#C8C8C8]' : 'bg-[#202123]'}  shadow-lg shadow-[rgba(0,0,0,0.35)]/900`}
      style={{ borderRadius: "0 0 15px 15px" }}
    >
      <Toaster position="bottom-right" />

      <div className={`${theme.theme === 'light' ? 'border-[#C8C8C8]' : 'border-[#2c2d2e]'} border border-b  h-20 flex items-center`}>
        <h4 className={`${theme.theme === 'light' ? 'text-lightText' : 'text-white'}  font-bold font-poppins ml-12`}>
          Account Profile
        </h4>
      </div>

      <section className="p-6">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="grid place-items-center space-y-2"
        >
          <section className=" relative w-full flex flex-col items-center justify-center">
            <label className={`focus:bg-[#202123] absolute left-28 top-1 max-sm:left-20 bg-[#202123] font-normal text-white h-10 w-auto  px-5 rounded-full`}>
              First name
            </label>
            <input
              {...register("first_name")}
              type="text"
              placeholder="First name"
              className={`${theme.theme === 'light' ? '' : ' focus:bg-[#202123] text-white'} w-[80%] h-20 p-2 mt-5 pl-20 rounded-full  bg-transparent text-2xl font-extralight border border-[#585858]`}
            />
            {errors?.first_name && (
              <span className="block text-red-400 text-lg text-left">
                {errors?.first_name?.message}
              </span>
            )}
          </section>

          <section className=" relative w-full flex flex-col items-center justify-center">
            <label className="absolute left-28 top-1 max-sm:left-20 focus:bg-[#202123] bg-[#202123] font-normal text-white h-10 w-auto  px-5 rounded-full">
              Middle name
            </label>
            <input
              {...register("middle_name")}
              type="text"
              placeholder="Middle name"
              className={`${theme.theme === 'light' ? 'text-lightText' : ' focus:bg-[#202123] text-white'} w-[80%] h-20 p-2 mt-5 pl-20 rounded-full  bg-transparent text-2xl font-extralight border border-[#585858]`}
            />
            {errors?.middle_name && (
              <span className="block text-red-400 text-lg text-left">
                {errors?.middle_name?.message}
              </span>
            )}
          </section>

          <section className=" relative w-full flex flex-col items-center justify-center">
            <label className="absolute left-28 top-1 max-sm:left-20 focus:bg-[#202123] bg-[#202123] font-normal text-white h-10 w-auto  px-5 rounded-full">
              Last name
            </label>
            <input
              {...register("last_name")}
              type="text"
              placeholder="Last name"
              className={`${theme.theme === 'light' ? 'text-lightText' : ' focus:bg-[#202123] text-white'} w-[80%] h-20 p-2 mt-5 pl-20 rounded-full  bg-transparent text-2xl font-extralight border border-[#585858]`}
            />
            {errors?.last_name && (
              <span className="block text-red-400 text-lg text-left">
                {errors?.last_name?.message}
              </span>
            )}
          </section>

          <section className=" relative w-full  flex flex-col items-center justify-center">
            <label className="absolute left-28 top-1 max-sm:left-20 focus:bg-[#202123] bg-[#202123] font-normal text-white h-10 w-auto  px-5 rounded-full">
              Username
            </label>
            <input
              {...register("username")}
              type="text"
              placeholder="Username"
              className={`${theme.theme === 'light' ? 'text-lightText' : ' focus:bg-[#202123] text-white'} w-[80%] h-20 p-2 mt-5 pl-20 rounded-full  bg-transparent text-2xl font-extralight border border-[#585858]`}
            />
            {errors?.username && (
              <span className="block text-red-400 text-lg text-left">
                {errors?.username?.message}
              </span>
            )}
          </section>

          <section className=" relative w-full flex flex-col items-center justify-center">
            <label className="absolute left-28 top-1 max-sm:left-20 focus:bg-[#202123] bg-[#202123] font-normal text-white h-10 w-auto  px-5 rounded-full">
              Email
            </label>
            <input
              {...register("email")}
              type="email"
              placeholder="Enter Email"
              className={`${theme.theme === 'light' ? 'text-lightText' : ' focus:bg-[#202123] text-white'} w-[80%] h-20 p-2 mt-5 pl-20 rounded-full  bg-transparent text-2xl font-extralight border border-[#585858]`}
            />
            {errors?.email && (
              <span className="block text-red-400 text-lg text-left">
                {errors?.email?.message}
              </span>
            )}
          </section>

          <section className=" relative w-full flex flex-col items-center justify-center">
            <label className="absolute left-28 top-1 max-sm:left-20 focus:bg-[#202123] bg-[#202123] font-normal text-white h-10 w-auto  px-5 rounded-full">
              Password
            </label>
            <input
              {...register("password")}
              type="password"
              placeholder="Password"
              className={`${theme.theme === 'light' ? 'text-lightText' : ' focus:bg-[#202123] text-white'} w-[80%] h-20 p-2 mt-5 pl-20 rounded-full  bg-transparent text-2xl font-extralight border border-[#585858]`}
            />
            {errors?.password && (
              <span className="block text-red-400 text-lg text-left">
                {errors?.password?.message}
              </span>
            )}
          </section>

          <section className=" relative w-full flex flex-col items-center justify-center">
            <label className="absolute left-28 top-1 max-sm:left-20 focus:bg-[#202123] bg-[#202123] font-normal text-white h-10 w-auto  px-5 rounded-full">
              Confirm Password
            </label>
            <input
              {...register("password_confirmation")}
              type="password"
              placeholder="Confirm Password"
              className={`${theme.theme === 'light' ? 'text-lightText' : ' focus:bg-[#202123] text-white'} w-[80%] h-20 p-2 mt-5 pl-20 rounded-full  bg-transparent text-2xl font-extralight border border-[#585858]`}
            />
            {errors?.password_confirmation && (
              <span className="block text-red-400 text-lg text-left">
                {errors?.password_confirmation?.message}
              </span>
            )}
          </section>

          <div className="flex justify-end p-5">
            <button
              className={`${theme.theme === 'light' ? 'border-gray-600/20 bg-gray-600/20 text-lightText' : 'border-[#fefefe] text-white '} flex justify-end py-3 px-7 border-[1px]  rounded-[12px] bg-transparent hover:bg-hsi-yellow duration-300
              type="submit"`}
            >
              Save Changes
            </button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default AccountSetting;