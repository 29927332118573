import { useState, useEffect } from "react";
import { create } from "@mui/material/styles/createTransitions";

import { putCreateTeam } from "../../../../../API/Team";
import { useForm } from "react-hook-form";

import { useUser } from "../../../../../context/UserContext";
const CreateTeam = ({ handleReturn }) => {
  const user = useUser();
  // const [teamValue, setTeamValue] = useState("N/A");
  // const [spanText, setSpanText] = useState("");

  const [createTeam, setCreateTeam] = useState();

  const handleCreateTeam = () => {
    setCreateTeam(!createTeam);
  };

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = async data => {
    try {
      const token = user?.token;
      await putCreateTeam(data, token);
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {createTeam && ""}
      {!createTeam && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-[#202123] shadow-lg shadow-[rgba(0,0,0,0.35)]/900 "
        >
          <div className="flex justify-center p-5">
            <span className="italic font-light text-[#fefefe49] text-lg ">
              You are currently creating a team called:
            </span>
          </div>
          <div className="px-10">
            <input
              type="text"
              className=" p-5 bg-[#383838] text-white text-center w-full rounded-[12px] border hover:border-[1px] hover:border-[#fefefe] capitalize"
              placeholder="Organization Name"
              {...register("organization_name")}
            />
          </div>

          <div className="pt-5 pb-10 px-10">
            <div className="flex flex-row justify-end gap-[10px]">
              <button
                className="text-center py-3 px-7 flex border-[1px] border-[#fefefe] rounded-[12px] text-white bg-[#929292] hover:opacity-70 duration-300 "
                onClick={() => handleReturn(false)}
              >
                Return
              </button>
              <button
                type="submit"
                className="text-center py-3 px-7 flex border-[1px] border-[#fefefe] rounded-[12px] text-white bg-hsi-yellow hover:opacity-70 duration-300 "
                // onSubmit={(e) => {
                //     e.preventDefault();
                //     setCreateTeam(true);
                //     setErrorFeedback('Creating team...')
                // }}
              >
                Join
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default CreateTeam;
