import React, { useContext, useState } from "react";
import { GoChevronDown, GoChevronUp } from "react-icons/go";
import ThemeModeContext from "../../context/ThemeContext";

const Accordion = ({ header, children }) => {
  const [expanded, setExpanded] = useState(false);
  const theme = useContext(ThemeModeContext);

  const toggleDropdown = () => {
    setExpanded(!expanded);
  };
  return (
    <div
      className={`relative flex flex-col items-center justify-center py-2 px-4 rounded-lg transition-all ${
        theme.theme === "light" ? "bg-white/20" : "bg-gray-500/20"
      }  ${expanded ? "bg-gray-500/20 gap-4" : "bg-none gap-0"}`}
    >
      <button
        onClick={toggleDropdown}
        className={`w-full text-shark-50 py-3 hover:text-[#F7B442] px-3 ${
          expanded && "border-shark-200 border-b-2 border-dotted"
        }`}
      >
        <div
          className={`flex items-center gap-4 pt-2 font-bold text-xl tracking-wider mt-2 font-poppins text-left hover:text-[#F7B442] 
        ${theme.theme === "light" ? "text-lightText" : "text-white"}`}
        >
          {header}
          <p className="ml-auto mt-2">
            {expanded ? <GoChevronUp /> : <GoChevronDown />}{" "}
          </p>
        </div>
      </button>

      <div
        className={`w-full overflow-hidden ${
          expanded ? "max-h-[1000rem]" : "max-h-0"
        }`}
      >
        {children}
      </div>
    </div>
  );
};

export default Accordion;
