import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { displayUsers, addUser, updateUser, deleteUser } from "../../API/User/user/displayUsers";

export const useDisplayUsers = () => {

  return useQuery({
    queryKey: ['users'],
    queryFn: displayUsers
  })
}


export const useAddUserMutation = () => {

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: addUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] })
    }
  })
}


export const useUpdateUserMutation = () => {

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] })
    }
  })
}


export const useDeleteUserMutation = () => {

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteUser,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] })
    }
  })
}