import { z } from 'zod'

export const RegisterSchema = z.object({
  username: z
    .string()
    .refine(value => value.length >= 6 || value.length <= 15, { message: "Username must be between 6 to 15 characters long" })
    .refine((value) => /\d/.test(value), { message: "Username should contain at least one digit" })
    .refine((value) => !/\s/.test(value), { message: "Username should not contain spaces" })
    .refine((value) => value.length !== 0, { message: "Username is required" }),
  email: z
    .string()
    .trim()
    .toLowerCase()
    .refine((value) => value.trim() !== "", { message: "Email is required" })
    .refine((value) => /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]{2,})?$/.test(value), { message: "Invalid email address" }),
  password: z
    .string()
    .min(6, { message: "Password should be at least 6 characters long" })
    .max(24, { message: "Password should not exceed to 24 characters long" })
    .refine((value) => /\d/.test(value), { message: "Password should contain at least one digit" }),
})