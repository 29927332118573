import React, { useState, useEffect } from "react";
import ManageMembers from "../Modal/Team/ManageMembers";
import { OrgUsage } from "./orgUsage";
import { FaCaretLeft } from "react-icons/fa";
import { viewTeamData } from "./data/viewTeamData";
import { AiOutlineClose } from "react-icons/ai";

import { getListMembers, getDetails } from "../../../API/TeamAdmin";
import { useUser } from "../../../context/UserContext";
import toast, { Toaster } from "react-hot-toast";
import RemoveMember from "../Modal/Team/RemoveMember";
import { Item } from "@radix-ui/react-select";

const ViewTeam = ({ closeViewTeam, storeOrgName, listOrg, orgId }) => {
  const [manageMembers, setManageMembers] = useState(false);
  const [orgUsage, setOrgUsage] = useState(false);
  const [clickOrg, setClickOrg] = useState(null);

  const handleOrgUsage = item => {
    setOrgUsage(!orgUsage);
    setClickOrg(item);
  };

  const [listMembers, setListMembers] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = user?.token;
        const Response = await getDetails(token);
        setListMembers(Response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const filteredData = listMembers.filter(
    list => list.organization_name === storeOrgName
  );
  return (
    <>
      {!orgUsage && (
        <div className=" content h-screen w-screen pl-[23rem]  bg-white">
          {manageMembers && <ManageMembers closeManage={setManageMembers} />}
          <div className="text-2xl bg-[#DDBB21] py-5 flex flex-row gap-4 justify-end px-5">
            <button
              className="rounded px-6 py-2 mr-2 text-black text-2xl bg-[#DDBB21] hover:bg-[#b19d58] duration-300 flex items-center justify-center w-auto border border-black"
              onClick={() => closeViewTeam(false)}
            >
              <FaCaretLeft className="text-white text-5xl w-14" /> Return
            </button>
          </div>
          <div className="bg-[#D9D9D9] col-lg-6 col-md-6 col-sm-6 text-3xl font-bold py-5">
            Team Members
          </div>
          <div className="bg-[#D9D9D9] col-lg-6 col-md-6 col-sm-6 flex flex-row justify-end gap-[5px]">
            <button
              className="bg-[#fefefe] hover:bg-[#9f9f9f] rounded-[5px] duration-300 text-2xl my-2 py-4 px-10 border border-black"
              onClick={() => handleOrgUsage(storeOrgName)}
            >
              Organization Usage
            </button>
          </div>
          <div className="col-12 border-2 border-b-gray flex flex-row py-5 pl-5 pr-0 text-2xl items-center justify-between mt-5">
            <span className="font-bold text-5x1">
              {filteredData?.length > 0 ? filteredData?.length : "0"} Members
            </span>
          </div>

          <div className="grid grid-cols-2 px-10 rounded-t-lg">
            <div className="bg-[#DDBB21] text-black font-bold text-center outline outline-1 p-3 my-3 rounded-tl-lg">
              Name
            </div>
            <div className="bg-[#DDBB21] text-black font-bold text-center outline outline-1 p-3 my-3 rounded-tr-lg">
              Roles
            </div>
          </div>
          {filteredData.map(item => {
            return (
              <div key={item?.id} className="grid grid-cols-2 px-10 hover:bg-[#fff2c4] cursor-pointer duration-300">
                <div className="relative bg-white text-black font-regular text-center border border-black p-3 my-3 border-r-0 border-l-0 rounded-l-lg">
                  <div className="absolute left-0 top-0 bottom-0 w-7 bg-[#DDBB21] border border-black rounded-l-lg"></div>
                  <div className="relative ml-4">{`${item?.first_name} ${item?.middle_name} ${item?.last_name}`}</div>
                </div>
                <div className="relative bg-white text-black font-regular text-center border border-black p-3 my-3 border-r-0 rounded-r-lg">
                  <div className="absolute right-0 top-0 bottom-0 w-7 bg-[#DDBB21] border border-black rounded-r-lg"></div>
                  <div className="relative mr-4">{item?.role}</div>
                </div>
              </div>
            );
          })}

        </div>
      )}

      {orgUsage && (
        <OrgUsage
          orgId={orgId}
          returnView={setOrgUsage}
          listOrg={listOrg}
          clickOrg={clickOrg}
        />
      )}
      <Toaster position="bottom-right" />
    </>
  );
};

export default ViewTeam;
