import { handler } from "tailwindcss-animate";


const GrantOneMember = ({handleReturn, funcRemoveMember}) => {
    return(
        <>

            <div className="relative text-white">
                <div className="fixed z-20 flex flex-row justify-center items-center top-0 left-0 h-[100%] w-[100%] bg-[#000000ad]">
                    <div className="bg-[#2f3031] p-12 flex flex-col text-center items-center w-[30vw] gap-[10px]">
                        <div className="text-5xl font-bold">Alert</div>
                        <div className="font-normal">You are attempting to remove a member from your team. Do you still want to proceed?</div>
                        <div className="flex flex-row justify-between gap-[10px]">
                            <button 
                                className='text-center py-3 px-7 border-[1px] border-[#fefefe] rounded-[12px] text-white bg-[#929292] hover:opacity-70 duration-300 '
                                onClick={handleReturn}
                            >
                                No
                            </button>
                            <button 
                                className='text-center py-3 px-7 border-[1px] border-[#fefefe] rounded-[12px] text-white bg-hsi-yellow hover:opacity-70 duration-300 '
                                onClick={funcRemoveMember}
                            >
                                Yes
                            </button>
                        </div>
                    </div>
                    

                </div>
            </div>
        </>
    );
}

export default GrantOneMember;