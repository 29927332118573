import React, { useState } from "react";
import { TiArrowUnsorted } from "react-icons/ti";
import flag from "../../ApiKey/assets/image 1.png";
import { ListOfCountry } from "./ListOfCountry";
const VerificationModal = ({ setIsOpen, isOpen }) => {
  const [openCountry, setOpenCountry] = useState(false);
  const handleOpenCountry = () => {
    setOpenCountry(prev => !prev);
  };
  return (
    <>
      {isOpen && (
        <section className="fixed  bg-[#0000008f] h-screen w-full z-10 left-0 top-0 font-[700] flex items-center justify-center">
          <section className="flex flex-col px-5 justify-center h-[190px] w-[500px] bg-white rounded-2xl border-[1px] border-black">
            <h1 className="text-4xl mb-5 mt-7 "> Verify your phone number</h1>
            <section className="flex gap-3 ">
              <div
                className="flex relative items-center w-28 h-20 rounded-lg px-2 gap-5 border-2 border-[#8e8e8e] cursor-pointer "
                onClick={handleOpenCountry}
              >
                <img src={flag} className="w-8 h-5" alt="" />
                <TiArrowUnsorted className="text-[#d7d7d7]" />
                {openCountry && <ListOfCountry />}
              </div>
              <section className="w-full relative">
                <input
                  type="text"
                  className="w-full h-20 border-2 border-[#8e8e8e] rounded-lg pl-14 placeholder:text-black outline-none   "
                />

                <span className="absolute left-3 top-6 "> +63</span>
              </section>
            </section>
            <button
              className="h-20 w-full bg-[#e3b51a] mt-3 rounded-lg text-white border-2 border-[#b18900] hover:bg-[#b18900]"
              onClick={() => setIsOpen(false)}
            >
              Send Code
            </button>
          </section>
        </section>
      )}
    </>
  );
};

export default VerificationModal;
