import { VscOrganization } from "react-icons/vsc";
import { GoOrganization } from "react-icons/go";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineFeedback } from "react-icons/md";
import { LiaKeySolid } from "react-icons/lia";
import { TbPrompt } from "react-icons/tb";
import { FiUsers } from "react-icons/fi";

export const sidebarData = [
  {
    path: "user",
    title: "User",
    icon: <GoOrganization className="w-8 h-8" />,
  },
  {
    path: "dashboard",
    title: "Dashboard",
    icon: <RxDashboard className="w-8 h-8" />,
  },
  {
    path: "api-keys",
    title: "API Keys",
    icon: <LiaKeySolid className="w-8 h-8" />,
  },
  {
    path: "prompt-template",
    title: "Prompt Template",
    icon: <TbPrompt className="w-8 h-8" />,
  },
  {
    path: "team",
    title: "Team",
    icon: <FiUsers className="w-8 h-8" />,
  },
  {
    path: "user-feedback",
    title: "Feedback",
    icon: <MdOutlineFeedback className="w-8 h-8" />,
  },
];
