import api from "../apiInstance";


export const displayUsers = async () => {
const user = JSON.parse(localStorage.getItem("user"))

  try {
    const response = await api.get('/api/v1/admin/users', {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user?.token}`
      }
    })
    return response?.data?.data
  } catch (error) {
    console.log(error)
  }
}

// function to add users
export const addUser = async (data) => {
const user = JSON.parse(localStorage.getItem("user"))

  try {
    const response = await api.post('/api/v1/admin/users', data, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user?.token}`
      }
    })
    return response?.data?.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

// function to update user
export const updateUser = async (data) => {
const user = JSON.parse(localStorage.getItem("user"))

  try {
    const response = await api.put(`/api/v1/admin/users/${data.id}`, data, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user?.token}`
      }
    })
    return response?.data?.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

// function to delete user
export const deleteUser = async (id) => {
const user = JSON.parse(localStorage.getItem("user"))
  try {
    const response = await api.delete(`/api/v1/admin/users/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${user?.token}`
      }
    })
    return response?.data?.data
  } catch (error) {
    console.log(error)
    throw error
  }
}