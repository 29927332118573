export const CustomInput = ({
  label,
  name,
  type = "text",
  register,
  required,
  value,
}) => {
  return (
    <div>
      <span className="pl-3 text-xl font-bold">{label}</span>
      <div className="h-4 bg-[#e3b51a] border-[1px] border-[#202020] rounded-t-2xl mt-1"></div>
      {type === "textarea" ? (
        <textarea
          className="border-x-[1px] border-b-[1px] w-full h-14 outline-none pl-5 pt-2 border-[#202020] rounded-b-2xl resize-none bg-[#dedede] text-lg"
          {...register(name, { required })}
        />
      ) : (
        <input
          className="border-x-[1px] border-b-[1px] w-full h-14 text-xl outline-none pl-5 bg-[#dedede] border-[#202020] rounded-b-2xl mb-3"
          type={type}
          {...register(name, { required })}
        />
      )}
    </div>
  );
};
export const TextArea = ({ label, name, register, value, required }) => {
  return (
    <div>
      <span className="pl-3 text-xl font-bold">{label}</span>
      <div className="h-4 bg-[#e3b51a] border-[1px] border-[#202020] rounded-t-2xl mt-1"></div>
      <textarea
        className="border-x-[1px] border-b-[1px] w-full h-28 outline-none pl-5 pt-2 border-[#202020] rounded-b-2xl resize-none bg-[#dedede] text-lg"
        {...register(name, { required })}
      />
    </div>
  );
};
